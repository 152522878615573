// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import { NavbarStyles } from '../../components/Navbar/styles';
import Colors from '../../styles/Colors';

// Types
interface PosterSideModalStylesTypes {
  show?: boolean;
  cardColor?: string;
  bodyFontColor?: string;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const PosterSideModalButtonStyles = styled.button`
  background: none;
  border: none;
  height: 54px;
  width: 54px;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 250ms ease-in-out 0s;

  svg {
    width: 16px;
    color: ${Colors.white};
  }

  &:hover {
    background: ${Colors.darkGrey};
  }

  &:focus {
    outline: 0;
  }
`;

export const PosterQuote = styled.div<{ cardColor?: string; bodyFontColor?: string }>`
  textarea {
    width: 100%;
    border-radius: 0px;
    resize: none;
    background-color: ${(props) => props.cardColor || 'white'} !important;
    color: ${(props) => props.bodyFontColor || 'black'} !important;
    &::placeholder {
      color: ${(props) => props.bodyFontColor || 'black'};
      opacity: 0.7;
    }
  }
  input {
    border-radius: 0px;
    background-color: ${(props) => props.cardColor || 'white'} !important;
    color: ${(props) => props.bodyFontColor || 'black'} !important;
    &::placeholder {
      color: ${(props) => props.bodyFontColor || 'black'};
      opacity: 0.7;
    }
    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
`;

export const PosterImage = styled.div`
  div.upload-wrapper {
    display: flex;
    div.custom-file {
      display: flex;
      width: calc(100% - 80px);
      border-radius: 30px !important;
      input {
        border-radius: 30px !important;
        &:nth-child(2) {
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          margin-bottom: 1rem;
        }
      }
    }
    & > button {
      width: 80px;
    }
  }

  border: 1px solid #0004;
  padding: 0.5rem;

  > .form-group.alt {
    margin-top: 1rem;
  }
`;

export const PosterSound = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
  border: 1px solid #0004;

  & > div {
    width: 100%;
    input {
      &:nth-of-type(1) {
        border-radius: none;
      }
      &:nth-of-type(2) {
        margin-bottom: 1rem;
      }
    }
  }

  audio {
    width: 100%;
  }
  button {
    width: 100%;
  }

  & > div.card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: rgb(240, 240, 240);
    }

    & > div {
      padding: 6px;
      background-color: ${Colors.blue};
      border-color: ${Colors.blue};
      margin-right: 10px;

      svg {
        color: white;
      }
    }
  }
`;
// https://www.youtube.com/watch?v=gm0b_ijaYMQ
export const PosterVideo = styled.div`
  input {
    margin-bottom: 1rem;
  }

  iframe {
    border: none;
  }
`;

export const PosterSideModalStyles = styled.div<PosterSideModalStylesTypes>`
  display: block;
  position: absolute;
  top: 54px;
  right: ${({ show }) => (show ? '0px' : '-420px')};
  width: 420px;
  height: calc(100vh - 54px);
  background: ${Colors.white};
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: all 150ms ease-in-out 0s;

  ${NavbarStyles} {
    background: ${Colors.grey};
    color: ${Colors.white};

    p {
      margin-left: 74px;
      text-transform: capitalize;
    }
  }

  article {
    position: absolute;
    width: 100%;
    height: calc(100vh - 108px);
    top: 54px;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: visible;

    section {
      padding: 20px;

      .btn-group {
        margin-bottom: 0;
        .btn {
          margin-bottom: 0;
        }
      }

      .form-group {
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        .ql-editor {
          background-color: ${(props) => props.cardColor || 'white'};

          * {
            color: ${(props) => props.bodyFontColor || 'black'};
          }
        }

        .form-control {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }

        .badge {
          width: 100%;
          padding: 0 6px;
          height: 20px;
          line-height: 20px;
          text-align: left;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          position: relative;
          svg {
            height: 12px;
            width: 12px;
          }

          .btn-group {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
          }

          button {
            height: 20px;
            width: 20px;
            padding: 0;
            border-radius: 0;
            position: relative;

            svg {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

            &:last-child {
              border-top-right-radius: 4px;
            }
          }
        }

        .btn.btn-outline-danger {
          margin-top: 10px;
          display: block;
          width: 100%;
        }
      }
    }
  }

  .toolbar {
    display: flex;
    flex-wrap: wrap;
  }

  .font-picker-wrapper {
    font-size: 14px;
    display: inline-block;
    width: 98px;
    height: 24px;

    & > div {
      width: 100%;
      height: 100%;
      box-shadow: none;

      button {
        background-color: white !important;
        height: 24px;

        p.dropdown-font-family {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        p.dropdown-icon {
          margin-left: 0;
        }
      }
    }
    .font-list {
      top: 24px;
      width: 120px;
      z-index: 999;
      li {
        background-color: white;
        button {
          width: 100%;
        }
      }
    }
  }

  div.chrome-picker {
    position: absolute;
    top: 60px;
    left: 65px;
    z-index: 999;
  }
`;

export const PosterTemplateStyles = styled.div`
  position: absolute;
  top: 54px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 54px);
  overflow: auto;
`;

export const PosterPageStyles = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  ${NavbarStyles} {
    margin-bottom: 0;
    h6 {
      color: ${Colors.white};
      line-height: 54px;
      margin: 0;

      span {
        margin-right: 10px;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 32px;
      }
    }

    .container-fluid {
      .row {
        .col {
          &:last-child {
            text-align: right;
            .btn {
              margin-left: 10px;
            }
          }

          .btn-group {
            .btn {
              &:nth-child(2) {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
`;

export const DraftModalContent = styled.div`
  & > span {
    display: block;
  }

  & > span:last-child {
    color: ${Colors.red};
    font-size: 14px;
    margin-top: 10px;
  }

  & > div {
    width: 100%;
    border-radius: 5px;
    border: 2px solid ${Colors.blue};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    span {
      display: inline-block;
      width: 80%;
      padding: 6px;
      overflow: hidden; /* make sure it hides the content that overflows */
      white-space: nowrap; /* don't break the line */
      text-overflow: ellipsis; /* give the beautiful '...' effect */
    }

    button {
      border-radius: 0 3px 3px 0;
      width: 20%;
      border: none;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
`;

export const RecordModalContent = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 6px;

    svg {
      margin-right: 6px;
    }
  }

  div.record-drawing {
    width: 100%;
    height: 200px;
    background-color: white;
    position: relative;

    div.straight-line {
      width: 100%;
      height: 3px;
      border-radius: 4px;
      background-color: black;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
`;
