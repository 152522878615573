// IMPORTS
// ------------------------------------------------------------
import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ExternalLink } from 'react-feather';

// Providers
import { useModal } from '../../providers/Modal';
import { useFetch } from '../../providers/Fetch';
import { useAuth } from '../../providers/Auth';
import { useNotification } from '../../providers/Notification';

// Helpers
import { CONST, getDefaultRoute, formatDate } from '../../utils/helpers';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataEdit from '../../components/DataEdit';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';
import InputThumbnail from '../../components/InputThumbnail';

// Styles
import { UserPageStyles } from './styles';
import DataList from '../../components/DataList';
import { Placeholder } from '../../components/Icons';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const UserPage = () => {
  // State / Props
  const [input, setInput] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const isMountedRef = React.useRef(false);
  const [conferences, setConferences] = React.useState<any>([]);
  const [isLoadingConferences, setIsLoadingConferences] = React.useState(false);
  const [listConferences, setListConferences] = React.useState<any>(null);
  const [isLoadingRoles, setIsLoadingRoles] = React.useState(false);
  const [listRoles, setListRoles] = React.useState<any>(null);
  const [selectConfenceRole, setSelectConfenceRole] = React.useState<any>({
    conferenceId: '',
    roleId: '',
  });
  const [showModalConference, setShowModalConference] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setModal } = useModal();
  const { fetch } = useFetch();
  const { auth } = useAuth();
  const { add } = useNotification();
  const [subdomain, setSubdomain] = useState('');

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (uuid: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/${
            auth.role === 'admin' ? 'users' : `conferences/${auth.conferenceId}/users`
          }/${uuid}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            const {
              orcid,
              twitter,
              facebook,
              instagram,
              website,
              photo,
              biography,
              petName,
              petPhoto,
              first_name: firstName,
              last_name: lastName,
              email,
              conferences_roles: conferencesRoles,
              pronoun,
              school,
              location,
            } = response.data.data;
            setInput({
              orcid,
              twitter,
              facebook,
              instagram,
              website,
              photo,
              biography,
              petName,
              petPhoto,
              ['first_name']: firstName,
              ['last_name']: lastName,
              email,
              pronoun,
              school,
              location,
            });
            setConferences(
              auth?.role === 'admin'
                ? conferencesRoles
                : conferencesRoles.map((item: any) => ({
                    ...item,
                    roleId: item.Role.id,
                    conferenceId: item.Conference.id,
                  })),
            );
            setIsLoading(false);
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        },
      );
    },
    [fetch, auth],
  );

  /**
   *
   */
  const deleteEntry = React.useCallback(
    async (entryId: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/${
            auth.role === 'admin'
              ? `users/${entryId}`
              : `conferences/${auth.conferenceId}/users/${entryId}`
          }`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.DELETED);
            navigate(`${CONST.adminRoute}/users`);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.DELETED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, navigate, auth],
  );

  /**
   *
   */
  const deleteUserConferenceRole = React.useCallback(
    async (userId: string, conferenceId: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/users/${userId}/conferences/${conferenceId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.DELETED);
            getData(userId);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.DELETED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData],
  );

  /**
   *
   */
  const createEntry = React.useCallback(
    async (payload: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/${auth.role === 'admin' ? 'users' : `conferences/${auth.conferenceId}/users`}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(payload),
        },
        (response: any) => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.CREATED);
            navigate(`${CONST.adminRoute}/users/${response.data.data.id}/edit`);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.CREATED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, navigate, auth],
  );

  /**
   *
   */
  const updateEntry = React.useCallback(
    async (entryId: string, data: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/users/${entryId}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.SAVED);
            getData(entryId);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData],
  );

  /**
   *
   */
  const getConferences = React.useCallback(async () => {
    setIsLoadingConferences(true);
    fetch(
      {
        url: `/conferences?take=100`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      (response: any) => {
        if (isMountedRef.current) {
          setListConferences(response.data.data);
          setIsLoadingConferences(false);
        }
      },
      () => {
        if (isMountedRef.current) {
          setIsLoadingConferences(false);
        }
      },
    );
  }, [fetch]);

  /**
   *
   */
  const getRoles = React.useCallback(async () => {
    setIsLoadingRoles(true);
    fetch(
      {
        url: `/roles?take=100`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      (response: any) => {
        if (isMountedRef.current) {
          const roles = response.data.data;
          if (roles) {
            setListRoles(
              roles.map((role: any) =>
                role.name === 'speaker' ? { ...role, display: 'Poster Presenter' } : role,
              ),
            );
          }
          setIsLoadingRoles(false);
        }
      },
      () => {
        if (isMountedRef.current) {
          setIsLoadingRoles(false);
        }
      },
    );
  }, [fetch]);

  /**
   *
   */
  const updateUserConferenceRole = React.useCallback(
    async (userId: string, conf: any) => {
      const payload: any = {
        ['conference_id']: auth.role === 'admin' ? conf.conferenceId : auth.conferenceId,
        ['role_id']: conf.roleId,
        quantity: isNaN(conf?.quantity) ? 0 : Number(conf?.quantity),
      };

      if (conf?.expires_at && new Date(conf?.expires_at).getTime()) {
        payload['expires_at'] = new Date(conf?.expires_at);
      }

      if (
        listConferences.find((item: any) => item.id === payload.conference_id).domain === 'admin'
      ) {
        payload['role_id'] = listRoles.find((item: any) => item.name === 'admin').id;
        payload.quantity = 0;
        payload['expires_at'] = undefined;
      }

      if (
        listRoles.find((item: any) => item.name === 'attendee').id === conf.roleId ||
        listRoles.find((item: any) => item.name === 'conference').id === conf.roleId
      ) {
        payload.quantity = 0;
        payload['expires_at'] = undefined;
      }

      setIsLoading(true);
      fetch(
        {
          url: `/users/${userId}/roles`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(payload),
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.SAVED);
            getData(userId);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData, listConferences, listRoles],
  );

  // Functions
  /**
   *
   */
  const onClickCancel = () => {
    navigate(`${CONST.adminRoute}/users`);
  };

  /**
   *
   */
  const onClickDelete = () => {
    setModal(
      <ModalContainer>
        <Modal
          title={`Delete '${input.email}'?`}
          hasBody={false}
          handleClose={() => setModal(null)}
          footer={
            <>
              <button
                onClick={() => setModal(null)}
                className="btn flex-fill btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteEntry(id as string);
                  setModal(null);
                }}
                className="btn flex-fill btn-outline-danger"
              >
                Delete
              </button>
            </>
          }
        />
      </ModalContainer>,
    );
  };

  /**
   *
   */
  const onClickSave = () => {
    createEntry(input);
  };

  /**
   *
   */
  const onClickUpdate = () => {
    updateEntry(id as string, input);
  };

  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if (event.target.name === 'email') {
      value = value.toLowerCase();
    }
    setInput({
      ...input,
      [event?.target.name]: value,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectConfenceRole({
      ...selectConfenceRole,
      [event.target.name]: event.target.value,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeInputConference = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectConfenceRole({
      ...selectConfenceRole,
      [event.target.name]: event.target.value,
    });
  };

  /**
   *
   */
  const onClickAddConf = () => {
    getConferences();
    getRoles();
    setSelectConfenceRole({
      conferenceId: '',
      roleId: '',
      quantity: 0,
      ['expires_at']: '',
    });
    setShowModalConference(true);
  };

  /**
   *
   */
  const onClickRemoveConf =
    (userId: string, conferenceName: string, conferenceId: string) => () => {
      setModal(
        <ModalContainer>
          <Modal
            title={`Remove Role from '${conferenceName}'?`}
            hasBody={false}
            handleClose={() => setModal(null)}
            footer={
              <>
                <button
                  onClick={() => setModal(null)}
                  className="btn flex-fill btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    deleteUserConferenceRole(userId, conferenceId);
                    setModal(null);
                  }}
                  className="btn flex-fill btn-outline-danger"
                >
                  Delete
                </button>
              </>
            }
          />
        </ModalContainer>,
      );
    };

  /**
   *
   * @param conferenceId
   * @param roleId
   */
  const onClickEditConf = (conf: any) => () => {
    setSelectConfenceRole(conf);
    setShowModalConference(true);
  };

  /**
   *
   * @param data
   */
  const buildLinkUrl = () => {
    const host = window.location.host.split('.');
    if (host.length > 0) host.shift();
    setSubdomain('.' + host.join('.'));
  };
  //`${data.Conference.domain}.${window.location.host}`
  React.useEffect(() => {
    buildLinkUrl();
  }, []);

  const conferenceColumns = {
    conferenceDomain: {
      display: 'Conference Domain',
      render: (data: any) => {
        const url = `http${window.location.host.includes('localhost') ? '' : 's'}://${
          data.Conference?.domain + subdomain
        }/`;
        return (
          <div>
            <a
              className="btn btn-sm btn-outline-primary"
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: '0px' }}
            >
              {url}
              <ExternalLink />
            </a>
          </div>
        );
      },
    },
    adminDomain: {
      display: 'Admin Domain',
      render: (data: any) => {
        const url = `http${window.location.host.includes('localhost') ? '' : 's'}://${
          data.Conference?.domain + subdomain
        }/admin`;
        return (
          <div>
            <a
              className="btn btn-sm btn-outline-primary"
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: '0px' }}
            >
              {url}
              <ExternalLink />
            </a>
          </div>
        );
      },
    },
    Role: {
      render: (data: any) => <>{data.Role.display}</>,
    },
    quantity: {
      display: 'Poster Slots',
      render: (data: any) => (
        <span>
          {data.Conference.domain === 'admin'
            ? '-'
            : data?.quantity && data?.quantity > 0
            ? data?.quantity
            : 'None'}
        </span>
      ),
    },
    ['expires_at']: {
      display: 'Access Expiration',
      render: (data: any) =>
        data.Conference.domain === 'admin' ? (
          '-'
        ) : data?.expires_at ? (
          <>
            {new Date(data?.expires_at).getTime() > new Date().getTime() ? (
              <span className={`badge bg-success`}>Active</span>
            ) : (
              <span className={`badge bg-warning`}>Disabled</span>
            )}{' '}
            <small className="text-muted">{formatDate(data?.expires_at ?? '')}</small>
          </>
        ) : (
          <span className="badge bg-secondary">None</span>
        ),
    },
    actions: {
      display: 'Actions',
      class: 'text-right',
      render: (rowData: any) => (
        <>
          {auth.role === 'admin' ? (
            <button
              onClick={onClickRemoveConf(
                id as string,
                rowData?.Conference?.name,
                rowData.conferenceId,
              )}
              className="btn btn-sm btn-outline-danger"
            >
              Remove
            </button>
          ) : null}
          <button onClick={onClickEditConf(rowData)} className="btn btn-sm btn-outline-secondary">
            Edit
          </button>
        </>
      ),
    },
  };

  // Hooks
  React.useEffect(() => {
    if (!pathname.endsWith('new') || !isMountedRef.current) return;
    setIsLoading(false);
  }, [pathname]);

  /**
   *
   */
  React.useEffect(() => {
    if (!pathname) return;
    if (!pathname.endsWith('new')) {
      getData(id as string);
    } else {
      setIsLoading(false);
    }
    getConferences();
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <DashboardLayout>
      <UserPageStyles>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataEdit
                isLoading={isLoading}
                subtitle={pathname.endsWith('new') ? 'New' : input?.email ?? ''}
                actions={
                  pathname.endsWith('new')
                    ? [
                        <button
                          key="cancel"
                          onClick={onClickCancel}
                          disabled={isLoading}
                          className={`btn btn-outline-secondary`}
                        >
                          Cancel
                        </button>,
                        <button
                          key="save"
                          onClick={onClickSave}
                          disabled={isLoading}
                          className="btn btn-primary"
                        >
                          Save
                        </button>,
                      ]
                    : [
                        <button
                          key="cancel"
                          onClick={onClickCancel}
                          disabled={isLoading}
                          className={`btn btn-outline-secondary`}
                        >
                          Cancel
                        </button>,
                        <button
                          key="delete"
                          onClick={onClickDelete}
                          disabled={isLoading}
                          className={`btn btn-outline-danger`}
                        >
                          {['admin'].includes(auth?.role ?? '') ? 'Delete' : 'Disable'}
                        </button>,
                        <button
                          key="save"
                          onClick={onClickUpdate}
                          disabled={isLoading}
                          className="btn btn-primary"
                        >
                          Save
                        </button>,
                      ]
                }
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <Link to={`${CONST.adminRoute}/users`} key="users">
                    Users
                  </Link>,
                  <span key="new">{pathname.endsWith('new') ? 'New' : 'Edit'}</span>,
                ]}
                title="Users"
              >
                <div className="row">
                  <div className="col col-12">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${tab === 0 ? 'active' : ''}`}
                          href="#account"
                          onClick={(e) => {
                            e.preventDefault();
                            if (tab !== 0) setTab(0);
                          }}
                        >
                          Account
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            pathname.endsWith('new') ? 'disabled' : tab === 1 ? 'active' : ''
                          }`}
                          href="#permissions"
                          tabIndex={-1}
                          aria-disabled="true"
                          onClick={(e) => {
                            e.preventDefault();
                            if (tab !== 1) setTab(1);
                          }}
                        >
                          Permissions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <section className="row" style={{ display: tab === 0 ? 'block' : 'none' }}>
                  <div className="col col-12 col-md-6">
                    <form>
                      <InputThumbnail>
                        <div className="form-group">
                          {input?.photo ? (
                            <img src={input.photo} className="rounded" alt="..." />
                          ) : (
                            <Placeholder
                              x="16%"
                              placeholder="Placeholder"
                              width="120"
                              height="120"
                            />
                          )}

                          <span>
                            <label htmlFor="photo">Profile Thumbnail</label>
                            <div className="custom-file">
                              <input
                                disabled={true}
                                accept="image/*"
                                name="photo"
                                type="file"
                                className="custom-file-input"
                                id="photo"
                              />
                              <label className="custom-file-label" htmlFor="photo">
                                Choose file
                              </label>
                            </div>
                          </span>
                        </div>
                      </InputThumbnail>

                      <div className="form-group">
                        <label htmlFor="first_name">
                          First Name <small>*</small>
                        </label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="first_name"
                          value={input?.first_name ?? ''}
                          type="text"
                          name="first_name"
                          className="form-control"
                          placeholder="ex: John"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="last_name">
                          Last Name <small>*</small>
                        </label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="last_name"
                          value={input?.last_name ?? ''}
                          type="text"
                          name="last_name"
                          className="form-control"
                          placeholder="ex: Smith"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="pronoun">Pronoun</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="pronoun"
                          value={input?.pronoun ?? ''}
                          type="text"
                          name="pronoun"
                          className="form-control"
                          placeholder="ex: he/him she/her"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="email">
                          Email <small>*</small>
                        </label>
                        <input
                          disabled={isLoading || (input?.email && auth?.email === input?.email)}
                          onChange={onChangeInput}
                          id="email"
                          value={input?.email ?? ''}
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="ex: Smith"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="school">Organization / Institution</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="school"
                          value={input?.school ?? ''}
                          type="text"
                          name="school"
                          className="form-control"
                          placeholder="ex: University Of America"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="location">Location</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="location"
                          value={input?.location ?? ''}
                          type="text"
                          name="location"
                          className="form-control"
                          placeholder="ex: New York, New York"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="biography">Biography</label>
                        <textarea
                          rows={6}
                          onChange={onChangeTextarea}
                          name="biography"
                          value={input?.biography ?? ''}
                          disabled={isLoading}
                          id="biography"
                          className="form-control"
                          placeholder="ex: A genius from a young age..."
                        />
                      </div>

                      <hr />

                      <div className="form-group">
                        <label htmlFor="orcid">ORCID</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="orcid"
                          value={input?.orcid ?? ''}
                          type="text"
                          name="orcid"
                          className="form-control"
                          placeholder="ex: https://orcid.org/4567-6768-2345-3455"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="twitter">Twitter</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="twitter"
                          value={input?.twitter ?? ''}
                          type="text"
                          name="twitter"
                          className="form-control"
                          placeholder="ex: https://twitter.com/userhandle"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="facebook">Facebook</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="facebook"
                          value={input?.facebook ?? ''}
                          type="text"
                          name="facebook"
                          className="form-control"
                          placeholder="ex: https://facebook.com/userhandle"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="instagram">Instagram</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="instagram"
                          value={input?.instagram ?? ''}
                          type="text"
                          name="instagram"
                          className="form-control"
                          placeholder="ex: https://instagram.com/userhandle"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="website">Website</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="website"
                          value={input?.website ?? ''}
                          type="text"
                          name="website"
                          className="form-control"
                          placeholder="ex: https://yourwebsite.com"
                        />
                      </div>

                      <hr />

                      <div className="form-group">
                        <label htmlFor="petName">Pet Name</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="petName"
                          value={input?.petName ?? ''}
                          type="text"
                          name="petName"
                          className="form-control"
                          placeholder="ex: Winston"
                        />
                      </div>

                      <InputThumbnail>
                        <div className="form-group">
                          {input?.petPhoto ? (
                            <img src={input.petPhoto} className="rounded" alt="..." />
                          ) : (
                            <Placeholder
                              x="16%"
                              placeholder="Placeholder"
                              width="120"
                              height="120"
                            />
                          )}

                          <span>
                            <label htmlFor="petPhoto">Pet Photo (optional)</label>
                            <div className="custom-file">
                              <input
                                disabled={true}
                                accept="image/*"
                                type="file"
                                name="petPhoto"
                                className="custom-file-input"
                                id="petPhoto"
                              />
                              <label className="custom-file-label" htmlFor="petPhoto">
                                Choose file
                              </label>
                            </div>
                          </span>
                        </div>
                      </InputThumbnail>
                    </form>
                  </div>
                </section>

                {!pathname.endsWith('new') && ['admin', 'conference'].includes(auth?.role) ? (
                  <section className="row" style={{ display: tab === 1 ? 'block' : 'none' }}>
                    <div className="col">
                      <DataList
                        isLoading={isLoading}
                        actions={
                          auth.role === 'admin' ||
                          (auth.role === 'conference' && conferences.length === 0)
                            ? [
                                <button
                                  className="btn btn-success"
                                  onClick={onClickAddConf}
                                  key="add"
                                >
                                  Add
                                </button>,
                              ]
                            : null
                        }
                        columns={conferenceColumns}
                        data={conferences}
                        hasPagination={false}
                      />
                    </div>
                  </section>
                ) : null}
              </DataEdit>
            </div>
          </div>
        </div>
      </UserPageStyles>

      {/* MODAL */}
      {showModalConference ? (
        <ModalContainer>
          <Modal
            title={`Add Conference / Role`}
            handleClose={() => {
              if (!isLoadingConferences && !isLoadingRoles) {
                setSelectConfenceRole({
                  ['conference_id']: '',
                  ['role_id']: '',
                });
                setShowModalConference(false);
              }
            }}
            body={
              <>
                {auth.role === 'admin' ? (
                  <div className="form-group">
                    <label htmlFor="conference_id">Conference</label>
                    <select
                      onChange={onChangeSelect}
                      disabled={isLoadingConferences}
                      name="conferenceId"
                      id="conference_id"
                      className="form-control"
                      value={selectConfenceRole.conferenceId}
                    >
                      <option disabled value="">
                        Select Conference
                      </option>
                      {listConferences &&
                        listConferences.map((item: any) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.domain}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : null}

                {auth.domain !== 'admin' ||
                (selectConfenceRole?.conferenceId &&
                  listConferences &&
                  listConferences.length > 0 &&
                  listConferences.find(
                    (item: any) => item?.id === selectConfenceRole?.conferenceId ?? '',
                  )?.domain !== 'admin') ? (
                  <div className="form-group">
                    <label htmlFor="role_id">Role</label>
                    <select
                      onChange={onChangeSelect}
                      disabled={isLoadingRoles}
                      name="roleId"
                      id="role_id"
                      className="form-control"
                      value={selectConfenceRole.roleId}
                    >
                      <option disabled value="">
                        Select Role
                      </option>
                      {listRoles &&
                        listRoles
                          .filter((item: any) => {
                            if (
                              selectConfenceRole?.conferenceId &&
                              listConferences.find(
                                (item: any) => item.id === selectConfenceRole.conferenceId,
                              ).name === 'admin'
                            ) {
                              return item;
                            }
                            return item.name !== 'admin';
                          })
                          .map((item: any) => (
                            <option key={`${item.id}`} value={item.id}>
                              {item.display}
                            </option>
                          ))}
                    </select>
                  </div>
                ) : null}

                {(auth.domain !== 'admin' ||
                  (selectConfenceRole?.conferenceId &&
                    listConferences.find((item: any) => item.id === selectConfenceRole.conferenceId)
                      .domain !== 'admin')) &&
                selectConfenceRole.roleId &&
                listRoles.find((item: any) => item.id === selectConfenceRole.roleId).name ===
                  'speaker' ? (
                  <div className="form-group">
                    <label htmlFor="quantity">Slots</label>
                    <input
                      disabled={isLoadingRoles}
                      onChange={onChangeInputConference}
                      id="quantity"
                      value={selectConfenceRole?.quantity ?? '1'}
                      type="number"
                      name="quantity"
                      className="form-control"
                      min="1"
                    />
                  </div>
                ) : null}

                {(auth.domain !== 'admin' ||
                  (selectConfenceRole?.conferenceId &&
                    listConferences.find((item: any) => item.id === selectConfenceRole.conferenceId)
                      .domain !== 'admin')) &&
                selectConfenceRole.roleId &&
                listRoles.find((item: any) => item.id === selectConfenceRole.roleId).name ===
                  'speaker' ? (
                  <div className="form-group">
                    <label htmlFor="expires_at">Access Expiration</label>
                    <input
                      disabled={isLoadingRoles}
                      onChange={onChangeInputConference}
                      id="expires_at"
                      value={
                        selectConfenceRole?.expires_at
                          ? selectConfenceRole.expires_at.slice(0, 10)
                          : ''
                      }
                      type="date"
                      name="expires_at"
                      className="form-control"
                    />
                  </div>
                ) : null}
              </>
            }
            footer={
              <>
                <button
                  disabled={isLoadingConferences || isLoadingRoles}
                  onClick={() => setShowModalConference(false)}
                  className="btn flex-fill btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  disabled={isLoadingConferences || isLoadingRoles}
                  onClick={() => {
                    updateUserConferenceRole(id as string, selectConfenceRole);
                    setShowModalConference(false);
                  }}
                  className="btn flex-fill btn-outline-success"
                >
                  Update
                </button>
              </>
            }
          />
        </ModalContainer>
      ) : null}
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default UserPage;
