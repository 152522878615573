// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

// Helpers
import { CONST } from '../../utils/helpers';

// Styles
import FullPageLoader from '../../components/FullPageLoader';
import { useFetch } from '../Fetch';
import { useRollbar } from '@rollbar/react';
import { isBreakout } from '../../utils/url';

// Types
interface AuthProviderTypes {
  children: ReactNode;
}

// MAIN CONTEXT
// ------------------------------------------------------------
/**
 *
 */
const AuthContext = React.createContext<any>(null);

// MAIN PROVIDER
// ------------------------------------------------------------
/**
 *
 * @param param0
 */
const AuthProvider = ({ children }: AuthProviderTypes) => {
  // State / Props
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [auth, setAuth] = React.useState<any>();
  const isMountedRef = React.useRef(false);

  // Providers
  const { pathname, search } = useLocation();
  const { fetch, setToken, token } = useFetch();
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const rollbar = useRollbar();

  // Request
  const getMe = React.useCallback(
    (existingAuth: any) => {
      fetch(
        {
          url: `/auth/me`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          const { data } = response.data;
          const domain = window.REACT_APP_DEBUG
            ? window.REACT_APP_DEBUG_DOMAIN
            : window.location.host.split('.')?.[0];
          const domainIndex = window.REACT_APP_DEBUG
            ? data.conferences_roles.findIndex(
                (item: any) => item.Conference.domain === window.REACT_APP_DEBUG_DOMAIN,
              )
            : data.conferences_roles.findIndex((item: any) => item.Conference.domain === domain);
          const conferenceId = data?.conferences_roles?.[domainIndex]?.conferenceId;
          const role = data?.conferences_roles?.[domainIndex]?.Role?.name ?? null;
          setAuth({ ...existingAuth, ...data, domain, domainIndex, role, conferenceId });
          setIsLoaded(true);
        },
        () => {
          throw Error('Could not retrive user data.');
        },
      );
    },
    [fetch],
  );

  // Hooks
  /**
   * Already Authenticated
   */
  React.useEffect(() => {
    if (
      isLoading ||
      pathname.startsWith(CONST.posterRoute) ||
      isLoaded ||
      !isAuthenticated ||
      !isMountedRef.current
    )
      return;
    const getToken = async () => {
      try {
        const response = await getAccessTokenSilently();
        setToken(response);
      } catch (error) {
        rollbar.error('Erro on getting token silently', { error });
        throw Error('Something went wrong.');
      }
    };

    getToken();
  }, [
    isLoading,
    pathname,
    isLoaded,
    isAuthenticated,
    fetch,
    getAccessTokenSilently,
    token,
    setToken,
  ]);

  /**
   * Not Authenticated
   */
  React.useEffect(() => {
    if (
      isLoading ||
      pathname.startsWith(CONST.posterRoute) ||
      isLoaded ||
      isAuthenticated ||
      !isMountedRef.current
    )
      return;

    loginWithRedirect({ appState: { returnTo: pathname } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLoaded, pathname, isAuthenticated]);

  /**
   * Authenticated - Retrieve User Information
   */
  React.useEffect(() => {
    if (
      pathname.startsWith(CONST.posterRoute) ||
      isLoading ||
      isLoaded ||
      !isAuthenticated ||
      !token ||
      !isMountedRef.current
    )
      return;
    getMe(auth);
  }, [isLoading, pathname, isLoaded, isAuthenticated, token, auth, getMe]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Render
  /**
   *
   */
  if (
    !pathname.startsWith(CONST.posterRoute) &&
    !pathname.startsWith('/notfound') &&
    !isBreakout(search) &&
    !isLoaded
  )
    return <FullPageLoader />;

  /**
   *
   */
  return <AuthContext.Provider value={{ isLoaded, auth, getMe }}>{children}</AuthContext.Provider>;
};

// MAIN CONSUMER
// ------------------------------------------------------------
/**
 *
 */
const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('AuthContext not set!');
  }
  return context;
};

// EXPORTS
// ------------------------------------------------------------
export default AuthProvider;
export { AuthContext, useAuth };
