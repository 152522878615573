// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Facebook, Globe, Home, Instagram, Twitter, X } from 'react-feather';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import 'katex/dist/katex.min.css';

// Providers
// import { useModal } from '../../providers/Modal';
// import { useFetch } from '../../providers/Fetch';
// import { useLoader } from '../../providers/Loader';

// Styles
import { DarkTemplateStyles } from './styles';

// Components
import { ID } from '../../components/Icons';
import { Placeholder } from '../../components/Icons';
import FontPicker from 'font-picker-react';
import PosterLayout from '../../components/PosterLayout';
import { CardItems, CardItemsType } from '../../components/PosterCard';
import { LayoutType, UserType } from '../../typings';

// Types
interface DarkTemplateTypes {
  url?: string;
  homeActive?: boolean;
  fontHeader: string;
  fontBody: string;
  backgroundOpacity?: number;
  layout: LayoutType;
  title: string;
  subtitle?: string;
  altTitle?: string;
  background?: string;
  edit?: string;
  cards?: CardItemsType;
  handleEditSection?: (id: string, layout?: string) => void;
  preview?: boolean;
  user: UserType;
  titleFontColor?: string;
  bodyFontColor?: string;
  backgroundColor?: string;
  titlebarColor?: string;
  cardColor?: string;
}

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const DarkTemplate = ({
  url,
  user,
  homeActive = false,
  fontHeader,
  backgroundOpacity,
  fontBody,
  layout,
  title,
  subtitle,
  altTitle,
  background,
  edit,
  cards = {},
  handleEditSection,
  preview = false,
  titleFontColor,
  bodyFontColor,
  backgroundColor,
  titlebarColor,
  cardColor,
}: DarkTemplateTypes) => {
  // State / Props
  const [modal, setModal] = React.useState<any>();
  const [content, setContent] = React.useState<any>();

  // Requests

  // Functions
  /**
   *
   * @param id
   */
  const onClickEdit = (id: string) => () => {
    if (handleEditSection) {
      handleEditSection(id, layout.name.toLowerCase().replace(' ', ''));
    }
  };

  /**
   *
   * @param id
   */
  const onClickCard = (id: string) => () => {
    if (preview) {
      setContent(id);
      setModal('card');
    }
  };

  // Render
  return (
    <DarkTemplateStyles
      id="template"
      opacity={backgroundOpacity}
      {...{ backgroundColor, titleFontColor, bodyFontColor, background, titlebarColor, cardColor }}
    >
      {/* FontPicker must be called to load the correct font family on first render */}
      <div style={{ display: 'none' }}>
        <FontPicker
          pickerId="headingTypography2"
          apiKey={window.REACT_APP_GOOGLE_FONTS_KEY}
          activeFontFamily={fontHeader || undefined}
        />
        <FontPicker
          pickerId="bodyTypography2"
          apiKey={window.REACT_APP_GOOGLE_FONTS_KEY}
          activeFontFamily={fontBody || undefined}
        />
      </div>
      <div className="inner">
        <header>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1 className="apply-font-headingTypography apply-font-headingTypography2">
                  {title}
                </h1>
                {subtitle && (
                  <p className="apply-font-bodyTypography apply-font-bodyTypography2">{subtitle}</p>
                )}
                {altTitle && (
                  <p className="apply-font-bodyTypography apply-font-bodyTypography2">{altTitle}</p>
                )}
                <Link
                  to={`${homeActive ? '/' : '#home'}`}
                  onClick={(e: any) => {
                    if (!homeActive) {
                      e.preventDefault();
                    }
                  }}
                >
                  <Home />
                  <span>Home</span>
                </Link>
                <aside>
                  <button onClick={() => setModal('qr')}>QR</button>
                  <button onClick={() => setModal('profile')}>
                    {user.photo ? (
                      <img src={user.photo} alt={`${user.firstName} ${user.lastName}`} />
                    ) : (
                      <Placeholder width="60px" height="60px" placeholder="" />
                    )}
                  </button>
                  {user?.institution ? (
                    <span
                      className="institution"
                      style={{ backgroundImage: `url(${user?.institution})` }}
                    ></span>
                  ) : null}
                </aside>
                {!preview ? (
                  <span
                    className={`edit ${edit === 'header' ? 'editing' : ''}`}
                    onClick={onClickEdit('header')}
                  ></span>
                ) : null}
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="container-fluid">
            <PosterLayout
              variant={layout.name}
              {...{ onClickCard, fontHeader, fontBody, cards, preview, edit, onClickEdit }}
            />
          </div>
        </main>
        {modal ? (
          <article className={`${modal}`}>
            <aside>
              {modal === 'card' ? (
                <section className={`modal-card`}>
                  <div
                    className={`columnCard apply-font-headingTypography apply-font-headingTypography2 apply-font-bodyTypography apply-font-bodyTypography2`}
                  >
                    {cards?.[content] && cards?.[content].length > 0 ? (
                      <CardItems data={cards[content]} cardIndex={content} />
                    ) : null}
                  </div>
                </section>
              ) : null}
              {modal === 'qr' ? (
                <section>
                  <QRCode value={url || ''} />
                </section>
              ) : null}
              {modal === 'profile' ? (
                <section>
                  <header>
                    {user.photo ? (
                      <img src={user.photo} alt={`${user.firstName} ${user.lastName}`} />
                    ) : (
                      <Placeholder placeholder="" />
                    )}
                    {user.petPhoto ? <img src={user.petPhoto} alt={`${user.petName}`} /> : null}
                    <h2>
                      {user.firstName} {user.lastName}
                    </h2>
                    <p>
                      {user.pronoun} {user.school ? `- ${user.school}` : ''}{' '}
                      {user.location ? `- ${user.location}` : ''}
                    </p>
                  </header>
                  <main>
                    {user.biography ? (
                      <>
                        <h3>Biography</h3>
                        <p>{user.biography}</p>
                      </>
                    ) : null}
                    <ul>
                      {user.twitter ? (
                        <li>
                          <span className="social social-twitter">
                            <Twitter fill="white" stroke="0px" />
                          </span>
                          <a href={user.twitter} target="_blank" rel="noopener noreferrer">
                            {user.twitter}
                          </a>
                        </li>
                      ) : null}
                      {user.facebook ? (
                        <li>
                          <span className="social social-facebook">
                            <Facebook fill="white" stroke="0px" />
                          </span>
                          <a href={user.facebook} target="_blank" rel="noopener noreferrer">
                            {user.facebook}
                          </a>
                        </li>
                      ) : null}
                      {user.instagram ? (
                        <li>
                          <span className="social social-instagram">
                            <Instagram />
                          </span>
                          <a href={user.instagram} target="_blank" rel="noopener noreferrer">
                            {user.instagram}
                          </a>
                        </li>
                      ) : null}
                      {user.website ? (
                        <li>
                          <span className="social social-website">
                            <Globe />
                          </span>
                          <a href={user.website} target="_blank" rel="noopener noreferrer">
                            {user.website}
                          </a>
                        </li>
                      ) : null}
                      {user.orcid ? (
                        <li>
                          <span className="social social-orcid">
                            <ID />
                          </span>
                          <a href={user.orcid} target="_blank" rel="noopener noreferrer">
                            {user.orcid}
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </main>
                </section>
              ) : null}
            </aside>
            <button
              type="button"
              onClick={() => {
                setModal(null);
                setContent(null);
              }}
            >
              <X />
            </button>
          </article>
        ) : null}
      </div>
    </DarkTemplateStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default DarkTemplate;
