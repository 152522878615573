// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Types
interface PosterEditorProviderTypes {
  children: ReactNode;
}

// MAIN CONTEXT
// ------------------------------------------------------------
/**
 *
 */
const PosterEditorContext = React.createContext<any>(null);

// MAIN PROVIDER
// ------------------------------------------------------------
/**
 *
 * @param param0
 */
const PosterEditorProvider = ({ children }: PosterEditorProviderTypes) => {
  // State / Props
  const [poster, setPoster] = React.useState<any>({
    show: true,
    current: 'settings',
  });
  const options = ['settings', 'layout', 'bio', 'graphics'];

  // Render
  return (
    <PosterEditorContext.Provider
      value={{
        options,
        poster,
        setPoster,
      }}
    >
      {children}
    </PosterEditorContext.Provider>
  );
};

// MAIN CONSUMER
// ------------------------------------------------------------
/**
 *
 */
const usePosterEditor = () => {
  const context = React.useContext(PosterEditorContext);
  if (!context) {
    throw new Error('PosterEditorContext not set!');
  }
  return context;
};

// EXPORTS
// ------------------------------------------------------------
export default PosterEditorProvider;
export { PosterEditorContext, usePosterEditor };
