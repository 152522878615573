import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const getConferenceStatus = (startsAt?: string, finishesAt?: string) => {
  const now = dayjs();

  return {
    isBefore: now.isBefore(dayjs(startsAt)),
    isLive: now.isAfter(dayjs(startsAt)) && dayjs(finishesAt).isAfter(now),
    isAfter: dayjs(finishesAt).isBefore(now),
    hasDate: !!startsAt && !!finishesAt,
  };
};

export const getStartDiff = (startsAt: string) => {
  const timezone = dayjs.tz.guess();
  return dayjs.tz(startsAt, timezone).diff(dayjs.tz(dayjs(), timezone), 's');
};

export const getCountdownDate = (date: string) => dayjs.utc(date).format(`MMMM DD, YYYY (z)`);
