// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { ModalAsideStyles } from '../../components/ModalAside/styles';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const HomeEmptyStyles = styled.div`
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  min-height: 50vh;
  width: 100%;

  h2 {
    color: #333;
  }

  p {
    color: #999;
  }
`;

export const HomePageStyles = styled.div`
  header {
    background-color: ${Colors.lightGrey};
    background-size: cover;
    background-position: center center;

    .inner {
      /* background: rgba(255, 255, 255, 0.6); */
      > img {
        width: 100%;
        height: auto;
      }
    }

    .container-fluid,
    .row,
    .col {
      height: 100%;
    }

    .col {
      display: flex;
      align-self: center;
      justify-content: center;
    }

    h1 {
      margin: 0;
      line-height: 200px;
    }
  }

  /* FILTERS */
  section {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${Colors.altLightGrey};
    position: relative;

    .col {
      line-height: 38px;
      margin-bottom: 15px;
      > .dropdown {
        input[type='search'] {
          margin-right: -5px;
        }
      }

      &:nth-child(2) {
        ul {
          li {
            flex: none;
            &:first-child {
              width: calc(60% - 30px);
              padding-right: 20px;
              margin-right: 0px;
              border-right: 1px solid #e1dfdf;
            }

            &:nth-child(2) {
              width: calc(40% - 10px);
              margin-left: 20px;
            }
          }
        }
      }
    }

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto 0;
      width: 16px;
      height: 16px;
      stroke-width: 2px;
      color: #becad2;
      polygon {
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 -10px;
      display: flex;

      li {
        float: left;
        font-size: 12px;
        flex: 1;
        margin: 0 10px;

        .form-control {
          &:focus ~ .dropdown-menu {
            display: block;
          }

          .dropdown-menu {
            span {
              padding: 0.25rem 1.5rem;
            }
          }
        }

        &:hover {
          .dropdown-menu {
            display: block;
          }
        }
        input {
          &:disabled ~ .dropdown-menu {
            display: none;
          }
        }

        a {
          color: ${Colors.grey};
        }
      }
    }

    input {
      background: #f5f5f5;
      padding-left: 35px;
    }

    input[type='search'] {
      border: none;
      margin-right: -15px;
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }

    .btn-group {
      width: 100%;
      .btn {
        text-align: left;
        height: 38px;

        svg {
          color: white;
        }

        &:nth-child(2) {
          width: 46px;
          text-align: center;
        }
      }
    }
    .btn.btn-dark {
      width: calc(60% - 10px);
      margin-left: -5px;
      margin-right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        margin-right: 6px;
      }
    }
    .btn.btn-secondary {
      margin-right: -5px;
      width: calc(40% - 5px);
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        display: none;
      }

      svg {
        margin-right: 6px;
      }
    }

    button {
      position: relative;
      width: 100%;
      span {
        display: none;
      }

      svg {
        position: unset;
      }
    }

    @media (min-width: 768px) {
      .col {
        margin-bottom: 0px;
        &:nth-child(1) {
          padding-right: 0px;
        }
      }
    }

    @media (min-width: 992px) {
      .col {
        .btn-dark,
        .btn-secondary {
          span {
            display: inline;
          }
        }

        &:nth-child(2) {
          ul {
            li {
              flex: none;
              &:first-child {
                width: calc(70% - 30px);
              }

              &:nth-child(2) {
                width: calc(30% - 10px);
              }
            }
          }
        }
      }
    }

    @media (min-width: 1200px) {
      .col {
        /* .btn-dark {
          width: calc(70% - 10px);
        }
        .btn-secondary {
          width: calc(30% - 5px);
        } */

        &:nth-child(2) {
          ul {
            li {
              flex: none;
              &:first-child {
                width: calc(65% - 30px);
              }

              &:nth-child(2) {
                width: calc(35% - 10px);
              }
            }
          }
        }
      }
    }
  }
  /* MAIN */
  main {
    padding-top: 20px;
    padding-bottom: 20px;

    a {
      display: block;
      border-radius: 0.4rem;
      border: 2px solid ${Colors.white};
      color: ${Colors.iconGrey};
      transition: all 250ms ease-in-out 0s;

      &:hover {
        border: 2px solid ${Colors.blue};
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
      }
    }

    article {
      cursor: pointer;
      position: relative;

      &:hover {
        .content {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.700437675070028) 34%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 15px;
      min-height: 60px;
      position: relative;
      picture {
        background: #efefef;
        background-size: cover;
        background-position: center center;
        width: 60px;
        height: 60px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 40px;
        display: block;
      }

      p {
        width: calc(100% - 75px - 70px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 18px;
        line-height: 21px;
        color: #666666;
        margin: 0 0 0 75px;

        small {
          font-size: 14px;
          line-height: 16px;
          color: #82bdf4;
        }
      }

      span {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 30px;
        max-width: 70px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
        padding: 0 8px;
        color: #6f6f6f;
        font-weight: bold;
        font-size: 13px;
        line-height: 30px;
        border-radius: 4px;
        border: 1px solid #ebebeb;
        margin: auto 0;
      }
    }

    .preview {
      display: block;
      border-radius: 4px;
      background-size: cover;
      background-position: center center;
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .content {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: left;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0.500437675070028) 34%,
        rgba(0, 0, 0, 0) 100%
      );
      border-radius: 4px;
      transition: all 250ms ease-in-out 0s;
      position: absolute;

      h4 {
        color: white;
        font-size: 21px;
        line-height: 24px;
        margin: 0;
        font-weight: normal;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        margin: 0px;
      }
    }
  }

  ${ModalAsideStyles} {
    main {
      padding: 0;
    }

    aside {
      padding: 30px 15px 60px 15px;

      header {
        background: none;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        min-height: 60px;
        padding-left: 70px;

        &.no-image {
          padding-left: 0px;
        }

        span,
        img {
          background: #efefef;
          width: 60px;
          height: 60px;
          border-radius: 60px;
          position: absolute;
          left: 0;
          top: 0;
        }

        h3 {
          font-size: 24px;
          line-height: 32px;
          margin: 0;
        }

        p {
          font-size: 14px;
          line-height: 17px;
          color: #666666;
          margin: 0;
        }
      }

      section {
        padding-top: 20px;
        padding-bottom: 20px;

        &.poster {
          .author {
            display: block;
            min-height: 36px;
            margin-bottom: 20px;
            padding-left: 46px;
            position: relative;
            .thumb {
              width: 36px;
              height: 36px;
              border-radius: 36px;
              position: absolute;
              left: 0;
            }

            p {
              font-size: 14px;
              line-height: 17px;
              color: #666666;
              margin: 0;

              small {
                font-size: 12px;
                line-height: 15px;
                color: #82bdf4;
              }
            }
          }
        }

        &:nth-child(2) {
          margin-top: 24px;
          border-top: 1px solid #eeeeee;
        }

        p {
          color: #81878c;
          strong {
            color: #666666;
          }
        }

        ul {
          display: block;
          margin: 0;
          li {
            display: block;
            float: none;
            margin: 0 0 20px 0;
            width: 100%;
            a {
              display: block;
            }
          }
        }

        button {
          width: 100%;
          color: white;
          font-weight: 500;
          position: relative;
          padding-left: 60px;
          padding-right: 60px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          svg {
            position: absolute;
            left: 15px;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }

          span {
            svg {
              left: auto;
              right: 15px;
              path,
              polyline,
              line {
                color: white;
              }
            }
          }

          &.website {
            background: #333333;
            svg {
              color: white;
            }
          }

          &.facebook {
            background: #4267b2;

            svg {
              stroke: none;
              path {
                fill: white;
                stroke-width: none;
              }
            }

            span {
              svg {
                stroke: white;
                path {
                  fill: none;
                }
              }
            }
          }

          &.twitter {
            background: #1da1f2;

            svg {
              stroke: none;
              path {
                fill: white;
                stroke-width: none;
              }
            }

            span {
              svg {
                stroke: white;
                path {
                  fill: none;
                }
              }
            }
          }

          &.instagram {
            background: #a942b2;
            svg {
              color: white;
            }
          }
        }
      }
    }
  }
`;

export const ComingSoon = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
  padding: 0px 10px;

  h1 {
    font-size: 32px;

    span {
      color: ${Colors.blue};
    }
  }

  & > div {
    max-width: 900px;
  }

  @media (max-width: 600px) {
    gap: 50px;
  }
`;

export const ConferenceFinished = styled.div`
  margin: auto;
  width: 100%;
  margin-top: 30px;
  padding: 0px 10px;
  text-align: center;
`;
