// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Styles
import { FullPageLoaderStyles } from './styles';

// Types
interface FullPageLoaderTypes {
  position?: string;
  zIndex?: number;
  label?: string | ReactNode;
  progress?: number;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const FullPageLoader = ({
  position = 'fixed',
  zIndex = 10,
  label,
  progress,
}: FullPageLoaderTypes) => (
  <FullPageLoaderStyles position={position} zIndex={zIndex}>
    <div>
      <div className="spinner-border text-secondary" role="status">
        <span className="sr-only">Loading...</span>
      </div>

      {label && <div className="label">{label}</div>}
      {typeof progress === 'number' && (
        <div
          className="progress"
          role="progressbar"
          aria-label="Saving progress"
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
    </div>
  </FullPageLoaderStyles>
);

// EXPORTS
// ------------------------------------------------------------
export default FullPageLoader;
