// IMPORTS
// ------------------------------------------------------------
import styled, { keyframes } from 'styled-components';
import Colors from '../../styles/Colors';

// Types
interface NotificationStylesTypes {
  show?: boolean;
  type: string;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0px;
  }
  to {
    opacity: 1;
    height: 44px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 44px;
  }
  to {
    opacity: 0;
    height: 0px;
  }
`;

const NotificationTypesBg: any = {
  success: Colors.green,
  error: Colors.red,
  warning: Colors.yellow,
};

const NotificationTypesText: any = {
  success: Colors.white,
  error: Colors.white,
  warning: Colors.dark,
};

/**
 *
 */
export const NotificationStyles = styled.div<NotificationStylesTypes>`
  display: block;
  width: 100%;
  height: 44px;
  background: ${({ type }) => NotificationTypesBg[type] || Colors.secondary};
  color: ${({ type }) => NotificationTypesText[type] || Colors.white};
  line-height: 44px;
  text-align: center;
  animation: ${({ show }) => (show ? fadeIn : fadeOut)} 250ms forwards 0s;
`;

// animation: ${fadeIn} 250ms forwards 0s;
// animation: ${({ show }) => (show ? fadeIn : fadeOut)} 250ms forwards 0s;

/**
 *
 */
export const NotificationProviderStyles = styled.div``;

/**
 *
 */
export const NotificationProviderContainerStyles = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9000;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;
