// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const ForbiddenPageStyles = styled.div`
  height: 100%;

  .container-fluid,
  .container,
  .row {
    height: 100%;
    .col {
      display: flex;
      align-items: center;
      .card {
        width: 100%;
      }
    }
  }
`;
