// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Styled
import { TableStyles } from './styles';

// Types
interface TableTypes {
  children: ReactNode;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const Table = ({ children }: TableTypes) => {
  return <TableStyles>{children}</TableStyles>;
};

// EXPORTS
// ------------------------------------------------------------
export default Table;
