// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Route, Routes as Router, useLocation } from 'react-router-dom';

// Helpers
import { CONST } from '../utils/helpers';

// Providers
import { useAuth } from '../providers/Auth';

// Page Components
import Home from '../pages/Home';
import Poster from '../pages/Poster';
import Unsupported from '../pages/Unsupported';
import Dashboard from '../pages/Dashboard';
import ConferencesList from '../pages/ConferencesList';
import ConferenceEdit from '../pages/ConferenceEdit';
import UsersList from '../pages/UsersList';
import UserEdit from '../pages/UserEdit';
import CategoriesList from '../pages/CategoriesList';
import CategoryEdit from '../pages/CategoryEdit';
import CategoryImport from '../pages/CategoryImport';
import PostersList from '../pages/PostersList';
import PosterEdit from '../pages/PosterEdit';
import PosterEditor from '../pages/PosterEditor';
import PostersImport from '../pages/PostersImport';
import ConferenceSettings from '../pages/ConferenceSettings';
import Account from '../pages/Account';
import NotFound from '../pages/NotFound';
import Forbidden from '../pages/Forbidden';
import Paywall from '../pages/Paywall';

// Private Routes
import PrivateRoute from './private';
import { isBreakout } from '../utils/url';

// IMPORTS
// ------------------------------------------------------------
const Routes = () => {
  // State / Props
  const { auth } = useAuth();
  const { search } = useLocation();

  // Render
  return (
    <Router>
      <Route path="/posters/:id" element={<Poster />} />
      <Route path="/unsupported" element={<Unsupported />} />
      {isBreakout(search) && <Route path="/" element={<Home />} />}
      {auth && (
        <>
          <Route
            path="/"
            element={
              <PrivateRoute isHome>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}`}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </>
      )}
      {['admin'].includes(auth?.role) && (
        <>
          <Route
            path={`${CONST.adminRoute}/conferences`}
            element={
              <PrivateRoute>
                <ConferencesList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/conferences/new`}
            element={
              <PrivateRoute>
                <ConferenceEdit />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/conferences/:id/edit`}
            element={
              <PrivateRoute>
                <ConferenceEdit />
              </PrivateRoute>
            }
          />
        </>
      )}
      {['admin', 'conference'].includes(auth?.role) && (
        <>
          <Route
            path={`${CONST.adminRoute}/users`}
            element={
              <PrivateRoute>
                <UsersList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/users/new`}
            element={
              <PrivateRoute>
                <UserEdit />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/users/:id/edit`}
            element={
              <PrivateRoute>
                <UserEdit />
              </PrivateRoute>
            }
          />
        </>
      )}
      {['speaker', 'conference'].includes(auth?.role) && (
        <Route
          path={`${CONST.adminRoute}/posters`}
          element={
            <PrivateRoute>
              <PostersList />
            </PrivateRoute>
          }
        />
      )}

      {['speaker'].includes(auth?.role) && (
        <>
          <Route
            path={`${CONST.adminRoute}/posters/:id/edit`}
            element={
              <PrivateRoute>
                <PosterEditor />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/paywall`}
            element={
              <PrivateRoute>
                <Paywall />
              </PrivateRoute>
            }
          />
        </>
      )}
      {['conference'].includes(auth?.role) && (
        <>
          <Route
            path={`${CONST.adminRoute}/posters/:id/edit`}
            element={
              <PrivateRoute>
                <PosterEdit />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/posters/import`}
            element={
              <PrivateRoute>
                <PostersImport />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/settings`}
            element={
              <PrivateRoute>
                <ConferenceSettings />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/categories`}
            element={
              <PrivateRoute>
                <CategoriesList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/categories/new`}
            element={
              <PrivateRoute>
                <CategoryEdit />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/categories/import`}
            element={
              <PrivateRoute>
                <CategoryImport />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONST.adminRoute}/categories/:id/edit`}
            element={
              <PrivateRoute>
                <CategoryEdit />
              </PrivateRoute>
            }
          />
        </>
      )}
      {auth ? (
        <Route
          path={`${CONST.adminRoute}/account`}
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />
      ) : null}
      {auth ? <Route path={`${CONST.adminRoute}/forbidden`} element={<Forbidden />} /> : null}
      <Route path="*" element={<NotFound />} />
    </Router>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default Routes;
