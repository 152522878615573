// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const ConferenceSettingsPageStyles = styled.div`
  .form-group {
    > svg {
      margin-top: 20px;
    }

    .preview {
      margin-top: 20px;
      display: block;
      position: relative;
      height: 120px;
      width: 100%;
      background-position: center center;
      background-size: cover;

      .btn {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;
        svg {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
`;
