// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// Styled Components
import { NavbarStyles } from '../../components/Navbar/styles';
import { FullPageLoaderStyles } from '../../components/FullPageLoader/styles';

// Types
interface PosterEditLayoutTypes {
  show?: boolean;
  hide?: boolean;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const PosterEditLayoutSidebarStyles = styled.div<PosterEditLayoutTypes>`
  width: 100%;
  transition: width 250ms ease-in-out 0s;

  @media (min-width: 992px) {
    position: absolute;
    width: ${({ show, hide }) => (hide ? '0px' : show ? '310px' : '54px')};
    overflow: ${({ hide }) => (hide ? 'hidden' : 'visible')};
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;

export const PosterEditLayoutMainStyles = styled.div<PosterEditLayoutTypes>`
  transition: margin 250ms ease-in-out 0s;

  ${NavbarStyles} {
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    /* margin-left: 310px;s */
    margin-left: ${({ show, hide }) => (hide ? '0px' : show ? '310px' : '54px')};
  }

  ${FullPageLoaderStyles} {
    opacity: 0.8;
  }
`;

export const PosterEditLayoutStyles = styled.div``;
