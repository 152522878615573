// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const FullLayoutStyles = styled.div`
  background: ${Colors.lightGrey};
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
