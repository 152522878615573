// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowLeft,
  ArrowRight,
  Volume2,
  Image,
  Youtube,
  Type,
  MessageCircle,
  ArrowUp,
  ArrowDown,
  X,
} from 'react-feather';
import ReactQuill from 'react-quill';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import { BsFillPlayFill, BsStopFill } from 'react-icons/bs';
import { MdReplay } from 'react-icons/md';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';

// Utils
import { CONST, hasValidSound, filterVideoUrl, sanitizeUser } from '../../utils/helpers';
import { editorSteps } from '../../utils/tutorial';

// Providers
import { useFetch } from '../../providers/Fetch';
import { useAuth } from '../../providers/Auth';
import { useNotification } from '../../providers/Notification';
import { useModal } from '../../providers/Modal';

// Layouts
import PosterEditLayout from '../../layouts/PosterEditLayout';

// Components
import Navbar from '../../components/Navbar';
import FullPageLoader from '../../components/FullPageLoader';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';

// Styles
import {
  PosterVideo,
  PosterSound,
  PosterQuote,
  PosterImage,
  PosterPageStyles,
  PosterTemplateStyles,
  PosterSideModalStyles,
  PosterSideModalButtonStyles,
  DraftModalContent,
  RecordModalContent,
} from './styles';
import Colors from '../../styles/Colors';

// Templates
import MinimalTemplate from '../../templates/minimal';
import DarkTemplate from '../../templates/dark';
import SingleTemplate from '../../templates/single';
import AudioPlayer from '../../components/AudioPlayer';
import { Helmet } from 'react-helmet';
import FontPicker from 'font-picker-react';
import { ImageInput } from '../../components/ImageInput';
import { Placeholder } from '../../components/Icons';
import { AxiosProgressEvent } from 'axios';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Types
type RecordData = {
  blob: Blob;
  type: string;
  url: string;
};

export const CustomToolbar = ({
  edit,
  keyValue,
  cards,
  setCards,
}: {
  edit?: string;
  keyValue: number;
  cards: any;
  setCards?: React.Dispatch<any>;
  quillColor: string;
  setQuillColor: React.Dispatch<any>;
}) => {
  return (
    <>
      <div className="toolbar" id={`toolbar-${edit}-${keyValue}`}>
        <div className="font-picker-wrapper">
          <FontPicker
            pickerId={`${edit}${keyValue}`}
            apiKey={window.REACT_APP_GOOGLE_FONTS_KEY}
            activeFontFamily={cards[edit as string][keyValue].fontFamily || undefined}
            onChange={(nextFont) => {
              setCards?.({
                ...cards,
                [edit as string]: cards[edit as string].map((card: any, key: number) =>
                  key === keyValue ? { ...card, fontFamily: nextFont.family } : card,
                ),
              });
            }}
          />
        </div>
        <select className="ql-header" defaultValue={''} onChange={(e) => e.persist()}>
          <option value="1" />
          <option value="2" />
          <option value="3" />
          <option selected />
        </select>
        <button className="ql-bold" title="Bold" />
        <button className="ql-italic" title="Italic" />
        <button className="ql-list" value="ordered" title="Ordered list" />
        <button className="ql-list" value="bullet" title="Bullet list" />
        <button className="ql-link" title="Link" />
        <button className="ql-formula" title="Formula" />
        <select className="ql-color">
          <option value="red"></option>
          <option value="green"></option>
          <option value="blue"></option>
          <option value="orange"></option>
          <option value="violet"></option>
          <option value="white"></option>
          <option value="#d0d1d2"></option>
          <option selected></option>
        </select>
      </div>
    </>
  );
};

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const PosterPage = () => {
  // State / Props
  const navigate = useNavigate();
  const { setModal } = useModal();
  const [preview, setPreview] = React.useState(false);
  const [input, setInput] = React.useState<any>(null);
  const [originalCards, setOriginalCards] = React.useState<any>({});
  const [layouts, setLayouts] = React.useState<Array<{ id: string; name: string }>>([]);
  const [templates, setTemplates] = React.useState<any>(null);
  const [cards, setCards] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingUpdateEntry, setIsLoadingUpdateEntry] = React.useState(false);
  const [isLoadingUpdateKeywords, setIsLoadingUpdateKeywords] = React.useState(false);
  const [isLoadingUpdateContents, setIsLoadingUpdateContents] = React.useState(false);
  const [isLoadingScreenshot, setIsLoadingScreenshot] = React.useState(false);
  const [savingStep, setSavingStep] = React.useState(0);

  const generalLoader =
    isLoadingUpdateEntry ||
    isLoadingUpdateKeywords ||
    isLoadingUpdateContents ||
    isLoadingScreenshot;

  const [edit, setEdit] = React.useState<any>(null);
  const [hasMadeChanges, setHasMadeChanges] = React.useState(false);
  const [user, setUser] = React.useState<any>(null);
  const [keywords, setKeywords] = React.useState<any>([]);
  const isMountedRef = React.useRef(false);
  const [showDraftModal, setShowDraftModal] = React.useState<boolean>(false);
  const [showTutorialModal, setShowTutorialModal] = React.useState<boolean>(false);
  const [joyrideStepIndex, setJoyrideStepIndex] = React.useState(0);
  const [joyrideEnabled, setJoyrideEnabled] = React.useState(() => {
    const isEnabled = localStorage.getItem('joyrideEnabled');
    if (!isEnabled || isEnabled === 'false') return false;
    return true;
  });
  const [showRecordModal, setShowRecordModal] = React.useState<{
    showModal: boolean;
    key?: number;
  }>({ showModal: false });
  const [record, setRecord] = React.useState<string | null>(null);
  const [recordData, setRecordData] = React.useState<RecordData | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [quillColor, setQuillColor] = React.useState<string>('#000000');

  const reactQuillRef = React.useRef<ReactQuill | null>(null);

  const { id } = useParams<{ id: string }>();
  const { auth } = useAuth();
  const { fetch } = useFetch();
  const { add } = useNotification();

  const qrCodeUrl = React.useMemo(() => `${window.location.origin}/posters/${id}`, []);

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (uuid: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/${
            auth.role === 'admin' ? 'posters' : `conferences/${auth.conferenceId}/posters`
          }/${uuid}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            setInput(response.data.data);
            fetch(
              {
                url: `/users/me`,
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
              (respMe: any) => {
                setUser(respMe.data.data);
                fetch(
                  {
                    url: `/conferences/${auth.conferenceId}/posters/${uuid}/contents?ordertake=1000`,
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  },
                  (resp: any) => {
                    const newCards = {};
                    if (resp?.data?.data?.length > 0) {
                      resp?.data?.data.map((item: any) => {
                        if (!(newCards as any)?.[`${item.card}`]) {
                          (newCards as any)[item.card] = [];
                        }

                        (newCards as any)[item.card].push({
                          type: item?.key ?? '',
                          value: item?.value ?? '',
                          alt: item?.alt ?? '',
                          meta: item?.meta ?? '',
                          order: item?.order ?? 0,
                          ...(item?.key === 'text' && {
                            fontFamily: item?.fontFamily ?? 'Open Sans',
                          }),
                        });
                        return item;
                      });
                      // Object.keys(newCards).map((i: any) => {
                      //   const find = (newCards as any)[i].find((item: any) => item.type === 'title');
                      //   if (!find) {
                      //     (newCards as any)[i].push({
                      //       type: 'title',
                      //       value: '',
                      //       alt: '',
                      //       meta: '',
                      //       order: 0,
                      //     });
                      //   }
                      //   return i;
                      // });

                      Object.keys(newCards).map((i: any) => {
                        if ((newCards as any)[i].length > 0) {
                          (newCards as any)[i].sort((a: any, b: any) => {
                            return a.order - b.order;
                          });
                        }
                        return i;
                      });
                    }

                    setCards(newCards);
                    // This state is used to compare unsaved changes while editing
                    setOriginalCards(JSON.parse(JSON.stringify({ ...newCards })));

                    fetch(
                      {
                        url: `/conferences/${auth.conferenceId}/posters/${uuid}/keywords?ordertake=1000`,
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      },
                      (keywordsResponse: any) => {
                        setKeywords(keywordsResponse?.data?.data || []);
                        setIsLoading(false);
                        setHasMadeChanges(false);
                      },
                      () => {
                        setIsLoading(false);
                      },
                    );
                  },
                  () => {
                    setIsLoading(false);
                  },
                );
              },
              () => {
                setIsLoading(false);
              },
            );
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        },
      );
    },
    [fetch, auth],
  );

  /**
   *
   */
  const getLayouts = React.useCallback(async () => {
    fetch(
      {
        url: `/layouts`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      (response: any) => {
        if (isMountedRef.current) {
          setLayouts(response.data.data);
        }
      },
      () => {
        if (isMountedRef.current) {
          add(CONST.REQUESTS.ERRORS.GET, 'error');
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, auth]);

  /**
   *
   */
  const getTemplates = React.useCallback(async () => {
    fetch(
      {
        url: `/templates`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      (response: any) => {
        if (isMountedRef.current) {
          setTemplates(response.data.data);
        }
      },
      () => {
        if (isMountedRef.current) {
          add(CONST.REQUESTS.ERRORS.GET, 'error');
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, auth]);

  /**
   *
   */
  const updateEntry = React.useCallback(
    async (data: any, callback?: () => void) => {
      setIsLoadingUpdateEntry(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/posters/${id}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        },
        (response: any) => {
          if (isMountedRef.current) {
            if (callback) {
              callback();
              setHasMadeChanges(false);
            } else {
              add(CONST.REQUESTS.SUCCESS.SAVED);
              getData(response.data.data.id);
            }
            setIsLoadingUpdateEntry(false);
            setSavingStep((step) => step + 25);
          }
        },
        (error: any) => {
          console.log(error);
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoadingUpdateEntry(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData],
  );

  /**
   *
   */
  const updateContents = React.useCallback(
    (uuid: string, data: any, callback?: () => void) => {
      setIsLoadingUpdateContents(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/posters/${uuid}/contents`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            contents: data,
          }),
        },
        () => {
          if (isMountedRef.current) {
            if (callback) {
              callback();
            } else {
              add(CONST.REQUESTS.SUCCESS.SAVED);
              setHasMadeChanges(false);
              getData(uuid);
            }
            setIsLoadingUpdateContents(false);
            setSavingStep((step) => step + 25);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoadingUpdateContents(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, auth],
  );

  /**
   *
   */
  const updateKeywords = React.useCallback(
    (uuid: string, data: any, callback?: () => void) => {
      setIsLoadingUpdateKeywords(true);

      fetch(
        {
          url: `/conferences/${auth.conferenceId}/posters/${uuid}/keywords`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            keywords: data,
          }),
        },
        () => {
          if (isMountedRef.current) {
            if (callback) {
              callback();
            } else {
              add(CONST.REQUESTS.SUCCESS.SAVED);
              setHasMadeChanges(false);
              getData(uuid);
            }
            setIsLoadingUpdateKeywords(false);
            setSavingStep((step) => step + 25);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoadingUpdateKeywords(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, auth],
  );

  /**
   *
   */
  const createScreenshot = React.useCallback(
    (originalData: any) => {
      if (window.REACT_APP_SCREENSHOTS_ALLOW) {
        setIsLoadingScreenshot(true);

        if (originalData.published) {
          updateEntry(originalData, () => {
            fetch(
              {
                url: `${window.REACT_APP_API_URL}/screenshots/capture?url=${window.location.protocol}//${window.location.host}/posters/${id}`,
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
              (response: any) => {
                if (isMountedRef.current) {
                  updateEntry({
                    ...originalData,
                    thumbnail: response?.data?.data?.key,
                  });
                  setIsLoadingScreenshot(false);
                  setSavingStep((step) => step + 25);
                }
              },
              () => {
                if (isMountedRef.current) {
                  setIsLoadingScreenshot(false);
                }
              },
            );
          });
        } else {
          if (isMountedRef.current) {
            updateEntry(originalData);
            setIsLoadingScreenshot(false);
            setSavingStep((step) => step + 25);
          }
        }
      } else {
        setSavingStep((step) => step + 25);
        updateEntry(originalData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch],
  );

  // Functions
  /**
   *
   */
  const onClickPreview = () => {
    const template = document.getElementById('template');
    const templateContainer = document.getElementById('template-container');
    templateContainer?.removeAttribute('style');
    template?.removeAttribute('style');
    setEdit(null);
    setPreview(!preview);
  };

  /**
   *
   */
  const onClickSave = (data: any) => {
    const dialogLabel = data?.published ? 'publish' : 'unpublish';

    if (data?.published !== input?.published && !window.confirm(`Save changes and ${dialogLabel}?`))
      return;

    const cardsData = Object.entries(cards)
      .map((item: any) => {
        return item[1].map((i: any, k: number) => ({
          key: i.type,
          value: i.value,
          alt: i.alt,
          meta: i.meta,
          order: k,
          card: Number(item[0]),
          ...(i.type === 'text' && { fontFamily: i.fontFamily || 'Open Sans' }),
        }));
      })
      .flat();

    setSavingStep(0);
    updateContents(id as string, cardsData, () =>
      updateKeywords(id as string, keywords, () => createScreenshot(data)),
    );

    setOriginalCards(JSON.parse(JSON.stringify({ ...cards })));
  };

  /**
   *
   */
  const onClickCancel = () => {
    if (
      !hasMadeChanges ||
      (hasMadeChanges && window.confirm('Are you sure leave and abandon your changes?'))
    ) {
      navigate(`${CONST.adminRoute}/posters`);
    }
  };

  /**
   *
   */
  const onClickCollapse = () => {
    const template = document.getElementById('template');
    const templateContainer = document.getElementById('template-container');
    templateContainer?.removeAttribute('style');
    template?.removeAttribute('style');
    setEdit(null);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) ||
      (joyrideStepIndex === 0 && index !== joyrideStepIndex)
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyrideEnabled(false);
      setJoyrideStepIndex(0);
      localStorage.setItem('joyrideEnabled', 'false');
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (index === 3) {
        setJoyrideEnabled(false);
        setTimeout(() => setJoyrideEnabled(true), 400);
      }
      setJoyrideStepIndex(stepIndex);
    }
  };

  const cancelTutorial = () => {
    localStorage.setItem('joyrideEnabled', 'false');
    setJoyrideStepIndex(0);
    setJoyrideEnabled(false);
  };

  const resetTutorial = () => {
    setJoyrideStepIndex(0);
    setJoyrideEnabled(false);
    setTimeout(() => setJoyrideEnabled(true), 400);
  };

  /**
   *
   * @param id
   * @param layout
   */
  const handleEditSection = (id: string, layout?: string) => {
    const template = document.getElementById('template');
    const templateContainer = document.getElementById('template-container');

    const card = document.getElementsByClassName('columnCard')[Number(id)];
    let inline: ScrollLogicalPosition = 'start';

    if (template && templateContainer && layout && card) {
      if (layout !== 'layout3') {
        const columns = layout
          ?.replace('layout', '')
          .split('')
          .reduce((acc, curr) => acc + Number(curr), 0);
        inline = Math.round(columns / 2) - 1 > Number(id) ? 'center' : 'start';
      }

      templateContainer.setAttribute(
        'style',
        layout === 'layout3' ? 'overflow-y: hidden;' : 'overflow: hidden;',
      );
      template.setAttribute('style', `width: 1600px; padding-right: 400px;`);
      card.scrollIntoView({ block: 'center', inline, behavior: 'smooth' });

      /*       if (id === 'header') {
        template.removeAttribute('style');
      } else if (layout === '2columns') {
        template.setAttribute(
          'style',
          `width: 1200px; margin-top: -106px; margin-left: ${
            isNaN(Number(id)) ? 0 : Number(id) * -582
          }px;`,
        );
      } else if (layout === '3columns') {
        template.setAttribute(
          'style',
          `width: 1200px; margin-top: -106px; margin-left: ${
            isNaN(Number(id)) ? 0 : Number(id) * -388
          }px;`,
        );
      } else if (layout === '4columns') {
        if (id === '0' || id === '1') {
          template.setAttribute(
            'style',
            `width: 1200px; margin-top: -106px; margin-left: ${
              isNaN(Number(id)) ? 0 : Number(id) * -582
            }px;`,
          );
        }
        if (id === '2' || id === '3') {
          template.setAttribute(
            'style',
            `width: 1200px; margin-top: -510px; margin-left: ${
              isNaN(Number(id)) ? 0 : (Number(id) - 2) * -582
            }px;`,
          );
        }
      } else if (layout === '6columns') {
        if (id === '0') {
          template.setAttribute(
            'style',
            `width: 1200px; margin-top: -106px; margin-left: ${isNaN(Number(id)) ? 0 : 0}px;`,
          );
        } else if (id === '1') {
          template.setAttribute(
            'style',
            `width: 1200px; margin-top: -496px; margin-left: ${isNaN(Number(id)) ? 0 : 0}px;`,
          );
        } else if (id === '2') {
          template.setAttribute(
            'style',
            `width: 1200px; margin-top: -106px; margin-left: ${
              isNaN(Number(id)) ? 0 : 1 * -292
            }px;`,
          );
        } else if (id === '3') {
          template.setAttribute(
            'style',
            `width: 1200px; margin-top: -106px; margin-left: ${
              isNaN(Number(id)) ? 0 : 2 * -292
            }px;`,
          );
        } else if (id === '4') {
          template.setAttribute(
            'style',
            `width: 1200px; margin-top: -106px; margin-left: ${
              isNaN(Number(id)) ? 0 : 3 * -292
            }px;`,
          );
        } else if (id === '5') {
          template.setAttribute(
            'style',
            `width: 1200px; margin-top: -496px; margin-left: ${
              isNaN(Number(id)) ? 0 : 3 * -292
            }px;`,
          );
        }
      } else {
        template.setAttribute('style', `width: 1200px; margin-top: -596px; margin-left: ${isNaN(Number(id)) ? 0 : Number(id) * -390}px;`);
      } */
    }
    setEdit(id);
  };

  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasMadeChanges(true);
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  /**
   *
   * @param card
   * @param key
   */
  const onChangeTextareaCard =
    (card: any, key: number) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setHasMadeChanges(true);
      const newCards = { ...cards };
      newCards[card][key].value = event.target.value;
      setCards(newCards);
    };

  /**
   *
   * @param card
   * @param key
   */
  const onChangeInputCard =
    (card: any, key: number, field?: string, type?: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setHasMadeChanges(true);
      const newCards = { ...cards };
      if (type) {
        const findIndex = newCards[card].findIndex((i: any) => i.type === type);
        if (findIndex > -1) {
          newCards[card][findIndex].value = event.target.value;
        }
      } else if (field) {
        newCards[card][key][field] = event.target.value;
      } else {
        newCards[card][key].value = event.target.value;
      }
      setCards(newCards);
    };

  /**
   *
   * @param card
   * @param type
   */
  const onClickAddCard =
    (card: string, type: string, prepend: boolean | undefined = false) =>
    () => {
      setHasMadeChanges(true);
      const newCards = { ...cards };
      if (!newCards[card]) {
        newCards[card] = [];
      }
      const item: any = {
        type,
        value: '',
        alt: '',
        meta: '',
      };

      if (prepend) {
        newCards[card].unshift(item);
      } else {
        newCards[card].push(item);
      }
      setCards(newCards);
    };

  /**
   *
   * @param card
   * @param key
   */
  const onClickUp = (card: string, key: number) => () => {
    setHasMadeChanges(true);
    if (key === 0) return;
    const newCards = { ...cards };
    [newCards[card][key - 1], newCards[card][key]] = [newCards[card][key], newCards[card][key - 1]];
    setCards(newCards);
  };

  /**
   *
   * @param card
   * @param key
   */
  const onClickDown = (card: string, key: number) => () => {
    setHasMadeChanges(true);
    const newCards = { ...cards };
    if (key + 1 >= newCards[card].length) return;
    [newCards[card][key + 1], newCards[card][key]] = [newCards[card][key], newCards[card][key + 1]];
    setCards(newCards);
  };

  /**
   *
   * @param card
   * @param key
   */
  const onClickRemove = (card: string, key: number) => {
    setHasMadeChanges(true);
    const newCards = { ...cards };
    newCards[card] = [...newCards[card].slice(0, key), ...newCards[card].slice(key + 1)];
    setCards(newCards);
  };

  // /**
  //  *
  //  * @param event
  //  */
  // const onChangeFileOrRecord = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsLoading(true);
  //   const formData = new FormData();
  //   formData.append('files', event.target.files?.[0] as any);
  //   fetch(
  //     {
  //       url: `/uploads`,
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       data: formData,
  //     },
  //     (response: any) => {
  //       event.target.value = '';
  //       setInput({
  //         ...input,

  //       })
  //       // updateEntry({ ...input, photo: response?.data?.data?.[0]?.key });
  //     },
  //     (errors: any) => {
  //       event.target.value = '';
  //       setIsLoading(false);
  //     }
  //   );
  // };

  const callUpload = (
    card: any,
    key: number,
    event?: React.ChangeEvent<HTMLInputElement>,
    field?: string,
    onUploadProgress?: (e: AxiosProgressEvent) => void,
  ) => {
    const formData = new FormData();
    formData.append(
      'files',
      recordData
        ? recordData.blob
        : ((event as React.ChangeEvent<HTMLInputElement>).target.files?.[0] as any),
    );
    fetch(
      {
        url: `/uploads`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress,
      },
      (response: any) => {
        if (isMountedRef.current) {
          if (event) event.target.value = '';
          setHasMadeChanges(true);
          const newCards = { ...cards };
          if (field) {
            newCards[card][key][field] = response?.data?.data?.[0]?.key ?? '';
          } else {
            newCards[card][key].value = response?.data?.data?.[0]?.key ?? '';
          }
          if (recordData) newCards[card][key].type = 'sound';
          setCards(newCards);
          add(CONST.REQUESTS.SUCCESS.UPLOADING);
          setRecordData(null);
          setRecord(null);
          setShowRecordModal({ showModal: false });
          setLoading(false);
        }
      },
      (errors: any) => {
        console.error(errors);
        if (event) event.target.value = '';
        if (isMountedRef.current) {
          add(CONST.REQUESTS.ERRORS.UPLOADING, 'error');
        }
      },
    );
  };

  /**
   *
   * @param event
   */
  const onChangeFileOrRecord =
    (card: any, key: number, field?: string) =>
    (
      event: React.ChangeEvent<HTMLInputElement>,
      onUploadProgress?: (e: AxiosProgressEvent) => void,
    ) => {
      callUpload(card, key, event, field, onUploadProgress);
    };

  /**
   *
   * @param event
   */
  const uploadRecord = (card: any, key: number, field?: string) => {
    setLoading(true);
    callUpload(card, key, undefined, field);
  };

  // Hook
  /**
   *
   */
  React.useEffect(() => {
    getLayouts();
    getTemplates();
    getData(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    window.katex = katex;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (showTutorialModal) {
      setModal(
        <ModalContainer>
          <Modal
            title={'Do you want a tutorial on how to use the editor?'}
            hasBody={false}
            showClose={false}
            footer={
              <>
                <button
                  onClick={() => {
                    setModal(null);
                    setShowTutorialModal(false);
                  }}
                  className="btn flex-fill btn-outline-danger"
                >
                  No
                </button>
                <button
                  onClick={() => {
                    setModal(null);
                    setShowTutorialModal(false);
                    setJoyrideEnabled(true);
                  }}
                  className="btn flex-fill btn-outline-success"
                >
                  Yes
                </button>
              </>
            }
          />
        </ModalContainer>,
      );
    }
  }, [showTutorialModal, setModal]);

  // Add joyride class to the first editable card
  React.useEffect(() => {
    const firstColumnChild = document.querySelector<HTMLDivElement>('.columnCard');
    firstColumnChild?.classList?.add('second-step');
    firstColumnChild?.addEventListener('click', () => {
      if (joyrideEnabled) setJoyrideStepIndex(joyrideStepIndex + 1);
    });
  }, [joyrideEnabled, joyrideStepIndex]);

  // Render
  return (
    <>
      <Joyride
        showProgress
        continuous={true}
        run={joyrideEnabled}
        scrollToFirstStep={true}
        showSkipButton={true}
        steps={editorSteps({ cancelTutorial, resetTutorial })}
        stepIndex={joyrideStepIndex}
        callback={handleJoyrideCallback}
      />
      <PosterEditLayout
        data={input}
        keywords={keywords}
        setKeywords={setKeywords}
        layouts={layouts}
        templates={templates}
        setData={setInput}
        setEdit={setEdit}
        preview={preview}
        setHasMadeChanges={setHasMadeChanges}
        hasMadeChanges={hasMadeChanges}
      >
        <Helmet>
          <title>Poster Editor{input?.title ? ` | ${input.title}` : ''}</title>
        </Helmet>
        <PosterPageStyles>
          {showDraftModal && (
            <ModalContainer>
              <Modal
                hasFooter={false}
                title="Share draft"
                handleClose={() => setShowDraftModal(false)}
                body={
                  <DraftModalContent>
                    <span>
                      Share this url of your poster for validation. People with this url will not be
                      able to edit your poster.
                    </span>
                    <div>
                      <span>{`${window.location.href
                        .replace('/admin', '')
                        .replace('/edit', '')}?mode=draft`}</span>
                      <CopyToClipboard
                        text={`${window.location.href
                          .replace('/admin', '')
                          .replace('/edit', '')}?mode=draft`}
                      >
                        <button className="btn btn-primary">Copy</button>
                      </CopyToClipboard>
                    </div>
                    {JSON.stringify(cards) !== JSON.stringify(originalCards) && (
                      <span>
                        Some of your changes are not saved and will only be available on your draft
                        after you save them
                      </span>
                    )}
                  </DraftModalContent>
                }
              />
            </ModalContainer>
          )}
          <Navbar>
            <div className="container-fluid">
              <div className="row">
                <div className="col col-6 col-md-3">
                  {!generalLoader ? (
                    <h6>
                      <span
                        className={`badge badge-${
                          input?.published ? 'success' : 'secondary'
                        } seventh-step`}
                      >
                        {input?.published ? 'Published ' : 'Not Published'}
                      </span>{' '}
                    </h6>
                  ) : null}
                </div>
                <div className="col col-18 col-md-9">
                  {!preview ? (
                    <>
                      <button
                        onClick={() => setShowTutorialModal(true)}
                        type="button"
                        className="nineth-step"
                        style={{ background: 'none', border: 0 }}
                      >
                        <AiOutlineQuestionCircle size={30} fill={Colors.homeLightGrey} />
                      </button>
                      <button
                        onClick={onClickCancel}
                        type="button"
                        className="btn btn-outline-light eighth-step"
                      >
                        Exit Editor
                      </button>
                    </>
                  ) : null}
                  {!input?.published && (
                    <button
                      onClick={() => setShowDraftModal(true)}
                      type="button"
                      className={`btn btn-info`}
                    >
                      Share draft
                    </button>
                  )}
                  <button
                    onClick={onClickPreview}
                    type="button"
                    className={`btn btn-${preview ? 'outline-light' : 'secondary'} fourth-step`}
                  >
                    {preview ? 'Exit Preview' : 'Preview'}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success fifth-step"
                    onClick={() => onClickSave(input)}
                  >
                    {input?.published ? 'Save' : 'Save without publish'}
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary sixth-step"
                    onClick={() => onClickSave({ ...input, published: !input.published })}
                  >
                    {input?.published ? 'Unpublish' : 'Publish'}
                  </button>
                </div>
              </div>
            </div>
          </Navbar>
          <PosterTemplateStyles id="template-container">
            {isLoading ? (
              <FullPageLoader position="absolute" />
            ) : (
              <>
                {templates &&
                templates?.find((i: any) => i.id === input?.templateId).name === 'Minimal' ? (
                  <MinimalTemplate
                    url={qrCodeUrl}
                    fontHeader={input?.fontHeader}
                    fontBody={input?.fontBody}
                    backgroundOpacity={
                      isNaN(input?.backgroundOpacity) ? 0 : Number(input?.backgroundOpacity)
                    }
                    layout={
                      (layouts &&
                        layouts.length > 0 &&
                        layouts.find((item) => item.id === input?.layoutId)) ||
                      layouts[0]
                    }
                    preview={preview}
                    handleEditSection={handleEditSection}
                    title={input?.title ?? ''}
                    altTitle={input?.altTitle ?? ''}
                    subtitle={input?.subtitle ?? ''}
                    background={input?.background}
                    edit={edit}
                    cards={cards}
                    user={sanitizeUser(user)}
                    backgroundColor={
                      input?.backgroundColor && [4, 7].includes(input?.backgroundColor.length)
                        ? input?.backgroundColor
                        : undefined
                    }
                    bodyFontColor={
                      input?.bodyFontColor && [4, 7].includes(input?.bodyFontColor.length)
                        ? input?.bodyFontColor
                        : undefined
                    }
                    titleFontColor={
                      input?.titleFontColor && [4, 7].includes(input?.titleFontColor.length)
                        ? input?.titleFontColor
                        : undefined
                    }
                    cardColor={
                      input?.cardColor && [4, 7].includes(input?.cardColor.length)
                        ? input?.cardColor
                        : undefined
                    }
                    titlebarColor={
                      input?.titlebarColor && [4, 7].includes(input?.titlebarColor.length)
                        ? input?.titlebarColor
                        : undefined
                    }
                  />
                ) : null}

                {templates &&
                templates?.find((i: any) => i.id === input?.templateId).name === 'Bold' ? (
                  <DarkTemplate
                    url={qrCodeUrl}
                    fontHeader={input?.fontHeader}
                    fontBody={input?.fontBody}
                    backgroundOpacity={
                      isNaN(input?.backgroundOpacity) ? 0 : Number(input?.backgroundOpacity)
                    }
                    layout={
                      (layouts &&
                        layouts.length > 0 &&
                        layouts.find((item) => item.id === input?.layoutId)) ||
                      layouts[0]
                    }
                    preview={preview}
                    handleEditSection={handleEditSection}
                    title={input?.title ?? ''}
                    altTitle={input?.altTitle ?? ''}
                    subtitle={input?.subtitle ?? ''}
                    background={input?.background}
                    edit={edit}
                    cards={cards}
                    user={sanitizeUser(user)}
                    backgroundColor={
                      input?.backgroundColor && [4, 7].includes(input?.backgroundColor.length)
                        ? input?.backgroundColor
                        : undefined
                    }
                    bodyFontColor={
                      input?.bodyFontColor && [4, 7].includes(input?.bodyFontColor.length)
                        ? input?.bodyFontColor
                        : undefined
                    }
                    titleFontColor={
                      input?.titleFontColor && [4, 7].includes(input?.titleFontColor.length)
                        ? input?.titleFontColor
                        : undefined
                    }
                    cardColor={
                      input?.cardColor && [4, 7].includes(input?.cardColor.length)
                        ? input?.cardColor
                        : undefined
                    }
                    titlebarColor={
                      input?.titlebarColor && [4, 7].includes(input?.titlebarColor.length)
                        ? input?.titlebarColor
                        : undefined
                    }
                  />
                ) : null}
                {templates &&
                templates?.find((i: any) => i.id === input?.templateId).name ===
                  'Previously created poster' ? (
                  <SingleTemplate
                    isEditor
                    background={input?.background}
                    user={sanitizeUser(user)}
                    backgroundColor={
                      input?.backgroundColor && [4, 7].includes(input?.backgroundColor.length)
                        ? input?.backgroundColor
                        : ''
                    }
                    bodyFontColor={
                      input?.bodyFontColor && [4, 7].includes(input?.bodyFontColor.length)
                        ? input?.bodyFontColor
                        : ''
                    }
                    titleFontColor={
                      input?.titleFontColor && [4, 7].includes(input?.titleFontColor.length)
                        ? input?.titleFontColor
                        : ''
                    }
                    url={qrCodeUrl}
                  />
                ) : (
                  ''
                )}
              </>
            )}
          </PosterTemplateStyles>
          <PosterSideModalStyles
            cardColor={input?.cardColor}
            bodyFontColor={input?.bodyFontColor}
            show={edit && !preview ? true : false}
          >
            <Navbar>
              <PosterSideModalButtonStyles onClick={onClickCollapse}>
                {edit ? <ArrowRight /> : <ArrowLeft />}
              </PosterSideModalButtonStyles>{' '}
              <p className="text-muted">Edit Card {isNaN(edit) ? edit : Number(edit) + 1}</p>
            </Navbar>
            <article>
              {edit !== null && edit === 'header' ? (
                <section>
                  <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                      disabled={false}
                      onChange={onChangeInput}
                      id="title"
                      value={input?.title ?? ''}
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="ex: Exploring The Universe"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="subtitle">Poster Subtitle</label>
                    <input
                      disabled={false}
                      onChange={onChangeInput}
                      id="subtitle"
                      value={input?.subtitle ?? ''}
                      type="text"
                      name="subtitle"
                      className="form-control"
                      placeholder="ex: What this means for you and me"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="altTitle">Poster Additional Title</label>
                    <input
                      disabled={false}
                      onChange={onChangeInput}
                      id="altTitle"
                      value={input?.altTitle ?? ''}
                      type="text"
                      name="altTitle"
                      className="form-control"
                      placeholder="ex: By John Smith"
                    />
                  </div>
                </section>
              ) : null}

              <section style={{ display: edit !== 'header' ? undefined : 'none' }}>
                {/* <div className="from-group">
                  <label htmlFor={`card-title-${edit}`}>Card Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Card Title"
                    id={`card-title-${edit}`}
                  />
                </div>
                <hr /> */}
                <div
                  className={`btn-group btn-block third-step`}
                  role="group"
                  aria-label="Content Type"
                >
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Rich Text</Tooltip>}>
                    <div
                      className="btn btn-secondary sixth-step"
                      onClick={onClickAddCard(edit, 'text', true)}
                    >
                      <Type />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Quote</Tooltip>}>
                    <div
                      className="btn btn-secondary seventh-step"
                      onClick={onClickAddCard(edit, 'quote', true)}
                    >
                      <MessageCircle />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Image</Tooltip>}>
                    <div
                      className="btn btn-secondary eighth-step"
                      onClick={onClickAddCard(edit, 'image', true)}
                    >
                      <Image />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Video</Tooltip>}>
                    <div
                      className="btn btn-secondary ninth-step"
                      onClick={onClickAddCard(edit, 'video', true)}
                    >
                      <Youtube />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Sound</Tooltip>}>
                    <div
                      className="btn btn-secondary tenth-step"
                      onClick={onClickAddCard(edit, 'sound', true)}
                    >
                      <Volume2 />
                    </div>
                  </OverlayTrigger>
                </div>
                <hr />

                {cards?.[edit] &&
                  cards?.[edit].map((item: any, key: number) => (
                    <>
                      <div key={`card${edit}-${key}-${item?.type}`} className="form-group">
                        <div className="badge badge-secondary">
                          {item?.type === 'text' ? <Type /> : null}
                          {item?.type === 'quote' ? <MessageCircle /> : null}
                          {item?.type === 'image' ? <Image /> : null}
                          {item?.type === 'video' ? <Youtube /> : null}
                          {item?.type === 'sound' ? <Volume2 /> : null}
                          <div className="btn-group" role="group" aria-label="controls">
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              onClick={onClickUp(edit, key)}
                            >
                              <ArrowUp />
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              onClick={onClickDown(edit, key)}
                            >
                              <ArrowDown />
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                if (window.confirm('Are you sure you wish to delete this item?')) {
                                  onClickRemove(edit, key);
                                }
                              }}
                            >
                              <X />
                            </button>
                          </div>
                        </div>
                        {item?.type === 'text' && (
                          <>
                            <CustomToolbar
                              edit={edit}
                              keyValue={key}
                              cards={cards}
                              setCards={setCards}
                              quillColor={quillColor}
                              setQuillColor={setQuillColor}
                            />

                            <ReactQuill
                              ref={reactQuillRef}
                              modules={{
                                toolbar: {
                                  container: `#toolbar-${edit}-${key}`,
                                },
                              }}
                              formats={[
                                'header',
                                'font',
                                'size',
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote',
                                'list',
                                'bullet',
                                'indent',
                                'link',
                                'color',
                              ]}
                              value={item?.value ?? ''}
                              onChange={(content) => {
                                setHasMadeChanges(true);
                                const newCards = { ...cards };
                                newCards[edit][key].value = content;
                                setCards(newCards);
                              }}
                            />
                          </>
                        )}

                        {item?.type === 'quote' ? (
                          <PosterQuote
                            cardColor={input?.cardColor}
                            bodyFontColor={input?.bodyFontColor}
                          >
                            <textarea
                              onChange={onChangeTextareaCard(edit, key)}
                              rows={4}
                              value={item?.value ?? ''}
                              disabled={false}
                              placeholder="Quote"
                              className="form-control"
                            />
                            <input
                              onChange={onChangeInputCard(edit, key, 'alt')}
                              className="form-control"
                              type="text"
                              value={item?.alt ?? ''}
                              placeholder="From"
                            />
                            {item?.alt ? (
                              <input
                                onChange={onChangeInputCard(edit, key, 'meta')}
                                className="form-control"
                                type="text"
                                value={item?.meta ?? ''}
                                placeholder="Reference"
                              />
                            ) : null}
                          </PosterQuote>
                        ) : null}
                        {item?.type === 'image' ? (
                          <PosterImage>
                            <ImageInput
                              name="image"
                              id={`image-file-${key}`}
                              disabled={false}
                              onChange={onChangeFileOrRecord(edit, key)}
                              src={item.value}
                              formLabel="Image"
                              sizeLabel="20MB"
                              recommendedDimension="1920x1080"
                              customPreview={() => (
                                <img className="img-thumbnail" src={item.value} alt={item.alt} />
                              )}
                              customPlaceholder={<Placeholder width="100%" x="137px" />}
                            />
                            <div className="form-group alt">
                              <label htmlFor={`alt-text-${key}`}>Alternative Text</label>
                              <input
                                onChange={onChangeInputCard(edit, key, 'alt')}
                                className="form-control"
                                type="text"
                                value={item.alt ?? ''}
                              />
                            </div>
                          </PosterImage>
                        ) : null}

                        {item?.type === 'video' ? (
                          <PosterVideo>
                            <input
                              onChange={onChangeInputCard(edit, key)}
                              value={item?.value ?? ''}
                              disabled={false}
                              className="form-control"
                              placeholder="ex: https://www.youtube.com/watch?v=a1b2c3"
                            />
                            {item?.value && item?.type === 'sound' && (
                              <iframe
                                title={item?.value}
                                allowFullScreen
                                width="100%"
                                height="260"
                                src={filterVideoUrl(item.value)}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              ></iframe>
                            )}
                          </PosterVideo>
                        ) : null}

                        {item?.type === 'sound' ? (
                          <PosterSound>
                            <p>
                              Here you can add your voice to narrate this section of your poster.
                              Start recording when you're ready. Don't worry, you can change the
                              recording later if you want.
                            </p>
                            <button
                              className="btn btn-primary"
                              onClick={() => setShowRecordModal({ showModal: true, key })}
                            >
                              Record
                            </button>
                            or
                            <div>
                              <label htmlFor={`audio-url-${key}`}>Audio url</label>
                              <input
                                onChange={onChangeInputCard(edit, key)}
                                value={item?.value ?? ''}
                                disabled={false}
                                className="form-control"
                                placeholder="ex: https://www.link.to/url.mp3"
                                id={`audio-url-${key}`}
                              />
                              <label htmlFor={`audio-alt-text-${key}`}>Alternative Text</label>
                              <input
                                onChange={onChangeInputCard(edit, key, 'alt')}
                                className="form-control"
                                type="text"
                                value={item?.alt ?? ''}
                                placeholder="Alt Text"
                                id={`audio-alt-text-${key}`}
                              />
                            </div>
                            {hasValidSound(item?.value) ||
                            item?.value.includes('-blob') ||
                            item?.value.includes('blob') ? (
                              <audio controls key={item.value}>
                                <source src={item.value} />
                              </audio>
                            ) : null}
                          </PosterSound>
                        ) : null}
                        {showRecordModal.showModal && (
                          <ModalContainer>
                            <Modal
                              hasFooter
                              title="Record"
                              handleClose={() => {
                                setShowRecordModal({ showModal: false });
                                setRecordData(null);
                                setRecord(null);
                              }}
                              body={
                                <RecordModalContent>
                                  {!recordData ? (
                                    <>
                                      <div className="record-drawing">
                                        <div
                                          style={{ display: !record ? 'block' : 'none' }}
                                          className="straight-line"
                                        />
                                        <AudioReactRecorder
                                          canvasWidth={466}
                                          canvasHeight={200}
                                          backgroundColor="white"
                                          state={record}
                                          onStop={(data: RecordData) => setRecordData(data)}
                                        />
                                      </div>
                                      <button
                                        className={`btn btn-${
                                          record === RecordState.START ? 'danger' : 'primary'
                                        }`}
                                        onClick={() =>
                                          setRecord(
                                            record === RecordState.START
                                              ? RecordState.STOP
                                              : RecordState.START,
                                          )
                                        }
                                      >
                                        {record === RecordState.START ? (
                                          <>
                                            <BsStopFill />
                                            Stop recording
                                          </>
                                        ) : (
                                          <>
                                            <BsFillPlayFill />
                                            Start recording
                                          </>
                                        )}
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <AudioPlayer src={recordData.url} />
                                      <button
                                        onClick={() => {
                                          setRecordData(null);
                                          setRecord(null);
                                        }}
                                        className="btn btn-primary"
                                      >
                                        <MdReplay />
                                        Record again
                                      </button>
                                    </>
                                  )}
                                </RecordModalContent>
                              }
                              footer={
                                <>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                      setShowRecordModal({ showModal: false });
                                      setRecordData(null);
                                      setRecord(null);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  {recordData && (
                                    <button
                                      className="btn btn-success"
                                      onClick={() =>
                                        uploadRecord(edit, showRecordModal.key as number)
                                      }
                                      disabled={loading}
                                    >
                                      {loading && (
                                        <span className="spinner-border spinner-border-sm mr-2" />
                                      )}
                                      Submit
                                    </button>
                                  )}
                                </>
                              }
                            />
                          </ModalContainer>
                        )}
                        <hr />
                      </div>
                    </>
                  ))}

                {cards?.[edit] && cards?.[edit].length > 0 ? (
                  <div className="btn-group btn-block" role="group" aria-label="Content Type">
                    <OverlayTrigger overlay={<Tooltip>Rich Text</Tooltip>}>
                      <div className="btn btn-secondary" onClick={onClickAddCard(edit, 'text')}>
                        <Type />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Quote</Tooltip>}>
                      <div className="btn btn-secondary s" onClick={onClickAddCard(edit, 'quote')}>
                        <MessageCircle />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Image</Tooltip>}>
                      <div className="btn btn-secondary " onClick={onClickAddCard(edit, 'image')}>
                        <Image />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Video</Tooltip>}>
                      <div className="btn btn-secondary" onClick={onClickAddCard(edit, 'video')}>
                        <Youtube />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Sound</Tooltip>}>
                      <div className="btn btn-secondary" onClick={onClickAddCard(edit, 'sound')}>
                        <Volume2 />
                      </div>
                    </OverlayTrigger>
                  </div>
                ) : null}
              </section>
            </article>
          </PosterSideModalStyles>
        </PosterPageStyles>
        {generalLoader ? <FullPageLoader progress={savingStep} label="Saving poster data" /> : null}
      </PosterEditLayout>
    </>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default PosterPage;
