import DataList from '../../components/DataList';
import Navbar from '../../components/Navbar';
import DashboardLayout from '../../layouts/DashboardLayout';
import { CategoryImportPageStyles, DropzoneStyles } from './styles';
import { useAuth } from '../../providers/Auth';
import { Link } from 'react-router-dom';
import { CONST, getDefaultRoute } from '../../utils/helpers';
import { AxiosProgressEvent } from 'axios';
import { useCallback, useState } from 'react';
import { useFetch } from '../../providers/Fetch';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ArrowLeft, FileText, Upload } from 'react-feather';

const CategoryEditPage = () => {
  const { auth } = useAuth();
  const { fetch } = useFetch();

  const [importedData, setImportedData] = useState<{
    created: number;
    deleted: number;
  }>();
  const [progress, setProgress] = useState<number>(0);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState('');

  const onDrop = useCallback(async (files: File[], rejections: FileRejection[]) => {
    setImportedData(undefined);
    setError('');

    if (rejections.length) {
      if (rejections.length === 1) setError('Invalid file! Only .csv or .xlsx files are allowed');
      else setError('Too many files! You can only import one file at a time');
      return;
    }

    const file = files[0];
    if (!file) return;

    const formAux = new FormData();
    if (file.name) formAux.append('auxFile', file, file.name.replace(/\s+/g, '_'));
    else formAux.append('auxFile', file);
    const auxFile = formAux.get('auxFile') as File;

    const formData = new FormData();
    formData.append('file', auxFile);

    await fetch(
      {
        url: `/conferences/${auth.conferenceId}/categories/import`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = Math.floor(
            (progressEvent.loaded / (progressEvent.total as number)) * 100,
          );
          setProgress(progress);
          if (!showProgress) setShowProgress(true);
        },
      },
      (res: any) => {
        const data = res.data.data as Record<string, { count: number }>;
        setImportedData({
          created: data.createdCategories.count,
          deleted: data.deletedCategories.count,
        });
        setShowProgress(false);
        files.pop();
      },
      (err: any) => {
        const data = err.response.data;
        setShowProgress(false);
        setError(data.errors);
        console.error(err);
        files.pop();
      },
    );
  }, []);

  const disabled = showProgress && progress > 0;

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    multiple: false,
    accept: { 'text/csv': ['.csv'], 'application/vnd.ms-excel': ['.xlsx'] },
    maxFiles: 1,
    disabled,
  });

  function handleReset() {
    setError('');
    setProgress(0);
    setShowProgress(false);
    setImportedData(undefined);
  }

  return (
    <DashboardLayout>
      <CategoryImportPageStyles>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataList
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <Link to={`${CONST.adminRoute}/categories`} key="categories">
                    Categories
                  </Link>,
                  <span key="import">Import</span>,
                ]}
                title={'Categories'}
                subtitle={'Import'}
                actions={[]}
                customContent={
                  <>
                    {importedData ? (
                      <>
                        <div className="alert alert-success text-center" role="alert">
                          <h3>Import Successful</h3>
                          <p className="text-center">
                            Total categories added: {importedData.created}
                          </p>
                        </div>
                        <div className="d-flex justify-content-center" style={{ gap: '1rem' }}>
                          <Link
                            to={`${CONST.adminRoute}/categories`}
                            className="btn btn-primary d-flex justify-content-center align-items-center"
                          >
                            <ArrowLeft size={18} style={{ marginRight: '0.5rem' }} /> Go back to
                            categories
                          </Link>
                          <button
                            onClick={handleReset}
                            className="btn btn-success d-flex justify-content-center align-items-center"
                          >
                            <FileText size={18} style={{ marginRight: '0.5rem' }} /> Import another
                            file
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>
                          Download the{' '}
                          <a href={`${window.REACT_APP_API_URL}/files/sample-categories.CSV`}>
                            example CSV file
                          </a>{' '}
                          or the{' '}
                          <a href={`${window.REACT_APP_API_URL}/files/sample-categories.xlsx`}>
                            example XLSX file
                          </a>{' '}
                          to know all the required/available fields of the import
                        </p>
                        <DropzoneStyles
                          isDraggingOver={isDragActive}
                          disabled={disabled}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />

                          {showProgress ? (
                            <>
                              <span>
                                {progress === 100
                                  ? 'Your file was received and the data is being created. This can take a while depending on the size of the file'
                                  : 'Upload progress'}
                              </span>
                              {progress === 100 ? (
                                <div className="spinner-border text-primary" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                              ) : (
                                <div
                                  className="progress w-100"
                                  role="progressbar"
                                  aria-label="Importing data"
                                  aria-valuenow={progress}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div
                                    className="progress-bar bg-primary"
                                    style={{ width: `${progress}%` }}
                                  >
                                    {progress}%
                                  </div>
                                </div>
                              )}
                            </>
                          ) : isDragActive ? (
                            <p>Drop the file here ...</p>
                          ) : (
                            <p className="d-flex flex-column gap-10 justify-content-center align-items-center">
                              {acceptedFiles.length ? <FileText size={28} /> : <Upload size={28} />}

                              {acceptedFiles.length > 0
                                ? acceptedFiles[0].name
                                : 'Click or drag file to this area to upload'}
                              {acceptedFiles.length === 0 && (
                                <span className="description">
                                  Support for a single file upload. Only .csv or .xlsx files allowed
                                </span>
                              )}
                            </p>
                          )}
                        </DropzoneStyles>

                        {error && (
                          <div className="alert alert-danger text-center" role="alert">
                            {error}
                          </div>
                        )}
                      </>
                    )}
                  </>
                }
                hasPagination={false}
              />
            </div>
          </div>
        </div>
      </CategoryImportPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default CategoryEditPage;
