// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

// import { Auth0Provider } from '@auth0/auth0-react';
import Auth0Provider from './Auth0';
import ConferenceProvider from './Conference';
import AuthProvider from './Auth';

import FetchProvider from './Fetch';
import ModalProvider from './Modal';
import PosterEditorProvider from './PosterEditor';
import NotificationProvider from './Notification';
import RollbarProvider from './Rollbar/Rollbar';

// Types
interface ProvidersType {
  children: ReactNode;
}

// MAIN PROVIDERS
// ------------------------------------------------------------
const Providers = ({ children }: ProvidersType) => {
  return (
    <RollbarProvider>
      <BrowserRouter>
        <Auth0Provider>
          <FetchProvider>
            <NotificationProvider>
              <AuthProvider>
                <ConferenceProvider>
                  <ModalProvider>
                    <PosterEditorProvider>{children}</PosterEditorProvider>
                  </ModalProvider>
                </ConferenceProvider>
              </AuthProvider>
            </NotificationProvider>
          </FetchProvider>
        </Auth0Provider>
      </BrowserRouter>
    </RollbarProvider>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default Providers;
