// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link } from 'react-router-dom';

// Helpers
import { CONST, getDefaultRoute, removeEmptyOrNull } from '../../utils/helpers';

// Providers
import { useFetch } from '../../providers/Fetch';
import { useAuth } from '../../providers/Auth';
import { useNotification } from '../../providers/Notification';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataEdit from '../../components/DataEdit';

// Styles
import { AccountPageStyles } from './styles';
import { ImageInput } from '../../components/ImageInput';
import { AxiosProgressEvent } from 'axios';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const AccountPage = () => {
  // State / Props
  const [input, setInput] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const isMountedRef = React.useRef(false);

  // Providers
  const { fetch } = useFetch();
  const { auth, getMe } = useAuth();
  const { add } = useNotification();

  // Requests
  /**
   *
   */
  const getData = React.useCallback(async () => {
    setIsLoading(true);
    fetch(
      {
        url: `/users/me`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      (response: any) => {
        if (isMountedRef.current) {
          const {
            orcid,
            twitter,
            facebook,
            instagram,
            website,
            biography,
            photo,
            first_name: firstName,
            last_name: lastName,
            email,
            pronoun,
            quantity,
            expires_at: expiresAt,
            school,
            location,
            petPhoto,
            petName,
            institution,
          } = response.data.data;

          setInput({
            orcid,
            twitter,
            facebook,
            instagram,
            website,
            biography,
            photo,
            ['first_name']: firstName,
            ['last_name']: lastName,
            email,
            pronoun,
            quantity: isNaN(quantity) ? 0 : Number(quantity),
            ['expires_at']: expiresAt ? expiresAt.slice(0, 10) : null,
            school,
            location,
            petPhoto,
            petName,
            institution,
          });
          setIsLoading(false);
        }
      },
      () => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      },
    );
  }, [fetch]);

  /**
   *
   */
  const updateEntry = React.useCallback(
    async (data: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/users/me`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(removeEmptyOrNull(data)),
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.SAVED);
            getData();
            getMe();
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData],
  );

  // Functions
  /**
   *
   */
  const onClickUpdate = () => {
    updateEntry(input);
  };

  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
  ) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('files', event.target.files?.[0] as any);
    fetch(
      {
        url: `/uploads`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress,
      },
      (response: any) => {
        event.target.value = '';
        updateEntry({ ...input, [event.target.name]: response?.data?.data?.[0]?.key });
      },
      () => {
        event.target.value = '';
        setIsLoading(false);
      },
    );
  };

  // Hooks
  /**
   *
   */
  React.useEffect(() => {
    getData();
  }, [getData]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <DashboardLayout>
      <AccountPageStyles>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataEdit
                isLoading={isLoading}
                subtitle={input?.email ?? ''}
                actions={[
                  <button
                    onClick={onClickUpdate}
                    disabled={isLoading}
                    className="btn btn-primary"
                    key="save"
                  >
                    Save
                  </button>,
                ]}
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <span key="account">Account</span>,
                ]}
                title="Logged in as:"
              >
                <div className="row">
                  <div className="col col-12 col-md-6">
                    <form>
                      <ImageInput
                        formLabel="Profile Thumbnail"
                        id="photo"
                        name="photo"
                        src={input?.photo}
                        disabled={false}
                        sizeLabel="20MB"
                        recommendedDimension="800x800"
                        sidePreview
                        onChange={onChangeFile}
                      />

                      <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="first_name"
                          value={input?.first_name ?? ''}
                          type="text"
                          name="first_name"
                          className="form-control"
                          placeholder="ex: John"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="last_name"
                          value={input?.last_name ?? ''}
                          type="text"
                          name="last_name"
                          className="form-control"
                          placeholder="ex: Smith"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="pronoun">Pronoun</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="pronoun"
                          value={input?.pronoun ?? ''}
                          type="text"
                          name="pronoun"
                          className="form-control"
                          placeholder="ex: he/him they/them"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          disabled={true}
                          onChange={onChangeInput}
                          id="email"
                          value={input?.email ?? ''}
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="ex: Smith"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="school">Organization / Institution</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="school"
                          value={input?.school ?? ''}
                          type="text"
                          name="school"
                          className="form-control"
                          placeholder="ex: University of America"
                        />
                      </div>
                      <ImageInput
                        formLabel="Institution Thumbnail"
                        id="institution"
                        name="institution"
                        src={input?.institution}
                        disabled={false}
                        sizeLabel="20MB"
                        recommendedDimension="800x800"
                        sidePreview
                        onChange={onChangeFile}
                      />
                      <div className="form-group">
                        <label htmlFor="location">Location</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="location"
                          value={input?.location ?? ''}
                          type="text"
                          name="location"
                          className="form-control"
                          placeholder="ex: New York, New York"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="biography">Biography</label>
                        <textarea
                          onChange={onChangeTextarea}
                          rows={6}
                          name="biography"
                          value={input?.biography ?? ''}
                          disabled={isLoading}
                          id="biography"
                          className="form-control"
                          placeholder="ex: A genius from a young age..."
                        />
                      </div>
                      <hr />
                      <div className="form-group">
                        <label htmlFor="orcid">Orcid</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="orcid"
                          value={input?.orcid ?? ''}
                          type="text"
                          name="orcid"
                          className="form-control"
                          placeholder="ex: https://orcid.org/4567-6768-2345-3455"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="twitter">Twitter</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="twitter"
                          value={input?.twitter ?? ''}
                          type="text"
                          name="twitter"
                          className="form-control"
                          placeholder="ex: https://twitter.com/userhandle"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="facebook">Facebook</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="facebook"
                          value={input?.facebook ?? ''}
                          type="text"
                          name="facebook"
                          className="form-control"
                          placeholder="ex: https://facebook.com/userhandle"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="instagram">Instagram</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="instagram"
                          value={input?.instagram ?? ''}
                          type="text"
                          name="instagram"
                          className="form-control"
                          placeholder="ex: https://instagram.com/userhandle"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="website">Website</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="website"
                          value={input?.website ?? ''}
                          type="text"
                          name="website"
                          className="form-control"
                          placeholder="ex: https://yourwebsite.com"
                        />
                      </div>
                      <hr />
                      <div className="form-group">
                        <label htmlFor="petName">Pet Name</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="petName"
                          value={input?.petName ?? ''}
                          type="text"
                          name="petName"
                          className="form-control"
                          placeholder="ex: Winston"
                        />
                      </div>

                      <ImageInput
                        formLabel="Pet Photo (optional)"
                        id="petPhoto"
                        name="petPhoto"
                        onChange={onChangeFile}
                        sidePreview
                        src={input?.petPhoto}
                        disabled={false}
                        sizeLabel="20MB"
                        recommendedDimension="800x800"
                      />

                      {/* {['speaker'].includes(auth.role) ? (
                        <div className="form-group">
                          <label htmlFor="expires_at">Expiration Date</label>
                          <input
                            disabled={true}
                            onChange={onChangeInput}
                            id="expires_at"
                            value={input?.expires_at ?? ''}
                            type="date"
                            name="expires_at"
                            className="form-control"
                            placeholder="ex: Smith"
                          />
                        </div>
                      ) : null}

                      {['speaker'].includes(auth.role) ? (
                        <div className="form-group">
                          <label htmlFor="quantity">Poster Quantity</label>
                          <input
                            min="1"
                            disabled={true}
                            onChange={onChangeInput}
                            id="quantity"
                            value={input?.quantity ?? '1'}
                            type="number"
                            name="quantity"
                            className="form-control"
                            placeholder="ex: Smith"
                          />
                        </div>
                      ) : null} */}
                    </form>
                  </div>
                </div>
              </DataEdit>
            </div>
          </div>
        </div>
      </AccountPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default AccountPage;
