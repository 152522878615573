import { UserType } from '../typings';

/**
 *
 */
export const CONST = {
  adminRoute: '/admin',
  posterRoute: '/posters',
  REQUESTS: {
    SUCCESS: {
      CREATED: 'Successfully created!',
      SAVED: 'Successfully saved!',
      DELETED: 'Successfully deleted!',
      UPLOADING: 'Successfully uploaded file!',
    },
    ERRORS: {
      GET: 'Error! Could not retrieve some data.',
      CREATED: 'Error! Could not create.',
      SAVED: 'Error! Could not save.',
      DELETED: 'Error! Could not delete.',
      UPLOADING: 'Error! Could not upload image.',
    },
  },
};

/**
 *
 * @param obj
 */
export const removeEmptyOrNull = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] && typeof obj[k] === 'object' && removeEmptyOrNull(obj[k])) ||
      (!obj[k] && obj[k] !== undefined && delete obj[k]),
  );
  return obj;
};

/**
 *
 * @param role
 */
export const getDefaultRoute = (role: string) => {
  if (role === 'admin') return `${CONST.adminRoute}/conferences`;
  if (role === 'conference') return `${CONST.adminRoute}/users`;
  if (role === 'speaker') return `${CONST.adminRoute}/posters`;
  return `${CONST.adminRoute}`;
};

const month = (monthNumber: number) => {
  switch (monthNumber) {
    case 0:
      return 'JAN';
    case 1:
      return 'FEB';
    case 2:
      return 'MAR';
    case 3:
      return 'APR';
    case 4:
      return 'MAY';
    case 5:
      return 'JUN';
    case 6:
      return 'JUL';
    case 7:
      return 'AUG';
    case 8:
      return 'SEP';
    case 9:
      return 'OCT';
    case 10:
      return 'NOV';
    case 11:
      return 'DEC';
  }
};

/**
 *
 * @param date
 */
export const formatDate = (date: string) => {
  if (!date || date.length === 0) return '';

  const d = new Date(date);
  return `${d.getFullYear()}-${month(d.getMonth())}-${d.getDate()}`;
};

/**
 *
 * @param url
 */
export const hasValidImage = (url: string) => {
  const re = new RegExp(
    /(?:([^:/?#]+):)?(?:([^/?#]*))?([^?#]*\.(?:jpg|gif|png|jpeg))(?:\?([^#]*))?(?:#(.*))?/,
  );
  return re.test(url);
};

/**
 *
 * @param url
 */
export const hasValidSound = (url: string) => {
  const re = new RegExp(
    /(?:([^:/?#]+):)?(?:([^/?#]*))?([^?#]*\.(?:mp3|wav))(?:\?([^#]*))?(?:#(.*))?/,
  );
  return re.test(url);
};

/**
 *
 * @param url
 */
export const filterVideoUrl = (url: string) => {
  // Validate YouTube Link
  const isYouTube = new RegExp(
    /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/,
  );
  if (isYouTube.test(url)) {
    const youTubeEmbed = new RegExp(
      /(?:https?:\/\/)?(?:www\.)?youtube(?:\.com)?\/?.*(?:embed)(?:.*v=|v\/|\/)([\w\-_]+)&?/,
    );
    const youTubeWatch = new RegExp(
      /(?:https?:\/\/)?(?:www\.)?youtube(?:\.com)?\/?.*(?:watch)?(?:.*v=|v\/)([\w\-_]+)&?/,
    );
    const youTubeShare = new RegExp(/(?:https?:\/\/)?(?:www\.)?youtu\.be?.*(?:.\/)([\w\-_]+)&?/);
    if (youTubeEmbed.test(url)) return url;
    if (youTubeWatch.test(url)) {
      const videoId = new URLSearchParams(`?${url.split('?')[1]}`).get('v');
      return `https://www.youtube.com/embed/${videoId}`;
    }
    if (youTubeShare.test(url)) {
      const videoId = url.replace('https://youtu.be/', '');
      return `https://www.youtube.com/embed/${videoId}`;
    }
  }

  // Validate Vimeo Link
  const isVimeo = new RegExp(
    /(?:https?:\/\/)?(?:www\.|)(?:vimeo|player.vimeo)\.com\/(?:video\/|)([\w\-_]+)/,
  );
  if (isVimeo.test(url)) {
    const vimeoPlayer = new RegExp(
      /(?:https?:\/\/)?(?:www\.|)(?:player.vimeo)\.com\/(?:video\/|)([\w\-_]+)/,
    );
    const vimeoLink = new RegExp(/(?:https?:\/\/)?(?:www\.|)(?:vimeo)\.com\/(?!video)([\w\-_]+)/);
    if (vimeoPlayer.test(url)) return url;
    if (vimeoLink.test(url)) {
      const videoId = url.startsWith('https://vimeo.com/')
        ? url.replace('https://vimeo.com/', '')
        : url.replace('https://www.vimeo.com/', '');
      return `https://player.vimeo.com/video/${videoId}`;
    }
  }

  return `${window.location.origin}/unsupported?type=Video`;
};

// Convert HEX to RGB
export function convertToRGB(hexColor = '#fff') {
  hexColor.replace('#', '');

  if (hexColor.length === 3) {
    const colorArray = hexColor.split('');
    return colorArray.map((color) => parseInt(`${color}${color}`, 16));
  }

  if (hexColor.length === 6) {
    const colorArray = [
      hexColor.substring(0, 1),
      hexColor.substring(2, 3),
      hexColor.substring(4, 5),
    ];
    return colorArray.map((color) => parseInt(color, 16));
  }

  return [255, 255, 255];
}

/* eslint-disable camelcase */
export function sanitizeUser(
  value: UserType & { first_name?: string; last_name?: string },
): UserType {
  const user = { ...value, firstName: value.first_name, lastName: value.last_name };
  delete user.first_name;
  delete user.last_name;
  return user;
}
