// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// Styled Components
import { NavbarStyles } from '../../components/Navbar/styles';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const DashboardLayoutSidebarStyles = styled.div`
  width: 100%;

  @media (min-width: 992px) {
    position: fixed;
    width: 260px;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;

export const DashboardLayoutMainStyles = styled.div`
  ${NavbarStyles} {
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    margin-left: 260px;
  }
`;

export const DashboardLayoutStyles = styled.div``;
