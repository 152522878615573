import React, { PropsWithChildren, ReactNode } from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Configuration } from 'rollbar';

const Fallback: React.FC<{ error: string }> = ({ error }) => (
  <div className="container-fluid">
    <div className="row">
      <div className="col">
        {error ? (
          <>
            <h3>The following error has happened:</h3>
            <p>
              <strong>{error.toString()}</strong>
            </p>
            <p>Our team has already been notified</p>
          </>
        ) : (
          <h3>Something went wrong. Our team has already been notified</h3>
        )}
      </div>
    </div>
  </div>
);

const RollbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const token = window.REACT_APP_ROLLBAR_CLIENT_TOKEN || '';
  const config = {
    environment: `better-poster.${process.env.NODE_ENV}`,
    accessToken: token || '',
    retryInterval: 1000,
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    onSendCallback: (_, args) => {
      if (token) {
        console.log(`Sending to Rollbar`, args);
      } else {
        console.log(`Error should be sent to Rollbar`, args);
      }
    },
  } as Configuration;

  return (
    <Provider config={config}>
      <ErrorBoundary fallbackUI={Fallback as unknown as ReactNode}>{children}</ErrorBoundary>
    </Provider>
  );
};

export default RollbarProvider;
