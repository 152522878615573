// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const DataListActionsStyles = styled.div`
  float: right;

  display: flex;

  span {
    display: block;
  }

  button,
  .btn {
    margin-left: 10px;
  }

  .dropdown {
    position: relative;

    &:hover {
      .dropdown-menu {
        display: block;
      }
    }

    input {
      padding-left: 35px;
    }

    .form-control {
      &:focus ~ .dropdown-menu {
        display: block;
      }
    }
    .dropdown-menu {
      span {
        cursor: pointer;
        padding: 0.25rem 1.5rem;
      }
    }

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto 0;
      width: 16px;
      height: 16px;
      stroke-width: 2px;
      color: #becad2;
      polygon {
      }
    }
  }
`;

export const DataListPaginationStyles = styled.nav`
  .page-link {
    border-radius: 0.25rem;
  }

  span {
    font-size: 12px;
    line-height: 38px;
  }
`;

export const DataListStyles = styled.div`
  header {
    h3 {
      line-height: 38px;
    }
    margin-bottom: 30px;
  }
`;
