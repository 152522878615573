import DOMPurify from 'dompurify';
import FontPicker from 'font-picker-react';
import React from 'react';
import { filterVideoUrl } from '../../utils/helpers';
import { LayoutProps } from '../PosterLayout';
import { QuoteItemWrapper } from './styles';

interface ItemProps {
  value: string;
  alt?: string;
  meta?: string;
  fontFamily?: string;
}

interface CardItemType extends ItemProps {
  type: 'text' | 'image' | 'quote' | 'video' | 'sound';
}

export type CardItemsType = { [key: string]: CardItemType[] };

const ImageItem = ({ value, alt }: { value: string; alt?: string }) => {
  return (
    <figure>
      <img src={value} alt={alt} />
      {alt && <figcaption>{alt}</figcaption>}
    </figure>
  );
};

const TextItem = ({ value }: { value: string }) => (
  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}></div>
);

const VideoItem = ({ value }: { value: string }) => {
  return (
    <iframe
      title={value}
      allowFullScreen
      width="100%"
      height="260"
      src={filterVideoUrl(value)}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>
  );
};

const SoundItem = ({ value, alt }: { value: string; alt?: string }) => {
  return (
    <>
      <audio controls>
        <source src={value} />
      </audio>
      {alt && <small>{alt}</small>}
    </>
  );
};

const QuoteItem = ({ value, alt, meta }: { value: string; alt?: string; meta?: string }) => {
  return (
    <QuoteItemWrapper>
      <svg
        width="25"
        height="19"
        viewBox="0 0 25 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.72 15.728C17.7734 14.4054 19.8 12.4854 19.8 9.96802C19.8 9.32802 19.5654 8.83735 19.096 8.49602C18.6267 8.15468 18.072 7.83468 17.432 7.53602C16.8347 7.23735 16.3014 6.83202 15.832 6.32002C15.3627 5.80802 15.128 5.06135 15.128 4.08002C15.128 2.84268 15.512 1.90402 16.28 1.26402C17.0907 0.624016 18.0934 0.304016 19.288 0.304016C20.6107 0.304016 21.7627 0.794683 22.744 1.77602C23.7254 2.71468 24.216 4.08002 24.216 5.87202C24.216 8.60268 23.4054 11.0987 21.784 13.36C20.2054 15.6214 17.5174 17.3067 13.72 18.416V15.728ZM0.920044 15.728C4.97338 14.4054 7.00004 12.4854 7.00004 9.96802C7.00004 9.32802 6.76538 8.83735 6.29604 8.49602C5.82671 8.15468 5.27204 7.83468 4.63204 7.53602C4.03471 7.23735 3.50138 6.83202 3.03204 6.32002C2.56271 5.80802 2.32804 5.06135 2.32804 4.08002C2.32804 2.84268 2.71204 1.90402 3.48004 1.26402C4.29071 0.624016 5.29338 0.304016 6.48804 0.304016C7.81071 0.304016 8.96271 0.794683 9.94404 1.77602C10.9254 2.71468 11.416 4.08002 11.416 5.87202C11.416 8.60268 10.6054 11.0987 8.98404 13.36C7.40538 15.6214 4.71738 17.3067 0.920044 18.416V15.728Z"
          fill="#484848"
        />
      </svg>
      <p>{value}</p>
      {alt && (
        <cite>
          {alt} {meta && <span>{meta}</span>}
        </cite>
      )}
    </QuoteItemWrapper>
  );
};

export const CardItems = ({
  data,
  cardIndex,
}: {
  data: CardItemType[];
  cardIndex: string | number;
}) => {
  const ITEMS = {
    image: (props: ItemProps) => <ImageItem {...props} />,
    text: (props: ItemProps) => <TextItem {...props} />,
    video: (props: ItemProps) => <VideoItem {...props} />,
    sound: (props: ItemProps) => <SoundItem key={props.value} {...props} />,
    quote: (props: ItemProps) => <QuoteItem {...props} />,
  };
  return (
    <>
      {data.map(({ type, ...item }, key) => (
        <section
          className={`${type} apply-font-${cardIndex}${key}${key} apply-font-${cardIndex}${key}`}
          key={`cards${cardIndex}-${key}-${type}`}
        >
          {data && type === 'text' && (
            <div style={{ display: 'none' }}>
              <FontPicker
                pickerId={`${cardIndex}${key}${key}`}
                apiKey={window.REACT_APP_GOOGLE_FONTS_KEY}
                activeFontFamily={data[key].fontFamily || undefined}
              />
            </div>
          )}
          {ITEMS[type]({ ...item }) || ''}
          {/* FontPicker must be called because we need to get the correct font family on first render */}
        </section>
      ))}
    </>
  );
};

interface CardProps extends LayoutProps {
  index: number;
  half?: boolean;
  halfStacked?: boolean;
}

const PosterCard = ({
  onClickCard,
  cards,
  preview,
  edit,
  onClickEdit,
  index,
  style,
  half,
  halfStacked,
}: CardProps) => {
  const handleClick = () => {
    if (preview) {
      onClickCard(`${index}`)();
    } else {
      onClickEdit(`${index}`)();
    }
  };

  return (
    <div
      onClick={handleClick}
      style={style}
      className={`columnCard ${half && 'half'} ${
        halfStacked && 'half-stacked'
      } apply-font-headingTypography apply-font-headingTypography2 apply-font-bodyTypography apply-font-bodyTypography2 ${
        !preview && edit === `${index}` ? 'editing' : ''
      }`}
    >
      {cards?.[index] && cards?.[index].length > 0 ? (
        <CardItems data={cards[index]} cardIndex={index} />
      ) : null}
    </div>
  );
};

export default PosterCard;
