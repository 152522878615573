import React from 'react';
import { Wrapper } from './styles';

type AudioPlayerProps = {
  src: string;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  return (
    <Wrapper>
      <audio ref={audioRef} src={src} itemType="audio/wav" controls />
    </Wrapper>
  );
};

export default AudioPlayer;
