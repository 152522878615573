// IMPORTS
// ------------------------------------------------------------
import styled, { keyframes } from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalDialogStyles = styled.div``;

export const ModalContainerStyles = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  animation-direction: forward;
  animation-duration: 500ms;
  animation-name: ${fadeIn};
`;
