// IMPORTS
// ------------------------------------------------------------
import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/Auth';
import { CONST } from '../utils/helpers';

// Types
type PrivateRouteTypes = {
  paywallRedirectTo?: string;
  accessRedirectTo?: string;
  isHome?: boolean;
};

// PRIVATE ROUTE COMPONENT
// ------------------------------------------------------------
const PrivateRoute: React.FC<PropsWithChildren<PrivateRouteTypes>> = ({
  paywallRedirectTo = `${CONST.adminRoute}/paywall`,
  accessRedirectTo = `${CONST.adminRoute}/forbidden`,
  isHome,
  children,
}) => {
  // State / Props
  const { auth } = useAuth();
  const { pathname } = useLocation();

  /**
   * Not Subdomain Access
   */
  if (
    !auth?.conferences_roles ||
    (!isHome && !['admin', 'speaker', 'conference'].includes(auth?.role ?? '')) ||
    (isHome && !['attendee', 'admin', 'speaker', 'conference'].includes(auth?.role ?? '')) ||
    auth.conferences_roles.length === 0 ||
    auth.domainIndex === -1
  ) {
    return <Navigate to={accessRedirectTo} />;
  }

  // Speaker, has poster quantity, expired_at
  // const currentDate = new Date().getTime();
  // const expirationDate = new Date(auth.expires_at).getTime();

  /**
   * Not Slots
   */
  if (
    auth?.conferences_roles.length === 0 ||
    (auth?.conferences_roles &&
      auth?.conferences_roles[auth.domainIndex]?.Role?.name === 'speaker' &&
      !auth?.conferences_roles?.[auth.domainIndex]?.quantity)
  ) {
    return <Navigate to={accessRedirectTo} />;
  }

  /**
   * Not Paid
   */
  if (
    auth?.conferences_roles &&
    auth?.conferences_roles[auth.domainIndex]?.Role?.name === 'speaker' &&
    pathname !== paywallRedirectTo &&
    (!auth?.conferences_roles?.[auth.domainIndex]?.expires_at ||
      new Date().getTime() >
        new Date(auth?.conferences_roles?.[auth.domainIndex]?.expires_at).getTime())
  ) {
    return <Navigate to={paywallRedirectTo} />;
  }

  return <>{children}</>;
};

// EXPORTS
// ------------------------------------------------------------
export default PrivateRoute;
