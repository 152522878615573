// IMPORTS
// ------------------------------------------------------------
import { createGlobalStyle } from 'styled-components';

// GLOBALSTYLES
// ------------------------------------------------------------
const GlobalStyles = createGlobalStyle`
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  label {
    font-weight: 600;
  }
`;

// EXPORTS
// ------------------------------------------------------------
export default GlobalStyles;
