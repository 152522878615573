// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Styles
import { FullLayoutStyles } from './styles';

// Types
interface FullLayoutTypes {
  children: ReactNode;
}

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const FullLayout = ({ children }: FullLayoutTypes) => {
  return <FullLayoutStyles>{children}</FullLayoutStyles>;
};

// EXPORTS
// ------------------------------------------------------------
export default FullLayout;
