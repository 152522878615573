// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const ModalStyles = styled.div`
  &.modal-footer {
    justify-content: center;
  }
`;
