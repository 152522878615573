import PosterCard, { CardItemsType } from '../PosterCard';

export interface LayoutProps {
  onClickCard: (id: string) => () => void;
  fontHeader?: string;
  fontBody?: string;
  cards: CardItemsType;
  preview: boolean;
  edit?: string;
  onClickEdit: (id: string) => () => void;
  style?: React.CSSProperties;
}

const Layout3 = (props: LayoutProps) => {
  return (
    <>
      <div className="row">
        <div className={`col col-12`} id={`column-0`}>
          <PosterCard {...props} index={0} />
        </div>
      </div>
      <div className="row">
        <div className={`col col-12`} id={`column-1`}>
          <PosterCard {...props} index={1} />
        </div>
      </div>
      <div className="row">
        <div className={`col col-12`} id={`column-2`}>
          <PosterCard {...props} index={2} />
        </div>
      </div>
    </>
  );
};

const Layout11 = (props: LayoutProps) => {
  return (
    <div className="row">
      <div className={`col col-6`} id={`column-0`}>
        <PosterCard {...props} index={0} />
      </div>
      <div className={`col col-6`} id={`column-1`}>
        <PosterCard {...props} index={1} />
      </div>
    </div>
  );
};

const Layout22 = (props: LayoutProps) => {
  return (
    <>
      <div className="row">
        <div className={`col col-6`} id={`column-0`}>
          <PosterCard {...props} index={0} half />
        </div>
        <div className={`col col-6`} id={`column-1`}>
          <PosterCard {...props} index={1} half />
        </div>
      </div>
      <div className="row">
        <div className={`col col-6`} id={`column-2`}>
          <PosterCard {...props} index={2} half />
        </div>
        <div className={`col col-6`} id={`column-3`}>
          <PosterCard {...props} index={3} half />
        </div>
      </div>
    </>
  );
};

const Layout111 = (props: LayoutProps) => {
  return (
    <div className="row">
      <div className={`col col-4`} id={`column-0`}>
        <PosterCard {...props} index={0} />
      </div>
      <div className={`col col-4`} id={`column-1`}>
        <PosterCard {...props} index={1} />
      </div>
      <div className={`col col-4`} id={`column-2`}>
        <PosterCard {...props} index={2} />
      </div>
    </div>
  );
};

const Layout212 = (props: LayoutProps) => {
  return (
    <div className="row">
      <div className="col col-3" id="column-0">
        <PosterCard {...props} halfStacked index={0} />
        <PosterCard {...props} halfStacked index={1} />
      </div>
      <div className="col col-6" id="column-1">
        <PosterCard {...props} index={2} />
      </div>
      <div className="col col-3" id="column-2">
        <PosterCard {...props} halfStacked index={3} />
        <PosterCard {...props} halfStacked index={4} />
      </div>
    </div>
  );
};

const Layout221 = (props: LayoutProps) => {
  return (
    <div className="row">
      <div className="col col-4" id="column-0">
        <PosterCard {...props} halfStacked index={0} />
        <PosterCard {...props} halfStacked index={1} />
      </div>
      <div className="col col-4" id="column-1">
        <PosterCard {...props} halfStacked index={2} />
        <PosterCard {...props} halfStacked index={3} />
      </div>
      <div className="col col-4" id="column-2">
        <PosterCard {...props} index={4} />
      </div>
    </div>
  );
};

const Layout222 = (props: LayoutProps) => {
  return (
    <>
      <div className="row">
        <div className="col col-3" id="column-0">
          <PosterCard {...props} index={0} />
        </div>
        <div className="col col-6" id="column-1">
          <PosterCard {...props} index={1} />
        </div>
        <div className="col col-3" id="column-2">
          <PosterCard {...props} index={2} />
        </div>
      </div>
      <div className="row">
        <div className="col col-3" id="column-3">
          <PosterCard {...props} index={3} />
        </div>
        <div className="col col-6" id="column-4">
          <PosterCard {...props} index={4} />
        </div>
        <div className="col col-3" id="column-5">
          <PosterCard {...props} index={5} />
        </div>
      </div>
    </>
  );
};

const Layout1111 = (props: LayoutProps) => {
  return (
    <div className="row">
      <div className={`col col-3`} id={`column-0`}>
        <PosterCard {...props} index={0} />
      </div>
      <div className={`col col-3`} id={`column-1`}>
        <PosterCard {...props} index={1} />
      </div>
      <div className={`col col-3`} id={`column-2`}>
        <PosterCard {...props} index={2} />
      </div>
      <div className={`col col-3`} id={`column-3`}>
        <PosterCard {...props} index={3} />
      </div>
    </div>
  );
};

const Layout1221 = (props: LayoutProps) => {
  return (
    <div className="row">
      <div className="col col-3" id="column-0">
        <PosterCard {...props} index={0} />
      </div>
      <div className="col col-3" id="column-1">
        <PosterCard {...props} halfStacked index={1} />
        <PosterCard {...props} halfStacked index={2} />
      </div>
      <div className="col col-3" id="column-2">
        <PosterCard {...props} halfStacked index={3} />
        <PosterCard {...props} halfStacked index={4} />
      </div>
      <div className="col col-3" id="column-3">
        <PosterCard {...props} index={5} />
      </div>
    </div>
  );
};

const Layout2112 = (props: LayoutProps) => {
  return (
    <div className="row">
      <div className="col col-3" id="column-0">
        <PosterCard {...props} halfStacked index={0} />
        <PosterCard {...props} halfStacked index={1} />
      </div>
      <div className="col col-3" id="column-1">
        <PosterCard {...props} index={2} />
      </div>
      <div className="col col-3" id="column-2">
        <PosterCard {...props} index={3} />
      </div>
      <div className="col col-3" id="column-3">
        <PosterCard {...props} halfStacked index={4} />
        <PosterCard {...props} halfStacked index={5} />
      </div>
    </div>
  );
};

const PosterLayout = ({ variant, ...props }: LayoutProps & { variant: string }) => {
  const LAYOUTS: { [key: string]: JSX.Element } = {
    'Layout 3': <Layout3 {...props} />,
    'Layout 11': <Layout11 {...props} />,
    'Layout 22': <Layout22 {...props} />,
    'Layout 111': <Layout111 {...props} />,
    'Layout 212': <Layout212 {...props} />,
    'Layout 221': <Layout221 {...props} />,
    'Layout 222': <Layout222 {...props} />,
    'Layout 1111': <Layout1111 {...props} />,
    'Layout 1221': <Layout1221 {...props} />,
    'Layout 2112': <Layout2112 {...props} />,
  };

  return LAYOUTS[variant] || <></>;
};

export default PosterLayout;
