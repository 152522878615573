import styled from 'styled-components';

export const CountdownStyles = styled.div`
  margin: auto;
  width: 100%;
`;

export const CountdownWrapperStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 64px;
  }
`;

export const TimerStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 50px;
  position: relative;

  .timer-item {
    font-size: 64px;
  }

  .timer-item-title {
    font-size: 32px;
  }

  @media (max-width: 600px) {
    &::before {
      content: '';
      position: absolute;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 2px solid black;
      z-index: -1;
      top: -30px;
    }
  }
`;
