// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Styled
import { ModalContainerStyles, ModalDialogStyles } from './styles';

// Types
interface ModalContainerTypes {
  children: ReactNode;
  handleClose?: () => void;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const ModalContainer = ({ children, handleClose }: ModalContainerTypes) => {
  // State / Props
  const isMountedRef = React.useRef<null | boolean>();

  // Hooks
  React.useEffect(() => {
    /**
     *
     * @param event
     */
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && handleClose) {
        handleClose();
      }
    };

    document.addEventListener('keydown', onKeyPress);

    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      document.removeEventListener('keypress', onKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render
  return (
    <ModalContainerStyles className="modal">
      <ModalDialogStyles>{children}</ModalDialogStyles>
    </ModalContainerStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default ModalContainer;
