// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// Helpers
import { CONST, getDefaultRoute, removeEmptyOrNull } from '../../utils/helpers';

// Providers
import { useAuth } from '../../providers/Auth';
import { useModal } from '../../providers/Modal';
import { useFetch } from '../../providers/Fetch';
import { useNotification } from '../../providers/Notification';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataEdit from '../../components/DataEdit';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';

// Styles
import { ConferencePageStyles } from './styles';
import { ImageInput } from '../../components/ImageInput';
import { AxiosProgressEvent } from 'axios';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const ConferencePage = () => {
  // State / Props
  const [input, setInput] = React.useState<any>({});
  const [focused, setFocused] = React.useState<any>({});
  const [invalid, setInvalid] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const { pathname } = useLocation();
  const isMountedRef = React.useRef(false);
  const inputRef = React.useRef<any>(null);
  const { add } = useNotification();
  const [isValidating, setIsValidating] = React.useState(false);
  const [domains, setDomains] = React.useState<any>();
  const [requiredFields, setRequiredFields] = React.useState({ name: false, domain: false });
  const [errors, setErrors] = React.useState<any>({});

  // Providers
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setModal } = useModal();
  const { fetch } = useFetch();
  const { auth } = useAuth();

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (uuid: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${uuid}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            const { photo, name, domain, url, description, start, end } = response.data.data;
            setInput({
              photo,
              name,
              domain,
              url,
              description,
              start,
              end,
            });
            setIsLoading(false);
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        },
      );
    },
    [fetch],
  );

  /**
   *
   */
  const getDomains = React.useCallback(
    async (find: string, domainId?: string) => {
      if (['api', 'admin'].includes(find) && !domainId) {
        setInvalid({ ...invalid, domain: 'Domain already taken.' });
      } else {
        setIsValidating(true);
        fetch(
          {
            url: `/conferences?q=${find}&${domainId ? `excludeId=${domainId}` : ''}`,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (response: any) => {
            if (isMountedRef.current) {
              setDomains(response?.data?.data);
              if (response?.data?.data.length > 0) {
                setInvalid({ ...invalid, domain: 'Domain already taken.' });
              }
              setIsValidating(false);
            }
          },
          () => {
            if (isMountedRef.current) {
              setIsValidating(false);
            }
          },
        );
      }
    },
    [fetch, invalid],
  );

  /**
   *
   */
  const deleteEntry = React.useCallback(
    async (entryId: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${entryId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.DELETED);
            navigate(`${CONST.adminRoute}/conferences`);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.DELETED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, navigate],
  );

  /**
   *
   */
  const createEntry = React.useCallback(
    async (data: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        },
        (response: any) => {
          if (isMountedRef.current) {
            setErrors({});
            add(CONST.REQUESTS.SUCCESS.CREATED);
            navigate(`${CONST.adminRoute}/conferences/${response.data.data.id}/edit`);
          }
        },
        (error: any) => {
          if (isMountedRef.current) {
            setErrors({});
            if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
              error.response?.data?.errors.map((item: any) => {
                setErrors({
                  ...errors,
                  [item?.param]: item?.msg,
                });
                return item;
              });
            }
            add(CONST.REQUESTS.ERRORS.CREATED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, navigate, errors],
  );

  /**
   *
   */
  const updateEntry = React.useCallback(
    async (entryId: string, data: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${entryId}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(removeEmptyOrNull(data)),
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.SAVED);
            getData(entryId);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData],
  );

  // Functions
  /**
   *
   */
  const onClickCancel = () => {
    navigate(`${CONST.adminRoute}/conferences`);
  };

  /**
   *
   */
  const onBlurDomain = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (
        event.target.value.length > 2 &&
        event.target.value.match(/^([a-z0-9]*-)*([a-z0-9])*^.*[^-]$/g) &&
        !event.target.value.match(/[/[!@#$%^&*()_+={}|[\];':",./<>?]/g)
      ) {
        setDomains(null);
        getDomains(event.target.value.toLowerCase(), !pathname.endsWith('new') ? id : undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname],
  );

  /**
   *
   */
  const onClickDelete = () => {
    setModal(
      <ModalContainer>
        <Modal
          title={`Delete '${input.name}'?`}
          hasBody={false}
          handleClose={() => setModal(null)}
          footer={
            <>
              <button
                onClick={() => setModal(null)}
                className="btn flex-fill btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteEntry(id as string);
                  setModal(null);
                }}
                className="btn flex-fill btn-outline-danger"
              >
                Delete
              </button>
            </>
          }
        />
      </ModalContainer>,
    );
  };

  /**
   *
   */
  const onClickSave = () => {
    createEntry(input);
  };

  /**
   *
   */
  const onClickUpdate = () => {
    updateEntry(id as string, input);
  };

  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFocused({ ...focused, ...{ [event.target.name]: true } });
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
    if (event.target.value.length > 0) {
      setRequiredFields({
        ...requiredFields,
        [event?.target.name]: true,
      });
    }
  };

  /**
   *
   * @param event
   */
  const onChangeFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
  ) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('files', event.target.files?.[0] as any);
    fetch(
      {
        url: `/uploads`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress,
      },
      (response: any) => {
        if (inputRef?.current) {
          inputRef.current.value = null;
        }
        updateEntry(id as string, { ...input, photo: response?.data?.data?.[0]?.key });
      },
      () => {
        if (inputRef?.current) {
          inputRef.current.value = null;
        }
        setIsLoading(false);
      },
    );
  };

  /**
   *
   * @param event
   */
  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFocused({ ...focused, ...{ [event.target.name]: true } });
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
  };

  // Hooks
  React.useEffect(() => {
    if (!pathname.endsWith('new')) return;
    setIsLoading(false);
  }, [pathname]);

  /**
   *
   */
  React.useEffect(() => {
    if (pathname.endsWith('new')) return;
    getData(id as string);
  }, [pathname, id, getData]);

  /**
   *
   */
  React.useEffect(() => {
    const newInvalid: any = {};

    if (
      focused?.domain &&
      ((input?.domain ?? '').length < 3 ||
        !(input?.domain ?? '').match(/^([a-z0-9]*-)*([a-z0-9])*^.*[^-]$/g) ||
        (input?.domain ?? '').match(/[/[!@#$%^&*()_+={}|[\];':",./<>?]/g))
    ) {
      newInvalid.domain = 'Invalid value.';
    }

    setInvalid(newInvalid);
  }, [input, focused]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Render
  return (
    <DashboardLayout>
      <ConferencePageStyles>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataEdit
                isLoading={isLoading}
                subtitle={pathname.endsWith('new') ? 'New' : input?.name ?? ''}
                actions={
                  pathname.endsWith('new')
                    ? [
                        <button
                          key="cancel"
                          onClick={onClickCancel}
                          disabled={isLoading}
                          className={`btn btn-outline-secondary`}
                        >
                          Cancel
                        </button>,
                        <button
                          key="save"
                          onClick={onClickSave}
                          disabled={
                            isLoading ||
                            Object.keys(input).length === 0 ||
                            Object.keys(invalid).length > 0 ||
                            !requiredFields.name ||
                            !requiredFields.domain
                          }
                          className="btn btn-primary"
                        >
                          Save
                        </button>,
                      ]
                    : [
                        <button
                          key="cancel"
                          onClick={onClickCancel}
                          disabled={isLoading}
                          className={`btn btn-outline-secondary`}
                        >
                          Cancel
                        </button>,
                        <button
                          key="delete"
                          onClick={onClickDelete}
                          disabled={isLoading}
                          className={`btn btn-outline-danger`}
                        >
                          Delete
                        </button>,
                        <button
                          key="save"
                          onClick={onClickUpdate}
                          disabled={isLoading}
                          className="btn btn-primary"
                        >
                          Save
                        </button>,
                      ]
                }
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <Link to={`${CONST.adminRoute}/conferences`} key="conferences">
                    Conferences
                  </Link>,
                  <span key="new">{pathname.endsWith('new') ? 'New' : 'Edit'}</span>,
                ]}
                title="Conferences"
              >
                <div className="row">
                  <div className="col col-12 col-md-6">
                    <form>
                      {!pathname.endsWith('new') ? (
                        <ImageInput
                          sidePreview
                          formLabel="Conference Thumbnail"
                          id="photo"
                          name="photo"
                          src={input?.photo}
                          disabled={isLoading}
                          sizeLabel="20MB"
                          recommendedDimension="800x800"
                          onChange={onChangeFile}
                        />
                      ) : null}

                      <div className="form-group">
                        <label htmlFor="name">Conference Name</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="name"
                          value={input?.name ?? ''}
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="ex: My Conference"
                        />
                      </div>

                      <div className={`form-group ${invalid?.domain ? 'has-validation' : ''}`}>
                        <label htmlFor="domain">Domain</label>
                        <input
                          onBlur={onBlurDomain}
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="domain"
                          value={input?.domain ?? ''}
                          type="text"
                          name="domain"
                          className={`form-control ${invalid?.domain ? 'is-invalid' : ''}`}
                          placeholder="ex: my-conference-123"
                        />
                        <span className="form-text text-muted">
                          {(input?.domain
                            ? `${input.domain}.${window.location.host}`
                            : ''
                          ).toLowerCase()}
                        </span>
                        {invalid?.domain ? (
                          <div className="invalid-feedback">
                            {domains && domains.length > 0
                              ? `${invalid.domain}`
                              : `${invalid.domain} Minimum 3 characters and ending with letter or number.`}
                          </div>
                        ) : null}
                        {isValidating ? (
                          <div className="spinner-border text-secondary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                      </div>

                      <div className={`form-group ${errors?.url ? 'has-validation' : ''}`}>
                        <label htmlFor="url">Conference Website</label>
                        <input
                          onChange={onChangeInput}
                          value={input?.url ?? ''}
                          disabled={isLoading}
                          id="url"
                          name="url"
                          type="text"
                          className={`form-control ${errors?.url ? 'is-invalid' : ''}`}
                          required
                          placeholder="ex: http://myconference.com"
                        />
                        {errors?.url ? (
                          <div className="invalid-feedback">{errors?.url}.</div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                          onChange={onChangeTextarea}
                          name="description"
                          value={input?.description ?? ''}
                          disabled={isLoading}
                          id="description"
                          className="form-control"
                          placeholder="ex: Describing my conference..."
                        />
                      </div>
                    </form>

                    <div className="form-group">
                      <label htmlFor="start">Start Date</label>
                      <input
                        onChange={onChangeInput}
                        value={
                          input?.start && new Date(input?.start).getTime()
                            ? input.start.slice(0, 10)
                            : ''
                        }
                        disabled={isLoading}
                        id="start"
                        name="start"
                        type="date"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="end">End Date</label>
                      <input
                        onChange={onChangeInput}
                        value={
                          input?.end && new Date(input?.end).getTime() ? input.end.slice(0, 10) : ''
                        }
                        disabled={isLoading}
                        id="end"
                        name="end"
                        type="date"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </DataEdit>
            </div>
          </div>
        </div>
      </ConferencePageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default ConferencePage;
