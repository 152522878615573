// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const BreadcrumbsStyles = styled.nav`
  height: 30px;
  margin-bottom: 20px;
  ol {
    padding: 0 10px;
    li,
    a,
    span {
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }
  }
`;
