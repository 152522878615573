// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const UnsupportedPageStyles = styled.div`
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;

  h1 {
    font-size: 18px;
    display: block;
    text-align: center;
    height: 21px;
    line-height: 21px;
  }
`;
