// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const TableStyles = styled.div`
  table {
    border-bottom: 1px solid #dee2e6;
    thead {
      tr {
        th {
          border-bottom: 1px solid #dee2e6;
          vertical-align: middle;
          color: ${Colors.secondary};
          a {
            color: ${Colors.darkGrey};
            span {
              svg {
                width: 8px;
                height: 8px;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          img {
            max-height: 120px;
            border-radius: 0.25rem;
          }
          .btn {
            margin-left: 10px;

            svg {
              height: 16px;
              width: 16px;
              margin: -4px 0 0 5px;
            }
          }
        }
      }
    }
  }
`;
