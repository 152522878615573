// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Navigate } from 'react-router-dom';

// Helpers
import { CONST } from '../../utils/helpers';

// Providers
import { useAuth } from '../../providers/Auth';

// Styles
import { DashboardPageStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const DashboardPage = () => {
  // State / Props
  const { auth, isLoaded } = useAuth();

  // Render
  /**
   * Not Loaded Yet
   */
  if (!isLoaded) return <div>Loading...</div>;

  /**
   * Loaded + Not Authenticated
   */
  if (isLoaded && !auth) return <div>Redirecting...</div>;

  /**
   * Authenticated As Admin
   */
  if (isLoaded && auth && ['admin'].includes(auth?.role))
    return <Navigate to={`${CONST.adminRoute}/conferences`} />;

  /**
   * Authenticated As Conference Admin
   */
  if (isLoaded && auth && ['conference'].includes(auth?.role))
    return <Navigate to={`${CONST.adminRoute}/users`} />;

  /**
   * Authenticated As Speaker
   */
  if (isLoaded && auth && ['speaker'].includes(auth?.role))
    return <Navigate to={`${CONST.adminRoute}/posters`} />;

  /**
   * Authenticated No Role
   */
  if (isLoaded && auth && (auth?.domainIndex !== null || auth.domainIndex === -1))
    return <Navigate to={`${CONST.adminRoute}/forbidden`} />;

  /**
   * Default View
   */
  return (
    <DashboardPageStyles>
      <h1>Dashboard Page</h1>
    </DashboardPageStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default DashboardPage;
