// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const PosterEditPageStyles = styled.div`
  .form-text {
    font-size: 12px;
  }

  .form-group {
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
`;
