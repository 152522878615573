// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link } from 'react-router-dom';
import { Triangle, Users, Map, Settings, Star } from 'react-feather';

// Providers
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../providers/Auth';

// Helpers
import { CONST, getDefaultRoute } from '../../utils/helpers';

// Components
import Navbar from '../Navbar';
import { IconCarrotDown, IconCarrotUp, Placeholder } from '../Icons';

// Styles
import { SidebarStyles, SidebarContentStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const Sidebar = () => {
  // State / Props
  const [show, setShow] = React.useState(false);
  const { logout } = useAuth0();
  const { auth } = useAuth();
  // Functions
  /**
   *
   */
  const onClickShowAccount = () => {
    setShow(!show);
  };

  /**
   *
   * @param event
   */
  const onClickLogout = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    logout({ logoutParams: { returnTo: `${window.location.origin}/admin` } });
  };

  // Render
  return (
    <SidebarStyles>
      <Navbar>
        <Link to={getDefaultRoute(auth?.role)}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 764 146"
            // style="enable-background:new 0 0 764 146;"
            xmlSpace="preserve"
          >
            <g>
              <g>
                <path
                  d="M191.26,106.18c0,1.72,0.55,3.33,1.67,4.84c1.11,1.5,2.99,2.26,5.64,2.26h13.44c2.65,0,4.53-0.66,5.64-1.99
			c1.11-1.33,1.67-2.85,1.67-4.57v-1.18c0-3.37,1.97-5.05,5.91-5.05c3.94,0,5.91,1.68,5.91,5.05v1.29c0,3.01-0.48,5.48-1.45,7.42
			c-0.97,1.94-2.42,3.62-4.35,5.05c-3.8,2.72-7.6,4.08-11.4,4.08h-18.06c-3.8,0-7.49-1.36-11.07-4.08
			c-1.72-1.43-3.05-3.24-3.98-5.43c-0.93-2.19-1.4-4.71-1.4-7.58V75.65c0-5.81,1.79-10.1,5.38-12.9c1.79-1.29,3.64-2.31,5.54-3.06
			c1.9-0.75,3.74-1.13,5.54-1.13h18.06c1.72,0,3.62,0.34,5.7,1.02c2.08,0.68,4.01,1.6,5.8,2.74c1.72,1.22,3.1,2.9,4.14,5.05
			c1.04,2.15,1.56,4.69,1.56,7.63v14.51c0,1.65-0.48,3.12-1.45,4.41s-2.74,1.94-5.32,1.94h-23.22c-2.58,0-3.94-1.68-4.08-5.05
			c0-3.37,1.36-5.05,4.08-5.05h18.27l-0.11-10c0-1.79-0.56-3.42-1.67-4.89c-1.11-1.47-2.99-2.2-5.64-2.2h-13.44
			c-2.65,0-4.53,0.74-5.64,2.2c-1.11,1.47-1.67,3.1-1.67,4.89V106.18z"
                />
                <path
                  d="M258.88,68.77v39.13c0,1.86,0.39,3.3,1.18,4.3c0.79,1,2.47,1.5,5.05,1.5h2.79c2.79,0,4.12,1.65,3.98,4.95
			c0,3.37-1.33,5.05-3.98,5.05h-3.55c-2.29,0-4.44-0.31-6.45-0.91c-2.01-0.61-3.94-1.49-5.8-2.63v0.11
			c-3.51-2.29-5.27-6.38-5.27-12.25V68.77h-4.19c-2.58,0-3.94-1.68-4.08-5.05c0-3.37,1.36-5.05,4.08-5.05h4.19v-11.5
			c0-3.44,2.04-5.16,6.13-5.16c3.94,0,5.91,1.72,5.91,5.16v11.5h8.92c2.79,0,4.12,1.68,3.98,5.05c0,3.37-1.33,5.05-3.98,5.05H258.88
			z"
                />
                <path
                  d="M295.32,68.77v39.13c0,1.86,0.39,3.3,1.18,4.3c0.79,1,2.47,1.5,5.05,1.5h2.79c2.79,0,4.12,1.65,3.98,4.95
			c0,3.37-1.33,5.05-3.98,5.05h-3.55c-2.29,0-4.44-0.31-6.45-0.91c-2.01-0.61-3.94-1.49-5.8-2.63v0.11
			c-3.51-2.29-5.27-6.38-5.27-12.25V68.77h-4.19c-2.58,0-3.94-1.68-4.08-5.05c0-3.37,1.36-5.05,4.08-5.05h4.19v-11.5
			c0-3.44,2.04-5.16,6.13-5.16c3.94,0,5.91,1.72,5.91,5.16v11.5h8.92c2.79,0,4.12,1.68,3.98,5.05c0,3.37-1.33,5.05-3.98,5.05H295.32
			z"
                />
                <path
                  d="M329.5,106.18c0,1.72,0.55,3.33,1.67,4.84c1.11,1.5,2.99,2.26,5.64,2.26h13.44c2.65,0,4.53-0.66,5.64-1.99
			c1.11-1.33,1.67-2.85,1.67-4.57v-1.18c0-3.37,1.97-5.05,5.91-5.05c3.94,0,5.91,1.68,5.91,5.05v1.29c0,3.01-0.48,5.48-1.45,7.42
			c-0.97,1.94-2.42,3.62-4.35,5.05c-3.8,2.72-7.6,4.08-11.4,4.08h-18.06c-3.8,0-7.49-1.36-11.07-4.08
			c-1.72-1.43-3.05-3.24-3.98-5.43c-0.93-2.19-1.4-4.71-1.4-7.58V75.65c0-5.81,1.79-10.1,5.38-12.9c1.79-1.29,3.64-2.31,5.54-3.06
			c1.9-0.75,3.74-1.13,5.54-1.13h18.06c1.72,0,3.62,0.34,5.7,1.02c2.08,0.68,4.01,1.6,5.8,2.74c1.72,1.22,3.1,2.9,4.14,5.05
			c1.04,2.15,1.56,4.69,1.56,7.63v14.51c0,1.65-0.48,3.12-1.45,4.41s-2.74,1.94-5.32,1.94h-23.22c-2.58,0-3.94-1.68-4.08-5.05
			c0-3.37,1.36-5.05,4.08-5.05h18.27l-0.11-10c0-1.79-0.56-3.42-1.67-4.89c-1.11-1.47-2.99-2.2-5.64-2.2h-13.44
			c-2.65,0-4.53,0.74-5.64,2.2c-1.11,1.47-1.67,3.1-1.67,4.89V106.18z"
                />
                <path
                  d="M394.64,118.86c0,3.44-1.97,5.16-5.91,5.16c-4.08,0-6.13-1.72-6.13-5.16v-44.5c0-5.88,1.76-9.96,5.27-12.25
			c3.51-2.29,7.56-3.44,12.15-3.44h12.25c2.65,0,3.98,1.68,3.98,5.05c0.14,3.37-1.18,5.05-3.98,5.05h-11.39
			c-2.58,0-4.26,0.5-5.05,1.5c-0.79,1-1.18,2.4-1.18,4.19V118.86z"
                />
                <path
                  d="M449.68,91.77c-1.22,0-2.17-0.48-2.85-1.45c-0.68-0.97-1.02-2.27-1.02-3.92c0-3.65,1.5-5.48,4.51-5.48h11.4
			c2.65,0,4.53-0.75,5.64-2.26c1.11-1.5,1.67-3.15,1.67-4.94V56.08c0-1.79-0.56-3.44-1.67-4.95c-1.11-1.5-2.99-2.26-5.64-2.26
			h-22.58v70.2c0,3.44-2.15,5.16-6.45,5.16c-4.16,0-6.24-1.72-6.24-5.16v-74.6c0-2.58,0.65-4.3,1.94-5.16s2.87-1.29,4.73-1.29h31.39
			c1.79,0,3.69,0.39,5.7,1.18c2.01,0.79,3.94,1.79,5.8,3.01h-0.11c1.86,1.36,3.3,3.14,4.3,5.32c1,2.19,1.51,4.71,1.51,7.58v19.46
			c0,5.81-1.94,10.14-5.81,13.01c-1.86,1.36-3.76,2.4-5.7,3.12c-1.93,0.72-3.83,1.08-5.7,1.08H449.68z"
                />
                <path
                  d="M531.49,58.66c1.86,0,3.78,0.39,5.75,1.18c1.97,0.79,3.89,1.79,5.75,3.01h-0.11c1.86,1.36,3.29,3.14,4.3,5.32
			c1,2.19,1.5,4.71,1.5,7.58v30.53c0,5.88-1.93,10.21-5.8,13.01c-3.8,2.72-7.6,4.08-11.4,4.08h-17.2c-1.86,0-3.76-0.32-5.7-0.97
			c-1.93-0.65-3.83-1.68-5.7-3.12c-3.87-2.72-5.8-7.06-5.8-13.01V75.75c0-2.87,0.5-5.39,1.5-7.58c1-2.19,2.44-3.96,4.3-5.32h-0.11
			c1.79-1.22,3.69-2.22,5.7-3.01c2.01-0.79,3.94-1.18,5.8-1.18H531.49z M529.56,113.27c2.65,0,4.53-0.75,5.64-2.26
			c1.11-1.5,1.67-3.12,1.67-4.84V75.86c0-1.79-0.56-3.42-1.67-4.89c-1.11-1.47-2.99-2.2-5.64-2.2h-13.33c-2.65,0-4.53,0.74-5.64,2.2
			c-1.11,1.47-1.67,3.1-1.67,4.89v30.31c0,1.72,0.55,3.33,1.67,4.84c1.11,1.5,2.99,2.26,5.64,2.26H529.56z"
                />
                <path
                  d="M597.17,85.75c1.79,0,3.71,0.41,5.75,1.24c2.04,0.82,3.96,1.85,5.75,3.06h-0.11c1.86,1.36,3.3,3.12,4.3,5.27
			c1,2.15,1.5,4.73,1.5,7.74v3.23c0,5.88-1.93,10.21-5.8,13.01c-1.86,1.43-3.76,2.47-5.7,3.12c-1.94,0.64-3.83,0.97-5.7,0.97H578.9
			c-1.86,0-3.76-0.32-5.7-0.97c-1.94-0.65-3.83-1.68-5.7-3.12c-3.87-2.72-5.81-7.06-5.81-13.01v-1.29c0-3.08,1.97-4.59,5.91-4.52
			c4.01,0,6.02,1.5,6.02,4.52v1.18c0,1.72,0.54,3.33,1.61,4.84c1.08,1.5,2.97,2.26,5.7,2.26h14.19c2.72,0,4.62-0.75,5.7-2.26
			c1.08-1.5,1.61-3.12,1.61-4.84v-3.01c0-0.93-0.13-1.83-0.38-2.69c-0.25-0.86-0.66-1.63-1.24-2.31c-0.57-0.68-1.33-1.24-2.26-1.67
			c-0.93-0.43-2.08-0.65-3.44-0.65h-15.7c-3.65,0-7.49-1.29-11.5-3.87c-3.8-2.44-5.7-6.63-5.7-12.58v-3.66c0-2.87,0.5-5.39,1.5-7.58
			c1-2.19,2.44-3.96,4.3-5.32h-0.11c1.79-1.22,3.69-2.22,5.7-3.01c2.01-0.79,3.94-1.18,5.8-1.18h17.2c1.86,0,3.78,0.39,5.75,1.18
			c1.97,0.79,3.89,1.79,5.75,3.01h-0.11c1.86,1.36,3.29,3.14,4.3,5.32c1,2.19,1.5,4.71,1.5,7.58v0.97c0,1.5-0.5,2.6-1.5,3.28
			c-1,0.68-2.47,1.06-4.41,1.13c-3.94,0.07-5.91-1.4-5.91-4.41v-0.86c0-1.79-0.56-3.42-1.67-4.89c-1.11-1.47-2.99-2.2-5.64-2.2
			h-13.33c-2.65,0-4.53,0.74-5.64,2.2c-1.11,1.47-1.67,3.1-1.67,4.89v2.58c0,1.86,0.55,3.55,1.67,5.05c1.11,1.5,2.99,2.26,5.64,2.26
			H597.17z"
                />
                <path
                  d="M642.43,68.77v39.13c0,1.86,0.39,3.3,1.18,4.3c0.79,1,2.47,1.5,5.05,1.5h2.79c2.79,0,4.12,1.65,3.98,4.95
			c0,3.37-1.33,5.05-3.98,5.05h-3.55c-2.29,0-4.44-0.31-6.45-0.91c-2.01-0.61-3.94-1.49-5.8-2.63v0.11
			c-3.51-2.29-5.27-6.38-5.27-12.25V68.77h-4.19c-2.58,0-3.94-1.68-4.08-5.05c0-3.37,1.36-5.05,4.08-5.05h4.19v-11.5
			c0-3.44,2.04-5.16,6.13-5.16c3.94,0,5.91,1.72,5.91,5.16v11.5h8.92c2.79,0,4.12,1.68,3.98,5.05c0,3.37-1.33,5.05-3.98,5.05H642.43
			z"
                />
                <path
                  d="M676.61,106.18c0,1.72,0.55,3.33,1.67,4.84c1.11,1.5,2.99,2.26,5.64,2.26h13.44c2.65,0,4.53-0.66,5.64-1.99
			c1.11-1.33,1.67-2.85,1.67-4.57v-1.18c0-3.37,1.97-5.05,5.91-5.05c3.94,0,5.91,1.68,5.91,5.05v1.29c0,3.01-0.48,5.48-1.45,7.42
			c-0.97,1.94-2.42,3.62-4.35,5.05c-3.8,2.72-7.6,4.08-11.4,4.08h-18.06c-3.8,0-7.49-1.36-11.07-4.08
			c-1.72-1.43-3.05-3.24-3.98-5.43c-0.93-2.19-1.4-4.71-1.4-7.58V75.65c0-5.81,1.79-10.1,5.38-12.9c1.79-1.29,3.64-2.31,5.54-3.06
			c1.9-0.75,3.74-1.13,5.54-1.13h18.06c1.72,0,3.62,0.34,5.7,1.02c2.08,0.68,4.01,1.6,5.8,2.74c1.72,1.22,3.1,2.9,4.14,5.05
			c1.04,2.15,1.56,4.69,1.56,7.63v14.51c0,1.65-0.48,3.12-1.45,4.41s-2.74,1.94-5.32,1.94H686.5c-2.58,0-3.94-1.68-4.08-5.05
			c0-3.37,1.36-5.05,4.08-5.05h18.27l-0.11-10c0-1.79-0.56-3.42-1.67-4.89c-1.11-1.47-2.99-2.2-5.64-2.2h-13.44
			c-2.65,0-4.53,0.74-5.64,2.2c-1.11,1.47-1.67,3.1-1.67,4.89V106.18z"
                />
                <path
                  d="M741.76,118.86c0,3.44-1.97,5.16-5.91,5.16c-4.08,0-6.13-1.72-6.13-5.16v-44.5c0-5.88,1.76-9.96,5.27-12.25
			c3.51-2.29,7.56-3.44,12.15-3.44h12.25c2.65,0,3.98,1.68,3.98,5.05c0.14,3.37-1.18,5.05-3.98,5.05h-11.39
			c-2.58,0-4.26,0.5-5.05,1.5c-0.79,1-1.18,2.4-1.18,4.19V118.86z"
                />
              </g>
              <g>
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="-28.6569"
                  y1="33.5542"
                  x2="132.1471"
                  y2="177.2728"
                >
                  <stop offset="1.429214e-03" style={{ stopColor: '#67DEFA' }} />
                  <stop offset="0.5166" style={{ stopColor: '#7085F3' }} />
                  <stop offset="1" style={{ stopColor: '#E033F0' }} />
                </linearGradient>
                <polygon
                  points="0,0.88 0,7.07 0,138.93 0,145.12 6.73,145.12 30.96,145.12 30.96,138.93 6.73,138.93 6.73,7.07 
			30.96,7.07 30.96,0.88 6.73,0.88 		"
                />
                <linearGradient
                  id="SVGID_2_"
                  gradientUnits="userSpaceOnUse"
                  x1="29.5722"
                  y1="-31.5972"
                  x2="190.3762"
                  y2="112.1214"
                >
                  <stop offset="1.429214e-03" style={{ stopColor: '#67DEFA' }} />
                  <stop offset="0.5166" style={{ stopColor: '#7085F3' }} />
                  <stop offset="1" style={{ stopColor: '#E033F0' }} />
                </linearGradient>
                <polygon
                  points="155.35,0.88 131.13,0.88 131.13,7.07 155.35,7.07 155.35,138.93 131.13,138.93 131.13,145.12 
			155.35,145.12 162.08,145.12 162.08,138.93 162.08,7.07 162.08,0.88 		"
                />
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="-5.987"
                  y1="8.1893"
                  x2="154.817"
                  y2="151.9079"
                >
                  <stop offset="1.429214e-03" style={{ stopColor: '#67DEFA' }} />
                  <stop offset="0.5166" style={{ stopColor: '#7085F3' }} />
                  <stop offset="1" style={{ stopColor: '#E033F0' }} />
                </linearGradient>
                <path
                  d="M92.83,39.09c-10.63,0-20.14,5.75-26.57,14.83V2.83H43.54v119.55h22.72v-12.99
			c6.43,9.07,15.94,14.83,26.57,14.83c19.35,0,35.04-19.06,35.04-42.57C127.88,58.15,112.19,39.09,92.83,39.09z M105.76,80.68
			c0,17.37-8.81,26.04-19.68,26.04S66.4,98.05,66.4,80.68v0.09c0-18.04,8.81-26.04,19.68-26.04s19.68,8,19.68,26.04V80.68z"
                />
              </g>
            </g>
          </svg>
        </Link>
      </Navbar>
      <SidebarContentStyles show={show}>
        <ul>
          {['admin'].includes(auth?.role ?? '') ? (
            <li>
              <Link to={`${CONST.adminRoute}/conferences`}>
                <span>
                  <Triangle />
                </span>
                Conferences
              </Link>
            </li>
          ) : null}
          {['admin', 'conference'].includes(auth?.role ?? '') ? (
            <li>
              <Link to={`${CONST.adminRoute}/users`}>
                <span>
                  <Users />
                </span>
                Users
              </Link>
            </li>
          ) : null}
          {['speaker', 'conference'].includes(auth?.role ?? '') ? (
            <li>
              <Link to={`${CONST.adminRoute}/posters`}>
                <span>
                  <Map />
                </span>
                Posters
              </Link>
            </li>
          ) : null}
          {['conference'].includes(auth?.role ?? '') ? (
            <li>
              <Link to={`${CONST.adminRoute}/settings`}>
                <span>
                  <Settings />
                </span>
                Conference Settings
              </Link>
            </li>
          ) : null}
          {['conference'].includes(auth?.role ?? '') ? (
            <li>
              <Link to={`${CONST.adminRoute}/categories`}>
                <span>
                  <Star />
                </span>
                Categories
              </Link>
            </li>
          ) : null}
        </ul>

        <ul>
          <li>
            <button onClick={onClickShowAccount} title={auth?.email}>
              <span>
                {auth?.photo ? (
                  <img src={auth?.photo} alt={auth?.email} />
                ) : (
                  <Placeholder width="24px" height="24px" placeholder="" />
                )}
              </span>
              {auth?.email ?? 'Account'}
              {show ? <IconCarrotDown /> : <IconCarrotUp />}
              {['conference', 'admin'].includes(auth?.role ?? '') ? (
                <div
                  className={`badge badge-${
                    auth?.role === 'admin'
                      ? 'danger'
                      : auth?.role === 'conference'
                      ? 'success'
                      : 'secondary'
                  }`}
                >
                  {auth?.role.toLowerCase() === 'conference' ? 'Conference Admin' : auth?.role}
                </div>
              ) : null}
            </button>
            <ul>
              <li>
                <Link to={`${CONST.adminRoute}/account`}>Account</Link>
              </li>
              <li>
                <a href="#logout" onClick={onClickLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </SidebarContentStyles>
    </SidebarStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default Sidebar;
