// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const CategoryEditPageStyles = styled.div`
  .form-text {
    font-size: 12px;
  }

  .form-group {
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }

    .success-message {
      display: block;
      margin-top: 4px;
      color: ${Colors.green};
    }
  }
`;
