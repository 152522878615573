// MAIN COLORS
// ------------------------------------------------------------
const Colors = {
  lightGrey: '#F8F9FA',
  altLightGrey: '#eeeeee',
  lightGreyQuote: '#aaaaaa',
  homeLightGrey: '#d9d9d9',
  borderGrey: '#E0E0E0',
  iconGrey: '#727272',
  subtitleGrey: '#999999',
  greyQuote: '#333333',
  grey: '#373A3C',
  darkGrey: '#262829',
  secondary: '#6c757d',
  blue: '#007BFF',
  green: '#28a745',
  yellow: '#ffc107',
  red: '#dc3545',
  white: '#ffffff',
  dark: '#343a40',
};

// EXPORTS
// ------------------------------------------------------------
export default Colors;
