// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';
import axios from 'axios';
import { useRollbar } from '@rollbar/react';

// Components

// Types
interface FetchProviderTypes {
  children: ReactNode;
}

// MAIN CONTEXT
// ------------------------------------------------------------
/**
 *
 */
const FetchContext = React.createContext<any>(null);

// MAIN PROVIDER
// ------------------------------------------------------------
/**
 *
 * @param param0
 */
const FetchProvider = ({ children }: FetchProviderTypes) => {
  // State / Props
  const [requests, setRequests] = React.useState<any>({});
  const [token, setToken] = React.useState<string | null>(null);
  const rollbar = useRollbar();

  // Functions
  /**
   *
   * @param id
   */
  const updateRequest = (id: string, status: string, message?: string) => {
    setRequests({ ...requests, [id]: { status, message } });
    if (status === 'completed' || status === 'error') {
      // setTimeout(() => {
      //   const newRequests = { ...requests };
      //   delete newRequests[id];
      //   setRequests(newRequests);
      // }, 2000);
    }
  };

  /**
   *
   * @param id
   */
  const addRequest = (id: string | number, status: string, message?: string) => {
    setRequests({
      ...requests,
      [id]: {
        status,
        message,
      },
    });
  };

  /**
   *
   * @param config
   * @param callback
   * @param failback
   */
  const fetch = React.useCallback(
    async (config: any, callback?: (data: any) => void, failback?: (error: any) => void) => {
      // const date = new Date().getTime();
      try {
        // setRequests({
        //   ...requests,
        //   [date]: {
        //     status: 'loading',
        //     message: 'Loading',
        //   },
        // });
        const hasHttp = new RegExp(/^(http|https):\/\//);
        config.url = hasHttp.test(config.url)
          ? config.url
          : `${window.REACT_APP_API_URL}${config.url}`;
        config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
        const response = await axios(config);
        // setRequests({
        //   ...requests,
        //   [date]: {
        //     status: 'complete',
        //     message: 'Completed',
        //   },
        // });
        if (callback) {
          callback(response);
        }
      } catch (error) {
        // setRequests({
        //   ...requests,
        //   [date]: {
        //     status: 'error',
        //     message: 'Incomplete',
        //   },
        // });
        rollbar.error('Error on fetch provider', { error });
        if (failback) {
          failback(error);
        }
      }
      // setTimeout(() => {
      //   const newRequests = { ...requests };
      //   delete newRequests[date];
      //   setRequests(newRequests);
      //   // setRequests({
      //   //   ...re
      //   // })
      // }, 1000);
    },
    [token],
  );

  // Render
  return (
    <FetchContext.Provider
      value={{
        fetch,
        updateRequest,
        addRequest,
        token,
        setToken,
      }}
    >
      <>
        {children}
        {/* <NotificationCenter>
          {requests && Object.keys(requests).length > 0 ? (
            <>
              {Object.keys(requests).map((item: any, key: number) => (
                <Notification key={`notification-${key}`} status={requests[item].status} test={`id-${item}`}>
                  {requests[item].status === 'loading' ? (
                    <>
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>

                      {requests[item]?.message ? <>&nbsp;{requests[item].message}</> : ''}
                    </>
                  ) : null}
                  {requests[item].status === 'completed' ? (
                    <>
                      <CheckCircle />
                      {requests[item]?.message ? <>&nbsp;{requests[item].message}</> : ''}
                    </>
                  ) : null}
                  {requests[item].status === 'error' ? (
                    <>
                      <XCircle />
                      {requests[item]?.message ? <>&nbsp;{requests[item].message}</> : ''}
                    </>
                  ) : null}
                </Notification>
              ))}
            </>
          ) : null}
        </NotificationCenter> */}
      </>
    </FetchContext.Provider>
  );
};

// MAIN CONSUMER
// ------------------------------------------------------------
/**
 *
 */
const useFetch = () => {
  const context = React.useContext(FetchContext);
  if (!context) {
    throw new Error('FetchContext not set!');
  }
  return context;
};

// EXPORTS
// ------------------------------------------------------------
export default FetchProvider;
export { FetchContext, useFetch };
