// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

// Helpers
import { useFetch } from '../Fetch';

// Types
interface ConferenceProviderTypes {
  children: ReactNode;
}

// MAIN CONTEXT
// ------------------------------------------------------------
/**
 *
 */
const ConferenceContext = React.createContext<any>(null);

// MAIN PROVIDER
// ------------------------------------------------------------
/**
 *
 * @param param0
 */
const ConferenceProvider = ({ children }: ConferenceProviderTypes) => {
  // State / Props
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = React.useState(false);
  const [isLoadingKeywords, setIsLoadingKeywords] = React.useState(false);
  const [posters, setPosters] = React.useState<any>();
  const [categories, setCategories] = React.useState<any>();
  const [keywords, setKeywords] = React.useState<any>();
  const [poster, setPoster] = React.useState<any>();
  const isMountedRef = React.useRef(false);
  const navigate = useNavigate();
  const { fetch } = useFetch();

  interface getPostersTypes {
    category?: string;
    keyword?: string;
    query?: string | undefined;
    skip?: number;
    take?: number;
    sort?: string;
    order?: string;
  }

  // Requests
  /**
   *
   */
  const getPosters = React.useCallback(
    // async (query?: string, skip?: number, take?: number, sort?: string, order?: string) => {
    async ({ query, category, keyword }: getPostersTypes) => {
      setIsLoaded(false);
      fetch(
        {
          url: `/conferences/public/${
            window.REACT_APP_DEBUG
              ? window.REACT_APP_DEBUG_DOMAIN
              : window.location.host.split('.')[0]
          }/posters?${query ? `q=${query}&` : ''}${category ? `&category=${category}` : ''}${
            keyword ? `&keyword=${keyword}` : ''
          }`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            setPosters(response.data.data);
            fetch(
              {
                url: `/conferences/public/${
                  window.REACT_APP_DEBUG
                    ? window.REACT_APP_DEBUG_DOMAIN
                    : window.location.host.split('.')[0]
                }/categories?take=100`,
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
              (resp: any) => {
                setCategories(resp.data.data);
                setIsLoaded(true);
              },
              () => {
                setIsLoaded(true);
              },
            );
            // setIsLoaded(true);
          }
        },
        () => {
          if (isMountedRef.current) {
            navigate('/notfound');
            setIsLoaded(true);
          }
        },
      );
    },
    [fetch, navigate],
  );

  /**
   *
   */
  const getCategories = React.useCallback(
    async (query?: string) => {
      setIsLoadingCategories(true);
      fetch(
        {
          url: `/conferences/public/${
            window.REACT_APP_DEBUG
              ? window.REACT_APP_DEBUG_DOMAIN
              : window.location.host.split('.')[0]
          }/categories?${query ? `q=${query}` : ''}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            setCategories(response.data.data);
            setIsLoadingCategories(false);
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoadingCategories(false);
            navigate('/notfound');
          }
        },
      );
    },
    [fetch, navigate],
  );

  /**
   *
   */
  const getKeywords = React.useCallback(
    async (query?: string) => {
      setIsLoadingKeywords(true);
      fetch(
        {
          url: `/conferences/public/${
            window.REACT_APP_DEBUG
              ? window.REACT_APP_DEBUG_DOMAIN
              : window.location.host.split('.')[0]
          }/keywords?${query ? `q=${query}` : ''}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            setKeywords(response.data.data);
            setIsLoadingKeywords(false);
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoadingKeywords(false);
            navigate('/notfound');
          }
        },
      );
    },
    [fetch, navigate],
  );

  /**
   *
   */
  const getPoster = React.useCallback(
    async (id: string, override = false, mode: string | null) => {
      setIsLoaded(false);
      const isDraft = mode === 'draft';
      fetch(
        {
          url: `/conferences/public/${
            window.REACT_APP_DEBUG
              ? window.REACT_APP_DEBUG_DOMAIN
              : window.location.host.split('.')[0]
          }/posters/${id}${override ? `?override=1` : ''}${isDraft ? '?mode=draft' : ''}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            const newCards = {};
            if (response?.data?.data?.poster?.PosterContent?.length > 0) {
              response?.data?.data?.poster?.PosterContent?.map((item: any) => {
                if (!(newCards as any)?.[`${item.card}`]) {
                  (newCards as any)[item.card] = [];
                }

                (newCards as any)[item.card].push({
                  type: item?.key ?? '',
                  value: item?.value ?? '',
                  alt: item?.alt ?? '',
                  meta: item?.meta ?? '',
                  ...(item?.key === 'text' && { fontFamily: item?.fontFamily ?? 'Open Sans' }),
                });
                return item;
              });
            }
            setPoster({ ...response.data.data, cards: newCards });
            setIsLoaded(true);
          }
        },
        (error: any) => {
          console.log('error', error);
          if (isMountedRef.current) {
            setIsLoaded(true);
            navigate('/notfound');
          }
        },
      );
    },
    [fetch, navigate],
  );

  // Hooks
  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Render
  return (
    <ConferenceContext.Provider
      value={{
        isLoadingCategories,
        categories,
        getCategories,
        isLoadingKeywords,
        keywords,
        getKeywords,
        posters,
        poster,
        getPosters,
        getPoster,
        isLoaded,
      }}
    >
      {children}
    </ConferenceContext.Provider>
  );
};

// MAIN CONSUMER
// ------------------------------------------------------------
/**
 *
 */
const useConference = () => {
  const context = React.useContext(ConferenceContext);
  if (!context) {
    throw new Error('ConferenceContext not set!');
  }
  return context;
};

// EXPORTS
// ------------------------------------------------------------
export default ConferenceProvider;
export { ConferenceContext, useConference };
