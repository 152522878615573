import styled, { css } from 'styled-components';

export const CategoryImportPageStyles = styled.div``;

export const DropzoneStyles = styled.div<{ isDraggingOver?: boolean; disabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 150px;
  margin: 1rem 0;
  border: 1px dashed #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  transition: border-color ease-in 0.2s;

  p {
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
    transition: color ease-in 0.2s;
    span {
      font-weight: 400;
      font-size: 1rem;
    }
  }

  &:hover {
    border-color: var(--blue);
    p {
      color: var(--blue);
    }
  }

  ${({ isDraggingOver }) =>
    isDraggingOver &&
    css`
      border-color: var(--blue);
      p {
        color: var(--blue);
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
      border-color: #ccc;
      p {
        color: black;
      }

      &:hover {
        border-color: #ccc;
        p {
          color: black;
        }
      }
    `}
`;
