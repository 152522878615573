// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// Types
interface NavbarSearchStylesTypes {
  hasIcon?: boolean;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const NavbarSearchStyles = styled.nav<NavbarSearchStylesTypes>`
  width: 100%;
  position: relative;

  input[type='search'] {
    background: none;
    height: 54px;
    width: 100%;
    border: none;
    padding: ${({ hasIcon }) => (hasIcon ? '0 20px 0 46px' : '0 20px')};
    color: ${Colors.white};

    &:focus {
      border: none;
      outline: 0;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      right: 20px;

      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }

  svg {
    width: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    margin: auto 0;
    color: #545454;
  }
`;
