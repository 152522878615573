// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Hooks
import useDebounce from '../../hooks/useDebounce';

// Providers
import { useModal } from '../../providers/Modal';
import { useFetch } from '../../providers/Fetch';
import { useAuth } from '../../providers/Auth';

// Helpers
import { CONST, getDefaultRoute } from '../../utils/helpers';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataList from '../../components/DataList';
import NavbarSearch from '../../components/NavbarSearch';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';

// Style
import { CategoriesListPageStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const CategoriesListPage = () => {
  // State / Props
  const { setModal } = useModal();
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort, setSort] = React.useState('');
  const [order, setOrder] = React.useState('');
  const [data, setData] = React.useState<any>(null);
  const [pagination, setPagination] = React.useState<any>(null);
  const isMountedRef = React.useRef(false);
  const { fetch } = useFetch();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(search, 500);

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (query?: string, skip?: number, take?: number, sort?: string, order?: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/categories?${query ? `q=${query}&` : ''}${
            skip ? `skip=${skip}&` : ''
          }${take ? `take=${take}&` : ''}${sort ? `sort=${sort}&` : ''}${
            order ? `order=${order}&` : ''
          }`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          const { data, pagination } = response.data;
          setData(data);
          setPagination(pagination);
          setSort(pagination.sort);
          setOrder(pagination.order);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    },
    [fetch, auth],
  );

  /**
   *
   */
  const deleteEntry = React.useCallback(
    async (entryId: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/categories/${entryId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        () => {
          if (isMountedRef.current) {
            getData();
          }
        },
        () => {
          setIsLoading(false);
        },
      );
    },
    [fetch, getData, auth],
  );

  // Functions
  /**
   *
   * @param data
   */
  const onClickDelete = (data: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setModal(
      <ModalContainer>
        <Modal
          title={`Delete '${data.name}'?`}
          hasBody={false}
          handleClose={() => setModal(null)}
          footer={
            <>
              <button
                onClick={() => setModal(null)}
                className="btn flex-fill btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteEntry(data.id);
                  setModal(null);
                }}
                className="btn flex-fill btn-outline-danger"
              >
                Delete
              </button>
            </>
          }
        />
      </ModalContainer>,
    );
  };

  /**
   *
   * @param param0
   */
  const handlePrev = () => {
    getData(search, pagination?.skip - pagination?.take, pagination?.take, sort, order);
  };

  /**
   *
   * @param param0
   */
  const handleNext = () => {
    getData(search, pagination?.skip + pagination?.take, pagination?.take, sort, order);
  };

  // Hooks
  /**
   * Search
   */
  React.useEffect(() => {
    getData(debouncedSearchTerm, pagination?.skip, pagination?.take, sort, order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Columns Config
  const columns: any = {
    name: {
      display: 'Name',
      filter: true,
    },
    actions: {
      display: 'Actions',
      class: 'text-right',
      render: (data: any) =>
        !isLoading ? (
          <>
            <button className="btn btn-sm btn-outline-danger" onClick={onClickDelete(data)}>
              Delete
            </button>
            <Link
              className="btn btn-sm btn-outline-secondary"
              to={`${CONST.adminRoute}/categories/${data.id}/edit`}
            >
              Edit
            </Link>
          </>
        ) : null,
    },
  };

  // Render
  return (
    <DashboardLayout>
      <CategoriesListPageStyles>
        <Navbar>
          <NavbarSearch
            handleChange={(input: string) => {
              setSearch(input || '');
            }}
          />
        </Navbar>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataList
                isLoading={isLoading}
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <span key="categories">Categories</span>,
                ]}
                title={'Conference'}
                subtitle={'Categories'}
                actions={[
                  <Link
                    className="btn btn-primary"
                    to={`${CONST.adminRoute}/categories/import`}
                    key="import"
                  >
                    Import
                  </Link>,
                  <Link
                    className="btn btn-success"
                    to={`${CONST.adminRoute}/categories/new`}
                    key="new"
                  >
                    Add New
                  </Link>,
                ]}
                rowClick={(data: any) =>
                  !isLoading ? navigate(`${CONST.adminRoute}/categories/${data.id}/edit`) : {}
                }
                columns={columns}
                sortBy={sort}
                order={order}
                handleSort={({ sortBy, orderBy }: { sortBy: string; orderBy: string }) => {
                  getData(search, undefined, undefined, sortBy, sort !== sortBy ? 'asc' : orderBy);
                }}
                data={data ? data : []}
                hasPagination={true}
                paginationShowing={`${data?.length === 0 ? 0 : pagination?.skip + 1} - ${
                  pagination?.skip + data?.length
                }`}
                paginationTotal={pagination?.count ?? 0}
                nextEnabled={
                  isLoading ? false : pagination?.skip + data?.length < pagination?.count
                }
                prevEnabled={isLoading ? false : pagination?.skip > 0}
                handlePrev={handlePrev}
                handleNext={handleNext}
              />
            </div>
          </div>
        </div>
      </CategoriesListPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default CategoriesListPage;
