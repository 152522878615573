// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ExternalLink, Filter, X } from 'react-feather';
import Joyride from 'react-joyride';

// Hooks
import useDebounce from '../../hooks/useDebounce';

// Helpers
import { CONST, getDefaultRoute } from '../../utils/helpers';
import { postersListSteps } from '../../utils/tutorial';

// Providers
import { useFetch } from '../../providers/Fetch';
import { useAuth } from '../../providers/Auth';
import { useModal } from '../../providers/Modal';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataList from '../../components/DataList';
import NavbarSearch from '../../components/NavbarSearch';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';

// Styles
import { PostersPageStyles } from './styles';
import { Placeholder } from '../../components/Icons';
import { Helmet } from 'react-helmet';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const PostersPage = () => {
  // State / Props
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort, setSort] = React.useState('');
  const [order, setOrder] = React.useState('');
  const [data, setData] = React.useState<any>(null);
  const [pagination, setPagination] = React.useState<any>(null);
  const [categories, setCategories] = React.useState<any>(null);
  const [searchCategories, setSearchCategories] = React.useState<any>(null);
  const [isLoadingCategories, setIsLoadingCategories] = React.useState<any>(false);
  const [category, setCategory] = React.useState<any>(null);
  const [joyrideEnabled, setJoyrideEnabled] = React.useState(false);
  const [onboardingQuestionAlreadyAnswared, setOnboardingQuestionAlreadyAnswared] = React.useState(
    () => {
      const isAnswered = localStorage.getItem('onboardingAnswered');
      if (!isAnswered || isAnswered === 'false') return false;
      return true;
    },
  );
  const isMountedRef = React.useRef(false);
  const navigate = useNavigate();
  const { fetch } = useFetch();
  const { auth } = useAuth();
  const { setModal } = useModal();
  const debouncedSearchTeam = useDebounce(search, 500);
  const debouncedSearchCategories = useDebounce(searchCategories, 500);

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (
      query?: string,
      skip?: number,
      take?: number,
      sort?: string,
      order?: string,
      category?: string,
    ) => {
      setIsLoading(true);
      fetch(
        {
          url: `/${
            auth.role === 'admin' ? 'posters' : `conferences/${auth.conferenceId}/posters`
          }?${query ? `q=${query}&` : ''}${skip ? `skip=${skip}&` : ''}${
            take ? `take=${take}&` : ''
          }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : ''}${
            category ? `category=${category}&` : ''
          }`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          const { data, pagination } = response.data;
          setData(data);
          setPagination(pagination);
          setSort(pagination.sort);
          setOrder(pagination.order);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    },
    [fetch, auth],
  );

  /**
   *
   */
  const getCategories = React.useCallback(
    async (query?: string) => {
      setIsLoadingCategories(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/categories?${query ? `q=${query}&` : ''}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            setCategories(response.data.data);
            setIsLoadingCategories(false);
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoadingCategories(false);
          }
        },
      );
    },
    [fetch, auth],
  );

  /**
   *
   * @param param0
   */
  const handlePrev = () => {
    getData(search, pagination?.skip - pagination?.take, pagination?.take, sort, order);
  };

  /**
   *
   * @param param0
   */
  const handleNext = () => {
    getData(search, pagination?.skip + pagination?.take, pagination?.take, sort, order);
  };

  // Hooks
  /**
   * Search
   */
  React.useEffect(() => {
    getCategories(debouncedSearchCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchCategories]);

  /**
   * Search
   */
  React.useEffect(() => {
    getData(debouncedSearchTeam, pagination?.skip, pagination?.take, sort, order, category?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTeam, category]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    const isSpeaker = auth.role === 'speaker';
    const haveAvailablePosters = data?.length > 0;
    if (!onboardingQuestionAlreadyAnswared && isSpeaker && haveAvailablePosters) {
      setModal(
        <ModalContainer>
          <Modal
            title={'Do you want a tutorial on how to use the editor?'}
            hasBody={false}
            showClose={false}
            footer={
              <>
                <button
                  onClick={() => {
                    setOnboardingQuestionAlreadyAnswared(true);
                    localStorage.setItem('onboardingAnswered', 'true');
                    setModal(null);
                  }}
                  className="btn flex-fill btn-outline-danger"
                >
                  No
                </button>
                <button
                  onClick={() => {
                    setOnboardingQuestionAlreadyAnswared(true);
                    localStorage.setItem('onboardingAnswered', 'true');
                    setJoyrideEnabled(true);
                    setModal(null);
                  }}
                  className="btn flex-fill btn-outline-success"
                >
                  Yes
                </button>
              </>
            }
          />
        </ModalContainer>,
      );
    }
  }, [onboardingQuestionAlreadyAnswared, setModal, auth.role, data]);

  // Render
  /**
   *
   */
  const columns: any = {
    thumbnail: {
      display: 'Thumbnail',
      render: (data: any) => {
        if (!data.thumbnail) return <Placeholder />;
        return <img src={data.thumbnail} alt={data.title} />;
      },
      width: 220,
    },
    talk: {
      display: 'Talk Number',
    },
    title: {
      display: 'Title',
      filter: true,
    },
    published: {
      display: 'Published',
      render: (data: any) =>
        data?.published ? (
          <span className="badge bg-success">Published</span>
        ) : (
          <span className="badge bg-secondary">Not Published</span>
        ),
      filter: true,
    },
  };

  if (['conference'].includes(auth?.role ?? '')) {
    columns.user = {
      display: 'User',
      render: (data: any) => (
        <span>
          {data?.User?.first_name} {data?.User?.last_name}
        </span>
      ),
    };

    columns.category = {
      display: 'Category',
      render: (data: any) => (
        <span>
          <small>
            {data?.PosterCategory.length} Categor{data?.PosterCategory.length === 1 ? 'y' : 'ies'}
          </small>
        </span>
      ),
    };
  }

  columns.actions = {
    display: 'Actions',
    class: 'text-right',
    render: (data: any) => (
      <>
        <Link
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.stopPropagation();

            if (joyrideEnabled) {
              localStorage.setItem('joyrideEnabled', 'true');
            }
          }}
          className="btn btn-sm btn-outline-secondary first-step"
          to={`${CONST.adminRoute}/posters/${data.id}/edit`}
        >
          Edit
        </Link>
        {auth.role === 'conference' ? (
          <Link
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              event.stopPropagation();
              event.preventDefault();
              window.open(`${window.location.origin}/posters/${data.id}`, '_blank');
              return;
            }}
            className="btn btn-sm btn-outline-primary"
            to={`#${window.location.origin}/posters/${data.id}`}
          >
            Link
            <ExternalLink />
          </Link>
        ) : null}
      </>
    ),
  };

  /**
   *
   */
  return (
    <DashboardLayout>
      <Joyride
        showProgress
        continuous={true}
        run={joyrideEnabled}
        scrollToFirstStep={true}
        showSkipButton={true}
        steps={postersListSteps}
      />
      <PostersPageStyles>
        <Helmet>
          <title>{`Posters List`}</title>
        </Helmet>
        <Navbar>
          <NavbarSearch
            handleChange={(input: string) => {
              setSearch(input || '');
            }}
          />
        </Navbar>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataList
                isLoading={isLoading}
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <span key="posters">Posters</span>,
                ]}
                title={auth.role === 'conference' ? 'Conference' : 'Posters'}
                subtitle={auth.role === 'conference' ? 'Posters' : undefined}
                actions={
                  ['conference'].includes(auth.role)
                    ? [
                        <Link
                          className="btn btn-primary"
                          to={`${CONST.adminRoute}/posters/import`}
                          key="import"
                          style={{ marginRight: '0.5rem' }}
                        >
                          Import
                        </Link>,
                        !category ? (
                          <div className="dropdown">
                            <input
                              type="search"
                              className="form-control dropdown-toggle"
                              placeholder="Filter Category"
                              value={searchCategories}
                              onChange={(event) => setSearchCategories(event.target.value)}
                            />
                            <Filter />

                            {!isLoadingCategories && categories && categories.length === 0 ? (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span className="dropdown-item">None Found</span>
                              </div>
                            ) : null}

                            {!isLoadingCategories && categories && categories.length > 0 ? (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {categories.map((item: any) => (
                                  <span
                                    key={`${item.id}`}
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCategory(item);
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="btn-group" role="group">
                            <button type="button" className="btn btn-primary">
                              {category?.name}
                            </button>
                            <button
                              onClick={() => setCategory(null)}
                              type="button"
                              className="btn btn-primary"
                            >
                              <X />
                            </button>
                          </div>
                        ),
                      ]
                    : null
                }
                rowClick={(data: any) => {
                  // if (auth.role === 'conference') {
                  //   window.open(`${window.location.origin}/posters/${data.id}`, '_blank');
                  //   return;
                  // }
                  navigate(`${CONST.adminRoute}/posters/${data.id}/edit`);
                }}
                columns={columns}
                sortBy={sort}
                order={order}
                handleSort={({ sortBy, orderBy }: { sortBy: string; orderBy: string }) => {
                  getData(search, undefined, undefined, sortBy, sort !== sortBy ? 'asc' : orderBy);
                }}
                data={data ? data : []}
                empty={
                  ['speaker'].includes(auth.role) && auth.quantity === 0
                    ? 'Please check back later to see if you have been given poster slots.'
                    : undefined
                }
                hasPagination={
                  (['speaker'].includes(auth.role) && auth.quantity > 0) ||
                  ['admin', 'conference'].includes(auth.role)
                }
                paginationShowing={`${data?.length === 0 ? 0 : pagination?.skip + 1} - ${
                  pagination?.skip + data?.length
                }`}
                paginationTotal={pagination?.count ?? 0}
                nextEnabled={pagination?.skip + data?.length < pagination?.count}
                prevEnabled={pagination?.skip > 0}
                handlePrev={handlePrev}
                handleNext={handleNext}
              />
            </div>
          </div>
        </div>
      </PostersPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default PostersPage;
