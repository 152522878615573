// IMPORTS
// ------------------------------------------------------------
import React from 'react';

// Styled
import { NotFoundPageStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const NotFoundPage = () => {
  return (
    <NotFoundPageStyles>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <p>&nbsp;</p>
            <h1>Not Found</h1>
            <p>If you are seeing this, it's because this page might not exist.</p>
          </div>
        </div>
      </div>
    </NotFoundPageStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default NotFoundPage;
