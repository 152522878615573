import styled, { keyframes } from 'styled-components';
import Colors from '../../styles/Colors';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`;

export const SingleTemplateStyles = styled.div<{ isEditor?: boolean }>`
  position: relative;
  min-width: fit-content;

  .buttons {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    z-index: 1;
    align-items: center;

    .home,
    .qr {
      color: ${Colors.greyQuote};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .qr {
      margin-left: auto;
    }

    .institution {
      padding: 0px;
      background-size: cover;
      border-radius: 0px;
      background-color: transparent;
      border: none;
      width: 100px;
      height: 100px;
      background-size: cover;
    }

    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    z-index: 1;

    .profile {
      padding: 0;

      img {
        border-radius: 1rem;
      }
    }

    .home,
    .qr,
    .profile {
      background-color: ${Colors.white}80;
      width: 70px;
      height: 70px;
      border-radius: 1rem;
      border: none;
    }
  }

  .bg-image {
    position: absolute;
    background-color: #007bff22;
    width: 100%;
    height: ${({ isEditor }) => (!isEditor ? '100vh' : 'calc(100vh - 60px)')};
    inset: 0;
    opacity: 0.5;

    span {
      position: absolute;
      width: 21.875%;
      background-color: white;
    }

    .header {
      width: 100%;
      height: 20%;
      background-color: #007bff80;
      top: 0;
    }

    .first,
    .second {
      height: calc(100% - 20% - 5%);
      bottom: 2.5%;
    }

    .first {
      left: 2.5%;
    }

    .second {
      left: calc(21.875% + 5%);
    }

    .third,
    .fourth {
      height: calc((100% - 25% - 2.5%) / 2);
      right: calc(21.875% + 5%);
    }

    .third,
    .fifth {
      top: 22.5%;
    }

    .fourth,
    .sixth {
      bottom: 2.5%;
    }

    .fifth,
    .sixth {
      right: 2.5%;
    }

    .fifth {
      height: calc((100% - 25% - 2.5%) / 3 * 2);
    }

    .sixth {
      height: calc((100% - 25% - 2.5%) / 3);
    }
  }

  button img {
    max-width: 100%;
    height: auto;
  }

  > img {
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: block;
  }

  article {
    backdrop-filter: blur(10px);
    background: rgba(26, 23, 54, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: ${fadeIn} 250ms forwards;

    > button[type='button'] {
      display: block;
      position: fixed;
      top: 20px;
      right: 20px;
      width: 44px;
      height: 44px;
      background: ${Colors.white};
      color: ${Colors.grey};
      border-radius: 44px;
      border: none;
      transition: all 250ms ease-in-out 0s;

      &:active,
      &:focus {
        outline: none;
      }

      &:hover {
        transform: scale(1.05);
      }

      svg {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
    }

    aside {
      background: #2e2a4f;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 540px;
      animation: ${slideIn} 250ms forwards;

      &.profile {
        background: ${Colors.white};
      }

      section {
        > svg {
          background: ${Colors.white};
          width: 296px !important;
          height: 296px !important;
          border-radius: 4px;
          padding: 20px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        header {
          background: #1a1736;
          height: 310px;
          width: 100%;
          position: relative;
          text-align: center;

          svg,
          img {
            width: 120px;
            height: 120px;
            border-radius: 120px !important;
            border: 2px solid ${Colors.white};
            margin: 60px 5px 20px 5px;
            position: static;
          }

          h2 {
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 3px;
            color: ${Colors.white};
          }
          p {
            font-size: 16px;
            line-height: 22px;
            color: #d9d9d9;
            margin-left: auto;
            margin-right: auto;
          }
        }

        main {
          padding: 30px 40px;
          h3 {
            font-size: 16px;
            line-height: 22px;
            color: #484848;
            margin-bottom: 20px;
            color: white;
          }

          p {
            font-size: 16px;
            line-height: 30px;
            color: #edebff;
            font-weight: 300;
            margin-bottom: 20px;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 20px;
              padding-left: 54px;
              position: relative;
              height: 44px;
              line-height: 44px;

              a {
                color: white;
              }

              .social {
                display: block;
                width: 44px;
                height: 44px;
                border-radius: 44px;
                color: ${Colors.white};
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;

                svg {
                  width: 16px;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                }
              }

              .social-twitter {
                background: #1da1f2;
              }

              .social-facebook {
                background: #4267b2;
              }

              .social-instagram {
                background: #833ab4;
              }

              .social-website {
                background: #333333;
              }

              .social-orcid {
                background: #baed66;
              }
            }
          }
        }
      }
    }

    &.profile {
      aside {
        background: #2e2a4f;
      }
    }
  }
`;
