export const IconCarrotUp = () => (
  <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 -2.62268e-07L-3.49691e-07 4L6 4L3 -2.62268e-07Z"
      fill="#373A3C"
    />
  </svg>
);

export const IconCarrotDown = () => (
  <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3 4L6 0L0 0L3 4Z" fill="#373A3C" />
  </svg>
);

export const Placeholder = ({
  x = '30%',
  y = '50%',
  width = 200,
  height = 120,
  placeholder = 'Placeholder',
}: {
  x?: string | undefined;
  y?: string | undefined;
  width?: number | string | undefined;
  height?: number | string | undefined;
  placeholder?: string | undefined;
}) => (
  <svg
    className="bd-placeholder-img rounded"
    width={`${width}`}
    height={`${height}`}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Placeholder"
    preserveAspectRatio="xMidYMid slice"
    focusable="false"
  >
    <title>{placeholder}</title>
    <rect width="100%" height="100%" fill="#6c757d"></rect>
    <text x={x} y={y} fill="#dee2e6" dy=".3em">
      {placeholder}
    </text>
  </svg>
);

export const Qr = ({ stroke, fill }: { stroke?: string; fill?: string }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 2.5H8.5C7.11929 2.5 6 3.61929 6 5V26C6 27.3807 7.11929 28.5 8.5 28.5H21C22.3807 28.5 23.5 27.3807 23.5 26V5C23.5 3.61929 22.3807 2.5 21 2.5Z"
      stroke={stroke || '#666666'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="9" y="9" width="12" height="12" fill={fill || '#EDEDED'} />
    <rect x="9" y="9" width="12" height="12" fill={fill || '#EDEDED'} />
    <path
      d="M13 25.75C13 25.3358 13.3358 25 13.75 25H16.25C16.6642 25 17 25.3358 17 25.75C17 26.1642 16.6642 26.5 16.25 26.5H13.75C13.3358 26.5 13 26.1642 13 25.75Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M13 5.75C13 5.33579 13.3358 5 13.75 5L16.25 5C16.6642 5 17 5.33579 17 5.75C17 6.16421 16.6642 6.5 16.25 6.5H13.75C13.3358 6.5 13 6.16421 13 5.75Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M8 15.5C8 15.2239 8.22386 15 8.5 15L21.5 15C21.7761 15 22 15.2239 22 15.5C22 15.7761 21.7761 16 21.5 16L8.5 16C8.22386 16 8 15.7761 8 15.5Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M9 9C9.82843 9 10.5 9.67157 10.5 10.5V13.25C10.5 13.6642 10.1642 14 9.75 14C9.33579 14 9 13.6642 9 13.25V9Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M13.25 9C13.6642 9 14 9.33579 14 9.75C14 10.1642 13.6642 10.5 13.25 10.5L10.5 10.5C9.67157 10.5 9 9.82843 9 9H13.25Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M21 22C20.1716 22 19.5 21.3284 19.5 20.5L19.5 17.75C19.5 17.3358 19.8358 17 20.25 17C20.6642 17 21 17.3358 21 17.75L21 22Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M16.75 22C16.3358 22 16 21.6642 16 21.25C16 20.8358 16.3358 20.5 16.75 20.5L19.5 20.5C20.3284 20.5 21 21.1716 21 22L16.75 22Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M21 9C21 9.82843 20.3284 10.5 19.5 10.5L16.75 10.5C16.3358 10.5 16 10.1642 16 9.75C16 9.33579 16.3358 9 16.75 9L21 9Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M21 13.25C21 13.6642 20.6642 14 20.25 14C19.8358 14 19.5 13.6642 19.5 13.25L19.5 10.5C19.5 9.67157 20.1716 9 21 9L21 13.25Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M9 22C9 21.1716 9.67157 20.5 10.5 20.5L13.25 20.5C13.6642 20.5 14 20.8358 14 21.25C14 21.6642 13.6642 22 13.25 22L9 22Z"
      fill={stroke || '#666666'}
    />
    <path
      d="M9 17.75C9 17.3358 9.33579 17 9.75 17C10.1642 17 10.5 17.3358 10.5 17.75L10.5 20.5C10.5 21.3284 9.82843 22 9 22L9 17.75Z"
      fill={stroke || '#666666'}
    />
  </svg>
);

export const ID = () => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.71196 12H0.511963V10.624L1.90396 9.98399V2.59199L0.511963 1.95199V0.575989H5.71196V1.95199L4.31996 2.59199V9.98399L5.71196 10.624V12Z"
      fill="white"
    />
    <path
      d="M17.1307 6.17599C17.1307 8.10666 16.5814 9.56266 15.4827 10.544C14.3947 11.5147 12.864 12 10.8907 12H7.65871V0.575989H11.2427C12.4374 0.575989 13.472 0.789322 14.3467 1.21599C15.232 1.64266 15.9147 2.27199 16.3947 3.10399C16.8854 3.92532 17.1307 4.94932 17.1307 6.17599ZM14.6187 6.23999C14.6187 4.97066 14.3414 4.04266 13.7867 3.45599C13.232 2.85866 12.4267 2.55999 11.3707 2.55999H10.0747V9.99999H11.1147C13.4507 9.99999 14.6187 8.74666 14.6187 6.23999Z"
      fill="white"
    />
  </svg>
);
