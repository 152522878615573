// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { CONST } from '../../utils/helpers';
import { isBreakout } from '../../utils/url';

// Types
interface Auth0Types {
  children: ReactNode;
}

// IMPORTS
// ------------------------------------------------------------
const Auth0 = ({ children }: Auth0Types) => {
  const { pathname, search } = useLocation();

  const isBreakoutSource = isBreakout(search);

  // /posters is the only public router
  if (
    pathname.startsWith(CONST.posterRoute) ||
    pathname.startsWith('/notfound') ||
    isBreakoutSource
  )
    return <>{children}</>;

  const redirect = pathname.startsWith(CONST.adminRoute) ? '/admin' : '';

  return (
    <Auth0Provider
      domain={window.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={window.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        ['redirect_uri']: `${window.location.origin}${redirect}`,
        audience: window.REACT_APP_AUTH0_AUDIENCE || '',
      }}
    >
      {children}
    </Auth0Provider>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default Auth0;
