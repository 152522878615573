// IMPORTS
// ------------------------------------------------------------
import Color from 'color';
import styled, { keyframes } from 'styled-components';
import Colors from '../../styles/Colors';

// Types
interface DarkTemplateStylesTypes {
  background?: string;
  opacity?: number;
  backgroundColor?: string;
  titleFontColor?: string;
  bodyFontColor?: string;
  titlebarColor?: string;
  cardColor?: string;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`;

export const DarkTemplateStyles = styled.div<DarkTemplateStylesTypes>`
  background-image: ${({ background }) =>
    background ? `url('${encodeURI(background)}')` : 'none'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#201c3d'};
  background-size: cover;
  background-position: center center;
  min-width: 1200px;
  min-height: 800px;
  transition: margin 250ms ease-in-out 0s;

  .inner {
    background: ${({ opacity }) =>
      opacity !== undefined && opacity >= 0 ? `rgba(255, 255, 255, ${opacity / 100})` : 'none'};

    > main {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .inner {
    > header {
      .edit {
        left: -15px;
        right: -15px;
      }
    }
  }

  .click {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
  }

  .edit.editing {
    border: 2px solid ${Colors.green};
    &:before {
      content: 'Editing';
      display: block;
      position: absolute;
      background: ${Colors.green};
      height: 20px;
      width: auto;
      color: ${Colors.white};
      font-size: 12px;
      line-height: 20px;
      top: 0px;
      left: 0px;
      padding: 0 6px;
      border-bottom-right-radius: 4px;
      font-family: 'sans-serif' !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  .container,
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
    .row {
      .col {
        position: relative;
        margin-bottom: 30px;
      }
    }
  }

  header {
    background: ${(props) => props.titlebarColor || '#1a1736'};
    height: auto;
    padding: 20px 0;
    position: relative;
    .container,
    .container-fluid {
      .row {
        .col {
          display: flex;
          align-items: top;
          justify-content: center;
          flex-direction: column;
          height: auto;
          margin: 0;
          position: static;
          min-height: 100px;

          .edit {
            left: 106px;
            right: 180px;
          }
        }
      }
    }

    h1 {
      margin-left: 120px;
      color: ${({ titleFontColor }) => titleFontColor || Colors.white};
      font-weight: 600;
      font-size: 36px;
      padding-right: 170px;
    }

    p {
      margin-left: 120px;
      color: ${({ titleFontColor }) =>
        Color(titleFontColor || Colors.white)
          .darken(0.05)
          .toString()};
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 0;
      padding-right: 170px;

      &:nth-child(3) {
        color: ${({ titleFontColor }) =>
          Color(titleFontColor || Colors.white)
            .darken(0.1)
            .toString()};
        font-size: 18px;
        font-weight: 400;
        font-style: italic;
      }
    }
    a {
      background: ${({ titlebarColor }) =>
        Color(titlebarColor || '#1A1736')
          .darken(0.1)
          .toString()};
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -15px;
      width: 120px;
      color: ${(props) => props.titleFontColor || Colors.white};
      font-size: 14px;
      transition: all 250ms ease-in-out 0s;
      text-align: center;

      svg {
        color: ${(props) => props.titleFontColor || Colors.white};
        margin-right: 5px;
        transition: all 250ms ease-in-out 0s;
        position: absolute;
        top: 0;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
      }

      &:hover {
        background: ${({ titlebarColor }) =>
          Color(titlebarColor || '#1A1736')
            .lighten(0.1)
            .toString()};
      }

      span {
        position: absolute;
        top: 34px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 20px;
        line-height: 20px;
      }
    }

    /* Top Right Navigation */
    aside {
      position: absolute;
      right: 15px;
      top: 15px;
      width: auto;
      display: flex;
      align-items: center;

      button,
      span {
        background: ${(props) => props.titlebarColor || Colors.white};
        margin-left: 20px !important;
        display: block;
        width: 64px;
        height: 64px;
        border-radius: 64px;
        margin-left: 10px;
        border: none;
        font-size: 28px;
        font-weight: 600;
        line-height: 60px;
        text-align: center;
        color: ${(props) => props.titleFontColor || '#15de97'};

        &:active,
        &:focus {
          outline: none;
        }

        &.institution {
          border-radius: 0px;
          background-color: transparent;
          border: none;
          width: 100px;
          height: 100px;
          background-size: cover;
        }
      }

      button {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        position: relative;
        transition: all 250ms ease-in-out 0s;
        padding: 0;
        border: 2px solid ${(props) => props.titleFontColor || '#15de97'};

        &:first-child {
          background: ${(props) =>
            Color(props.titlebarColor || '#1A1736')
              .lighten(0.2)
              .toString()};
          border-radius: 4px;
          line-height: 50px;
        }

        svg {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        &:hover {
          transform: scale(1.05);
        }

        &:nth-child(1) {
          position: relative;
          /* padding: 0px 0px 0px 15px; */
          svg,
          img {
            position: static;
            display: block;
            width: 30px;
            height: 30px;
            margin: 0 0 0 0;
          }
        }

        &:nth-child(2) {
          position: relative;
          svg,
          img {
            border-radius: 60px !important;
          }

          img {
            display: block;
            width: 60px;
            height: 60px;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
      }

      span {
        border: 1px solid ${Colors.borderGrey};
        background-size: cover;
        background-position: center center;
      }
    }
  }

  .columnCard {
    background: ${(props) => props.cardColor || '#2e2a4f'};
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: calc(100vh - 54px - 4px - 175px);
    width: calc(100% - 4px);
    border: 2px solid ${(props) => props.cardColor || '#2e2a4f'};
    transition: all 250ms ease-in-out 0s;
    overflow: auto;
    border-radius: 2px;
    position: relative;

    &:hover {
      border-color: ${Colors.blue};
    }

    &.editing {
      border: 2px solid ${Colors.green};
      &:before {
        content: 'Editing';
        display: block;
        position: sticky;
        background: ${Colors.green};
        height: 20px;
        width: min-content;
        color: ${Colors.white};
        font-size: 12px;
        line-height: 20px;
        padding: 0 6px;
        top: -20px;
        left: -20px;
        margin-top: -20px;
        margin-left: -20px;
        border-bottom-right-radius: 4px;
        z-index: 15;
      }
    }

    &.half {
      height: calc(50vh - 28px - 87px);
    }

    &.half-stacked {
      height: calc(50vh - 28px - 87px - 15px);
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .text {
      p {
        color: ${(props) => props.bodyFontColor || '#edebff'};
      }

      h1 {
        color: ${(props) => props.bodyFontColor || Colors.white};
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 10px;
      }

      h2 {
        color: ${(props) => props.bodyFontColor || Colors.white};
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 18px;
        line-height: 22px;
        color: ${(props) => props.bodyFontColor || '#c3beed'};
        margin-bottom: 20px;
      }

      em {
        color: ${(props) => props.bodyFontColor || Colors.white};
        a {
          background: none !important;
          color: ${(props) => props.bodyFontColor || '#15de97'};
        }
      }
      strong {
        font-weight: bold;
        color: ${(props) => props.bodyFontColor || Colors.white};
        a {
          background: none !important;
          color: ${(props) => props.bodyFontColor || '#15de97'};
        }
      }
      a {
        background: none !important;
        color: ${(props) => props.bodyFontColor || '#15de97'};
        font-weight: 400;
        text-decoration: underline;
      }
      li {
        color: ${(props) => props.bodyFontColor || '#edebff'};
      }
    }

    &.body-serif {
      .text {
        p {
          font-size: 14px;
          line-height: 28px;
        }
      }
    }

    section {
      margin-bottom: 20px;
    }

    .text {
      p {
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
        color: ${(props) => props.bodyFontColor || ' #edebff'};
        margin-bottom: 16px;
      }
    }

    .video {
      iframe {
        border: none;
        border-radius: 4px;
      }
    }

    .sound {
      audio {
        border-radius: 4px;
        width: 100%;
        input {
          display: none;
        }
        &:focus {
          outline: none;
        }
      }
      small {
        margin-top: 6px;
        font-size: 12px;
        line-height: 18px;
        font-style: italic;
        color: ${(props) => props.bodyFontColor || '#b7b1e7'};
        display: block;
        text-align: center;
      }
    }

    .image {
      img {
        width: 100%;
        height: auto;
        margin: 0;
        display: block;
        border-radius: 2px;
      }
      figcaption {
        margin-top: 6px;
        font-size: 12px;
        line-height: 18px;
        font-style: italic;
        color: ${(props) => props.bodyFontColor || '#b7b1e7'};
        display: block;
        text-align: center;
      }
    }

    .quote {
      blockquote {
        background: ${(props) =>
          props.cardColor ? Color(props.cardColor).darken(0.1).toString() : '#201c3d'};
        padding: 20px 64px 20px 25px;
        color: ${(props) => props.bodyFontColor || Colors.white};
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 5px;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          background: ${(props) =>
            props.cardColor ? Color(props.cardColor).darken(0.3).toString() : '#8784ed'};
        }

        svg {
          position: absolute;
          top: 20px;
          right: 20px;
          path {
            fill: ${(props) =>
              props.cardColor ? Color(props.cardColor).darken(0.3).toString() : '#595389'};
          }
        }
        p {
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 6px;
        }
        cite {
          font-size: 12px;
          line-height: 28px;
          font-style: italic;
          color: ${(props) =>
            props.bodyFontColor ? Color(props.bodyFontColor).lighten(0.2).toString() : '#b7b1e7'};
          &:before {
            content: '- ';
          }
          span {
            color: ${(props) => props.bodyFontColor || '#ebe9ff'};
          }
        }
      }
    }
  }

  article {
    backdrop-filter: blur(10px);
    background: ${(props) =>
      props.backgroundColor
        ? Color(props.backgroundColor).darken(0.3).toString() + '80'
        : 'rgba(26, 23, 54, 0.5)'};
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: ${fadeIn} 250ms forwards;

    > button[type='button'] {
      display: block;
      position: fixed;
      top: 20px;
      right: 20px;
      width: 44px;
      height: 44px;
      background: ${(props) =>
        props.cardColor ? Color(props.cardColor).darken(0.1).toString() : Colors.white};
      color: ${(props) => props.bodyFontColor || Colors.grey};
      border-radius: 44px;
      border: none;
      transition: all 250ms ease-in-out 0s;

      &:active,
      &:focus {
        outline: none;
      }

      &:hover {
        transform: scale(1.05);
      }

      svg {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
    }

    aside {
      background: ${(props) =>
        props.cardColor ? Color(props.cardColor).darken(0.1).toString() : '#2e2a4f'};
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 540px;
      animation: ${slideIn} 250ms forwards;
      overflow-y: scroll;

      &.profile {
        background: ${(props) => props.titleFontColor || Colors.white};
      }

      section {
        > svg {
          background: ${(props) => props.titleFontColor || Colors.white};
          width: 296px !important;
          height: 296px !important;
          border-radius: 4px;
          padding: 20px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        header {
          background: ${(props) =>
            props.titlebarColor ? Color(props.titlebarColor).darken(0.1).toString() : '#1a1736'};
          height: 310px;
          width: 100%;
          position: relative;
          text-align: center;

          svg,
          img {
            width: 120px;
            height: 120px;
            border-radius: 120px !important;
            border: 2px solid ${(props) => props.titleFontColor || Colors.white};
            margin: 60px 5px 20px 5px;
            position: static;
            object-fit: cover;
          }

          h2 {
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 3px;
            color: ${(props) => props.titleFontColor || Colors.white};
          }
          p {
            font-size: 16px;
            line-height: 22px;
            color: ${(props) => props.titleFontColor || '#d9d9d9'};
            margin-left: auto;
            margin-right: auto;
            padding: 0;
            margin: 0;
          }
        }

        main {
          padding: 30px 40px;
          h3 {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;
            color: ${(props) => props.bodyFontColor || Colors.white};
          }

          p {
            font-size: 16px;
            line-height: 30px;
            color: ${(props) => props.bodyFontColor || '#edebff'};
            font-weight: 300;
            margin-bottom: 20px;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 20px;
              padding-left: 54px;
              position: relative;
              height: 44px;
              line-height: 44px;

              a {
                color: ${(props) => props.bodyFontColor || Colors.white};
              }

              .social {
                display: block;
                width: 44px;
                height: 44px;
                border-radius: 44px;
                color: ${(props) => props.bodyFontColor || Colors.white};
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;

                svg {
                  width: 16px;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                }
              }

              .social-twitter {
                background: #1da1f2;
              }

              .social-facebook {
                background: #4267b2;
              }

              .social-instagram {
                background: #f55376;
                color: #fff;
              }

              .social-website {
                background: #333333;
                color: #fff;
              }

              .social-orcid {
                background: #baed66;
              }
            }
          }
        }
      }
    }

    &.profile {
      aside {
        background: ${(props) =>
          props.cardColor ? Color(props.cardColor).darken(0.1).toString() : '#2e2a4f'};
      }
    }

    &.card {
      background: rgba(51, 51, 51, 0.7);

      aside {
        background: ${(props) =>
          props.cardColor ? Color(props.cardColor).darken(0.1).toString() : '#2e2a4f'};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        height: 100%;
        width: 680px;
        animation: none;

        .columnCard {
          background: none;
          box-shadow: none;
          padding: 30px;
          height: calc(100vh);
          width: 100%;
          border: none;
          transition: all 250ms ease-in-out 0s;
          overflow: auto;
        }
      }
    }
  }
`;
