// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link } from 'react-router-dom';

// Providers
import { useFetch } from '../../providers/Fetch';
import { useAuth } from '../../providers/Auth';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataEdit from '../../components/DataEdit';

// Styled
import { ConferenceSettingsPageStyles } from './styles';
import { ImageInput } from '../../components/ImageInput';
import { AxiosProgressEvent } from 'axios';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const ConferenceSettingsPage = () => {
  // State / Props
  const [input, setInput] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const { fetch } = useFetch();
  const isMountedRef = React.useRef(false);
  const { auth } = useAuth();

  // Requests
  /**
   *
   */
  const getData = React.useCallback(async () => {
    setIsLoading(true);
    fetch(
      {
        url: `/conferences/${auth.conferenceId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      (response: any) => {
        if (isMountedRef.current) {
          const { photo, name, domain, url, description, background } = response.data.data;
          setInput({
            photo,
            name,
            domain,
            url,
            description,
            background,
          });
          setIsLoading(false);
        }
      },
      () => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      },
    );
  }, [fetch, auth]);

  /**
   *
   */
  const updateEntry = React.useCallback(
    async (id: string, data: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        },
        () => {
          if (isMountedRef.current) {
            getData();
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        },
      );
    },
    [fetch, getData, auth],
  );

  // Functions
  /**
   *
   */
  const onClickUpdate = () => {
    updateEntry(auth.conferenceId, input);
  };

  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
  };

  /**
   *
   * @param key
   */
  const onClickRemove = (key: string) => () => {
    updateEntry(auth.conferenceId, { ...input, [key]: null });
  };

  /**
   *
   * @param event
   */
  const onChangeFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
  ) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('files', event.target.files?.[0] as any);
    fetch(
      {
        url: `/uploads`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress,
      },
      (response: any) => {
        event.target.value = '';
        updateEntry(auth.conferenceId, {
          ...input,
          [event.target.name]: response?.data?.data?.[0]?.key,
        });
      },
      () => {
        event.target.value = '';
        setIsLoading(false);
      },
    );
  };

  // Hooks
  React.useEffect(() => {
    getData();
  }, [getData]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Render
  return (
    <DashboardLayout>
      <ConferenceSettingsPageStyles>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataEdit
                isLoading={isLoading}
                title="Conference"
                subtitle="Settings"
                actions={[
                  <button
                    key="save"
                    onClick={onClickUpdate}
                    disabled={isLoading}
                    className="btn btn-primary"
                  >
                    Save
                  </button>,
                ]}
                breadcrumbs={[
                  <Link to="/" key="home">
                    Home
                  </Link>,
                  <span key="settings">Conference Settings</span>,
                ]}
              >
                <div className="row">
                  <div className="col col-12">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${tab === 0 ? 'active' : ''}`}
                          href="#details"
                          onClick={(e) => {
                            e.preventDefault();
                            if (tab !== 0) setTab(0);
                          }}
                        >
                          Details
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className={`nav-link ${tab === 1 ? 'active' : ''}`}
                          href="#admins"
                          tabIndex={-1}
                          aria-disabled="true"
                          onClick={(e) => {
                            e.preventDefault();
                            if (tab !== 1) setTab(1);
                          }}
                        >
                          Admins
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <section className="row" style={{ display: tab === 0 ? 'block' : 'none' }}>
                  <div className="col col-12 col-md-6">
                    <form>
                      <ImageInput
                        sidePreview
                        src={input?.photo}
                        name="photo"
                        formLabel="Conference Thumbnail"
                        id="photo"
                        disabled={isLoading}
                        onChange={onChangeFile}
                        sizeLabel="20MB"
                        recommendedDimension="1920x1080"
                      />
                      <hr />

                      <ImageInput
                        id="conference-background"
                        name="background"
                        formLabel={
                          <>
                            Conference Background
                            <br />
                            <small>
                              This image banner will be displayed on the conference home page.
                            </small>
                          </>
                        }
                        src={input?.background}
                        disabled={isLoading}
                        onChange={onChangeFile}
                        removeImage={onClickRemove('background')}
                        sizeLabel="20MB"
                        recommendedDimension="2400x400"
                        customPreview={(children) => (
                          <div
                            className="preview"
                            style={{
                              backgroundImage: `url(${encodeURI(input?.background)})`,
                              borderRadius: '0.25rem',
                              display: 'flex',
                              width: '100%',
                              height: '120px',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              justifyContent: 'right',
                              alignItems: 'flex-start',
                            }}
                          >
                            {children}
                          </div>
                        )}
                      />

                      <div className="form-group">
                        <label htmlFor="name">Conference Name</label>
                        <input
                          disabled={isLoading}
                          onChange={onChangeInput}
                          id="name"
                          value={input?.name ?? ''}
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="ex: My Conference"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="domain">Domain</label>
                        <input
                          disabled={true}
                          onChange={onChangeInput}
                          id="domain"
                          value={input?.domain ?? ''}
                          type="text"
                          name="domain"
                          className="form-control"
                          placeholder="ex: my-conference-123"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="url">Conference Website</label>
                        <input
                          onChange={onChangeInput}
                          value={input?.url ?? ''}
                          disabled={isLoading}
                          id="url"
                          name="url"
                          type="text"
                          className="form-control"
                          required
                          placeholder="ex: http://myconference.com"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                          onChange={onChangeTextarea}
                          name="description"
                          value={input?.description ?? ''}
                          disabled={isLoading}
                          id="description"
                          className="form-control"
                          placeholder="ex: Describing my conference..."
                        />
                      </div>
                    </form>
                  </div>
                </section>
              </DataEdit>
              {/* <DataEdit
                isLoading={isLoading}
                title="Conference Filters"
                actions={[
                  <button onClick={onClickUpdateFilters} disabled={isLoading} className="btn btn-primary">
                    Save
                  </button>,
                ]}
              ></DataEdit> */}
            </div>
          </div>
        </div>
      </ConferenceSettingsPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default ConferenceSettingsPage;
