// IMPORTS
// ------------------------------------------------------------
import Color from 'color';
import styled, { css, keyframes } from 'styled-components';
import Colors from '../../styles/Colors';

// Types
interface MinimalTemplateStylesTypes {
  background?: string;
  backgroundColor?: string;
  opacity?: number;
  cardColor?: string;
  titlebarColor?: string;
  bodyFontColor?: string;
  titleFontColor?: string;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`;

export const MinimalTemplateStyles = styled.div<MinimalTemplateStylesTypes>`
  ${({
    background,
    backgroundColor = '#FFFFFF',
    opacity,
    cardColor = '#fafafa',
    titlebarColor = '#3a3a3a',
    bodyFontColor = '#1a1a1a',
    titleFontColor = '#e1e1e1',
  }) => css`
    background-color: ${backgroundColor};
    background-image: ${background ? `url('${encodeURI(background)}')` : 'none'};
    background-size: cover;
    background-position: center center;
    min-width: 1200px;
    min-height: 800px;
    transition: margin 250ms ease-in-out 0s;

    small {
      margin-top: 6px;
      font-size: 12px;
      line-height: 18px;
      font-style: italic;
      color: ${Color(titleFontColor).darken(0.2).toString()};
      display: block;
      text-align: center;
    }

    .inner {
      background: ${opacity ? `rgba(255, 255, 255, ${opacity / 100})` : 'none'};
    }
    .edit {
      display: block;
      border: 2px solid transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 250ms ease-in-out 0s;

      &:hover {
        border: 2px solid ${Colors.blue};
      }
    }

    .click {
      display: block;
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
    }

    .edit.editing {
      border: 2px solid ${Colors.green};
      &:before {
        content: 'Editing';
        display: block;
        position: absolute;
        background: ${Colors.green};
        height: 20px;
        width: auto;
        color: ${Colors.white};
        font-size: 12px;
        line-height: 20px;
        top: 0px;
        left: 0px;
        padding: 0 6px;
        border-bottom-right-radius: 4px;
        font-family: 'sans-serif' !important;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
    }

    .container,
    .container-fluid {
      padding-left: 30px;
      padding-right: 30px;
      .row {
        .col {
          position: relative;
          margin-bottom: 30px;
        }
      }
    }
    header {
      height: auto;
      padding: 25px 0px;
      margin-bottom: 1.5rem;
      background-color: ${titlebarColor};

      .container,
      .container-fluid {
        .row {
          .col {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            flex-direction: row;
            height: auto;
            margin-bottom: 0;

            .edit {
              left: 15px;
              right: 15px;
            }
          }
        }
      }

      h1 {
        font-weight: 800;
        display: block;
        text-align: center;
        padding: 0px;
        color: ${titleFontColor};
      }

      p {
        font-size: 21px;
        font-weight: 200;
        margin-bottom: 0;

        padding: 0px;
        color: ${Color(titleFontColor).darken(0.1).toString()};

        &:nth-child(3) {
          font-size: 14px;
          font-weight: 300;
          font-style: italic;
        }
      }
      a {
        min-width: 210px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 24px;
        color: ${titleFontColor};
        font-size: 18px;
        font-weight: 700;
        transition: all 250ms ease-in-out 0s;
        padding: 15px;
        border-radius: 1rem;
        border: 2px solid transparent;

        svg {
          color: ${titleFontColor};
          margin-right: 5px;
          transition: all 250ms ease-in-out 0s;
        }

        &:hover {
          border-color: ${titleFontColor};
        }
      }

      aside {
        width: 250px;
        display: flex;
        align-items: center;

        button,
        span {
          background: ${titleFontColor};
          display: block;
          width: 60px;
          height: 60px;
          border-radius: 60px;
          margin-left: 10px;
          border: none;

          &:active,
          &:focus {
            outline: none;
          }

          &.institution {
            border-radius: 0px;
            background-color: transparent;
            border: none;
            width: 100px;
            height: 100px;
            background-size: cover;
          }
        }

        button {
          box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
          position: relative;
          transition: all 250ms ease-in-out 0s;

          svg {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          &:hover {
            transform: scale(1.05);
          }

          &:nth-child(1) {
            position: relative;
            padding: 0px 0px 0px 15px;
            svg,
            img {
              position: static;
              display: block;
              width: 30px;
              height: 30px;
              margin: 0 0 0 0;
            }
          }

          &:nth-child(2) {
            position: relative;
            svg,
            img {
              border-radius: 60px !important;
            }

            img {
              display: block;
              width: 60px;
              height: 60px;
              position: absolute;
              top: 0;
              left: 0;
              object-fit: cover;
            }
          }
        }

        span {
          border: 1px solid ${Colors.borderGrey};
          background-size: cover;
          background-position: center center;
        }
      }
    }

    .columnCard {
      background: ${cardColor};
      box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.1);
      padding: 20px;
      height: calc(100vh - 54px - 4px - 175px);
      width: calc(100% - 4px);
      border: 2px solid transparent;
      transition: all 250ms ease-in-out 0s;
      overflow: auto;
      position: relative;

      h1,
      h2,
      h3 {
        color: ${bodyFontColor};
      }

      &:hover {
        border-color: ${Colors.blue};
      }

      &.editing {
        border-color: ${Colors.green};
        &:before {
          content: 'Editing';
          display: block;
          position: sticky;
          background: ${Colors.green};
          height: 20px;
          width: min-content;
          color: ${Colors.white};
          font-size: 12px;
          line-height: 20px;
          padding: 0 6px;
          top: -20px;
          left: -20px;
          margin-top: -20px;
          margin-left: -20px;
          border-bottom-right-radius: 4px;
          z-index: 15;
        }
      }

      &.half {
        height: calc(50vh - 28px - 87px);
      }

      &.half-stacked {
        height: calc(50vh - 28px - 87px - 15px);
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .text {
        em {
          color: ${Color(bodyFontColor).darken(0.2).toString()}80;
          a {
            color: ${bodyFontColor};
          }
        }
        strong {
          color: ${Color(bodyFontColor).darken(0.2).toString()}80;
          a {
            color: ${bodyFontColor};
          }
        }
        a {
          color: ${bodyFontColor};
          font-weight: 400;
        }
        li {
          color: ${bodyFontColor};
        }
      }

      section {
        margin-bottom: 20px;
      }

      .text {
        p {
          font-size: 16px;
          line-height: 30px;
          font-weight: 300;
          color: ${bodyFontColor};
          margin-bottom: 16px;
        }
      }

      .video {
        iframe {
          border: none;
        }
      }

      .sound {
        small {
          color: ${bodyFontColor};
        }
        audio {
          width: 100%;
          input {
            display: none;
          }
          &:focus {
            outline: none;
          }
          &::-webkit-media-controls {
            background: #efefef;
          }
          &::-webkit-media-controls-overlay-enclosure {
            background: none;
          }
          &::-webkit-media-controls-current-time-display {
            display: none;
          }
          &::-webkit-media-controls-time-remaining-display {
            display: none;
          }
          &::-webkit-media-controls-volume-control-container {
            display: none;
          }
        }
      }

      .image {
        img {
          width: 100%;
          height: auto;
          margin: 0;
          display: block;
        }
        figcaption {
          margin-top: 6px;
          font-size: 12px;
          line-height: 18px;
          font-style: italic;
          color: ${bodyFontColor};
          display: block;
          text-align: center;
        }
      }

      .quote {
        blockquote {
          background: ${Color(cardColor).darken(0.1).toString()};
          padding: 20px 64px 20px 20px;
          color: ${bodyFontColor};
          position: relative;
          svg {
            position: absolute;
            top: 20px;
            right: 20px;
          }
          p {
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 6px;
          }
          cite {
            font-size: 12px;
            line-height: 28px;
            font-style: italic;
            color: ${bodyFontColor};
            &:before {
              content: '- ';
            }
            span {
              color: ${Color(bodyFontColor).toString()}80;
            }
          }
        }
      }
    }

    article {
      backdrop-filter: blur(10px);
      background: ${Color(backgroundColor).toString()}80;
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      animation: ${fadeIn} 250ms forwards;

      > button[type='button'] {
        display: block;
        position: fixed;
        top: 20px;
        right: 20px;
        width: 44px;
        height: 44px;
        background: ${cardColor};
        color: ${bodyFontColor};
        border-radius: 44px;
        border: none;
        transition: all 250ms ease-in-out 0s;

        &:active,
        &:focus {
          outline: none;
        }

        &:hover {
          transform: scale(1.05);
        }

        svg {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
        }
      }

      aside {
        background: ${Color(cardColor).darken(0.1).toString()};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 540px;
        animation: ${slideIn} 250ms forwards;
        overflow-y: scroll;

        &.profile {
          background: ${Colors.white};
        }

        section {
          > svg {
            background: ${Colors.white};
            width: 296px !important;
            height: 296px !important;
            padding: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          header {
            background: ${Color(titlebarColor).darken(0.1).toString()};
            height: 310px;
            width: 100%;
            position: relative;
            text-align: center;

            svg,
            img {
              width: 120px;
              height: 120px;
              border-radius: 120px !important;
              border: 2px solid ${Colors.white};
              margin: 60px 5px 20px 5px;
              position: static;
              object-fit: cover;
            }

            h2 {
              font-size: 24px;
              line-height: 33px;
              margin-bottom: 3px;
              color: ${titleFontColor};
            }
            p {
              font-size: 16px;
              line-height: 22px;
              color: ${titleFontColor};
            }
          }

          main {
            padding: 30px 40px;
            h3 {
              font-size: 16px;
              line-height: 22px;
              color: ${bodyFontColor};
              margin-bottom: 20px;
            }

            p {
              font-size: 16px;
              line-height: 30px;
              color: ${bodyFontColor};
              font-weight: 300;
              margin-bottom: 20px;
            }

            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                margin-bottom: 20px;
                padding-left: 54px;
                position: relative;
                height: 44px;
                line-height: 44px;

                a {
                  color: ${bodyFontColor};
                }

                .social {
                  display: block;
                  width: 44px;
                  height: 44px;
                  border-radius: 44px;
                  color: ${Colors.white};
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto 0;

                  svg {
                    width: 16px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  }
                }

                .social-twitter {
                  background: #1da1f2;
                }

                .social-facebook {
                  background: #4267b2;
                }

                .social-instagram {
                  background: #f55376;
                  color: #fff;
                }

                .social-website {
                  background: #333333;
                  color: #fff;
                }

                .social-orcid {
                  background: #baed66;
                }
              }
            }
          }
        }
      }

      &.profile {
        aside {
          background: ${Color(cardColor).darken(0.1).toString()};
        }
      }

      &.card {
        background: rgba(51, 51, 51, 0.7);

        aside {
          background: ${Color(cardColor).darken(0.1).toString()};
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          height: 100%;
          width: 640px;
          animation: none;

          .columnCard {
            background: none;
            box-shadow: none;
            padding: 20px;
            height: calc(100vh);
            width: 100%;
            border: none;
            transition: all 250ms ease-in-out 0s;
            overflow: auto;
          }
        }
      }
    }
  `}
`;
