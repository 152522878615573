// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// Helpers
import { CONST, getDefaultRoute, removeEmptyOrNull } from '../../utils/helpers';

// Providers
import { useAuth } from '../../providers/Auth';
import { useModal } from '../../providers/Modal';
import { useFetch } from '../../providers/Fetch';
import { useNotification } from '../../providers/Notification';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataEdit from '../../components/DataEdit';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';

// Styles
import { CategoryEditPageStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const CategoryEditPage = () => {
  // State / Props
  const [input, setInput] = React.useState<any>({});
  const [focused, setFocused] = React.useState<any>({});
  const [invalid, setInvalid] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const { pathname } = useLocation();
  const isMountedRef = React.useRef(false);
  const { add } = useNotification();
  const [requiredFields, setRequiredFields] = React.useState({ name: false });
  const [errors, setErrors] = React.useState<any>({});
  const [inputChanged, setInputChanged] = React.useState(false);

  // Providers
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setModal } = useModal();
  const { fetch } = useFetch();
  const { auth } = useAuth();

  const params = new URLSearchParams(window.location.search);
  const isNew = params.get('isNew');

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (uuid: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth?.conferenceId}/categories/${uuid}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            const { name } = response.data.data;
            setInput({
              name,
            });
            setIsLoading(false);
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        },
      );
    },
    [fetch, auth],
  );

  /**
   *
   */
  const deleteEntry = React.useCallback(
    async (entryId: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/categories/${entryId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.DELETED);
            navigate(`${CONST.adminRoute}/categories`);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.DELETED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, navigate, auth],
  );

  /**
   *
   */
  const createEntry = React.useCallback(
    async (data: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/categories`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        },
        (response: any) => {
          if (isMountedRef.current) {
            setErrors({});
            add(CONST.REQUESTS.SUCCESS.CREATED);
            navigate(`${CONST.adminRoute}/categories/${response.data.data.id}/edit?isNew=true`);
          }
        },
        (error: any) => {
          if (isMountedRef.current) {
            setErrors({});
            if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
              error.response?.data?.errors.map((item: any) => {
                setErrors({
                  ...errors,
                  [item?.param]: item?.msg,
                });
                return item;
              });
            }
            add(CONST.REQUESTS.ERRORS.CREATED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, navigate, errors, auth],
  );

  /**
   *
   */
  const updateEntry = React.useCallback(
    async (entryId: string, data: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/categories/${entryId}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(removeEmptyOrNull(data)),
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.SAVED);
            getData(entryId);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData, auth],
  );

  // Functions
  /**
   *
   */
  const onClickCancel = () => {
    navigate(`${CONST.adminRoute}/categories`);
  };

  /**
   *
   */
  const onClickDelete = () => {
    setModal(
      <ModalContainer>
        <Modal
          title={`Delete '${input.name}'?`}
          hasBody={false}
          handleClose={() => setModal(null)}
          footer={
            <>
              <button
                onClick={() => setModal(null)}
                className="btn flex-fill btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteEntry(id as string);
                  setModal(null);
                }}
                className="btn flex-fill btn-outline-danger"
              >
                Delete
              </button>
            </>
          }
        />
      </ModalContainer>,
    );
  };

  /**
   *
   */
  const onClickSave = () => {
    createEntry(input);
  };

  /**
   *
   */
  const onClickUpdate = () => {
    updateEntry(id as string, input);
  };

  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFocused({ ...focused, ...{ [event.target.name]: true } });
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
    if (isNew) {
      setInputChanged(true);
    }
    if (event.target.value.length > 0) {
      setRequiredFields({
        ...requiredFields,
        [event?.target.name]: true,
      });
    }
  };

  // Hooks
  React.useEffect(() => {
    if (!pathname.endsWith('new')) return;
    setIsLoading(false);
  }, [pathname]);

  /**
   *
   */
  React.useEffect(() => {
    if (pathname.endsWith('new')) return;
    getData(id as string);
  }, [pathname, id, getData]);

  /**
   *
   */
  React.useEffect(() => {
    const newInvalid: any = {};

    if (
      focused?.domain &&
      ((input?.domain ?? '').length < 3 ||
        !(input?.domain ?? '').match(/^([a-z0-9]*-)*([a-z0-9])*^.*[^-]$/g) ||
        (input?.domain ?? '').match(/[/[!@#$%^&*()_+={}|[\];':",./<>?]/g))
    ) {
      newInvalid.domain = 'Invalid value.';
    }

    setInvalid(newInvalid);
  }, [input, focused]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Render
  return (
    <DashboardLayout>
      <CategoryEditPageStyles>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataEdit
                isLoading={isLoading}
                subtitle={
                  pathname.endsWith('new') ? 'New Category' : `Category: ${input?.name ?? ''}`
                }
                actions={
                  pathname.endsWith('new')
                    ? [
                        <button
                          key="cancel"
                          onClick={onClickCancel}
                          disabled={isLoading}
                          className={`btn btn-outline-secondary`}
                        >
                          Cancel
                        </button>,
                        <button
                          key="save"
                          onClick={onClickSave}
                          disabled={
                            isLoading ||
                            Object.keys(input).length === 0 ||
                            Object.keys(invalid).length > 0 ||
                            !requiredFields.name
                          }
                          className="btn btn-primary"
                        >
                          Save
                        </button>,
                      ]
                    : [
                        <button
                          key="cancel"
                          onClick={onClickCancel}
                          disabled={isLoading}
                          className={`btn btn-outline-secondary`}
                        >
                          Cancel
                        </button>,
                        <button
                          key="delete"
                          onClick={onClickDelete}
                          disabled={isLoading}
                          className={`btn btn-outline-danger`}
                        >
                          Delete
                        </button>,
                        <button
                          key="save"
                          onClick={onClickUpdate}
                          disabled={isLoading}
                          className="btn btn-primary"
                        >
                          Save
                        </button>,
                      ]
                }
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <Link to={`${CONST.adminRoute}/categories`} key="categories">
                    Categories
                  </Link>,
                  <span key="new">{pathname.endsWith('new') ? 'New' : 'Edit'}</span>,
                ]}
                title="Conferences"
              >
                <div className="row">
                  <div className="col col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        disabled={isLoading}
                        onChange={onChangeInput}
                        id="name"
                        value={input?.name ?? ''}
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="ex: Physics"
                      />
                      {isNew && !inputChanged && (
                        <span className="success-message">
                          Category <strong>{input?.name}</strong> was created successfully!
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </DataEdit>
            </div>
          </div>
        </div>
      </CategoryEditPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default CategoryEditPage;
