// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Components
import Breadcrumbs from '../Breadcrumbs';
import LoadingBox from '../../components/LoadingBox';

// Styles
import { DataEditStyles, DataEditActionsStyles } from './styles';

// Types
interface DataEditTypes {
  title?: string;
  subtitle?: string;
  actions?: any;
  breadcrumbs?: Array<any>;
  children?: ReactNode;
  isLoading?: boolean;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const DataEdit = ({
  title,
  subtitle,
  actions = [],
  breadcrumbs = [],
  children,
  isLoading = false,
}: DataEditTypes) => {
  return (
    <DataEditStyles>
      {isLoading ? <LoadingBox height="30px" width="100%" margin="0px 0px 20px 0px" /> : null}
      {!isLoading && breadcrumbs && breadcrumbs.length > 0 ? (
        <Breadcrumbs data={breadcrumbs} />
      ) : null}

      <header>
        {actions?.length > 0 ? (
          <DataEditActionsStyles>
            {isLoading ? <LoadingBox height="38px" width="92px" margin="0px 0px 30px 0px" /> : null}
            {!isLoading &&
              actions.map((action: ReactNode, key: number) => (
                <span key={`actions-${key}`}>{action}</span>
              ))}
          </DataEditActionsStyles>
        ) : null}
        {isLoading ? <LoadingBox height="38px" width="320px" margin="0px 0px 30px 0px" /> : null}
        {!isLoading && title ? (
          <h3>
            {title} {subtitle ? <span className="text-muted">{subtitle}</span> : null}
          </h3>
        ) : null}
      </header>
      {isLoading ? (
        <main>
          <div className="row">
            <div className="col col-12 col-md-6">
              <LoadingBox height="24px" width="130px" margin="0px 0px 8px 0px" />
              <LoadingBox height="38px" width="100%" margin="0px 0px 16px 0px" />
              <LoadingBox height="24px" width="170px" margin="0px 0px 8px 0px" />
              <LoadingBox height="38px" width="100%" margin="0px 0px 16px 0px" />
              <LoadingBox height="24px" width="110px" margin="0px 0px 8px 0px" />
              <LoadingBox height="38px" width="100%" margin="0px 0px 16px 0px" />
            </div>
          </div>
        </main>
      ) : null}
      {!isLoading ? <main>{children}</main> : null}
    </DataEditStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default DataEdit;
