// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// Helpers
import { CONST, getDefaultRoute } from '../../utils/helpers';

// Providers
import { useAuth } from '../../providers/Auth';
import { useFetch } from '../../providers/Fetch';
import { useNotification } from '../../providers/Notification';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataEdit from '../../components/DataEdit';
import DataList from '../../components/DataList';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';

// Styles
import { PosterEditPageStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const PosterEditPage = () => {
  // State / Props
  const [input, setInput] = React.useState<any>({});
  const [posterCategories, setPosterCategories] = React.useState<any>(null);
  const [categories, setCategories] = React.useState<any>([]);
  const [isLoadingCategories, setIsLoadingCategories] = React.useState(false);
  const [selectCategory, setSelectCategory] = React.useState<any>({
    categoryId: '',
  });

  const [focused] = React.useState<any>({});
  const [, setInvalid] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const { pathname } = useLocation();
  const isMountedRef = React.useRef(false);
  const { add } = useNotification();
  // const [requiredFields, setRequiredFields] = React.useState({ name: false });
  // const [errors, setErrors] = React.useState<any>({});
  const [showModalCategories, setShowModalCategories] = React.useState(false);

  // Providers
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { fetch } = useFetch();
  const { auth } = useAuth();

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (uuid: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth?.conferenceId}/posters/${uuid}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          if (isMountedRef.current) {
            const {
              title,
              User,
              subtitle,
              talk,
              abstract,
              thumbnail,
              background,
              templateId,
              layoutId,
              fontBody,
              fontHeader,
              imported,
            } = response.data.data;
            setInput({
              title,
              subtitle,
              talk,
              abstract,
              thumbnail,
              background,
              templateId,
              layoutId,
              fontBody,
              fontHeader,
              User,
              imported,
            });

            fetch(
              {
                url: `/conferences/${auth.conferenceId}/posters/${uuid}/categories`,
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
              (resp: any) => {
                if (isMountedRef.current) {
                  setPosterCategories(resp.data.data);
                  setIsLoading(false);
                }
              },
              () => {
                if (isMountedRef.current) {
                  setIsLoading(false);
                }
              },
            );
          }
        },
        () => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        },
      );
    },
    [fetch, auth],
  );

  /**
   *
   */
  const getCategories = React.useCallback(async () => {
    setIsLoadingCategories(true);
    fetch(
      {
        url: `/conferences/${auth.conferenceId}/categories?take=100`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      (response: any) => {
        if (isMountedRef.current) {
          setCategories(response.data.data);
          setIsLoadingCategories(false);
        }
      },
      () => {
        if (isMountedRef.current) {
          setIsLoadingCategories(false);
        }
      },
    );
  }, [fetch, auth]);

  /**
   *
   */
  const updateEntry = React.useCallback(
    async (entryId: string, data: any) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/posters/${entryId}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(data),
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.SAVED);
            getData(entryId);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData, auth],
  );

  /**
   *
   */
  const updatePosterCategory = React.useCallback(
    async (posterId: string, categoryId: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/posters/${posterId}/categories`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            categoryId,
          }),
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.SAVED);
            getData(posterId);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData, auth],
  );

  /**
   *
   */
  const deletePosterCategory = React.useCallback(
    async (posterId: string, categoryId: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences/${auth.conferenceId}/posters/${posterId}/categories/${categoryId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.SUCCESS.SAVED);
            getData(posterId);
          }
        },
        () => {
          if (isMountedRef.current) {
            add(CONST.REQUESTS.ERRORS.SAVED, 'error');
            setIsLoading(false);
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, getData, auth],
  );

  // Functions
  /**
   *
   */
  const onClickCancel = () => {
    navigate(`${CONST.adminRoute}/posters`);
  };

  /**
   *
   * @param data
   */
  const onClickDelete = (data: any) => () => {
    deletePosterCategory(id as string, data?.id);
  };

  /**
   *
   */
  const onClickUpdate = () => {
    updateEntry(id as string, input);
  };

  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput({
      ...input,
      [event?.target.name]: event?.target.value,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectCategory({
      [event.target.name]: event.target.value,
    });
  };

  /**
   *
   */
  const onClickAddCategory = () => {
    getCategories();
    setShowModalCategories(true);
  };

  // Hooks
  React.useEffect(() => {
    if (!pathname.endsWith('new')) return;
    setIsLoading(false);
  }, [pathname]);

  /**
   *
   */
  React.useEffect(() => {
    getData(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /**
   *
   */
  React.useEffect(() => {
    const newInvalid: any = {};

    if (
      focused?.domain &&
      ((input?.domain ?? '').length < 3 ||
        !(input?.domain ?? '').match(/^([a-z0-9]*-)*([a-z0-9])*^.*[^-]$/g) ||
        (input?.domain ?? '').match(/[/[!@#$%^&*()_+={}|[\];':",./<>?]/g))
    ) {
      newInvalid.domain = 'Invalid value.';
    }

    setInvalid(newInvalid);
  }, [input, focused]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Render
  return (
    <DashboardLayout>
      <PosterEditPageStyles>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataEdit
                isLoading={isLoading}
                subtitle={pathname.endsWith('new') ? 'New' : input?.name ?? ''}
                actions={[
                  <button
                    key="cancel"
                    onClick={onClickCancel}
                    disabled={isLoading}
                    className={`btn btn-outline-secondary`}
                  >
                    Cancel
                  </button>,
                  <button
                    key="save"
                    onClick={onClickUpdate}
                    disabled={isLoading}
                    className="btn btn-primary"
                  >
                    Save
                  </button>,
                ]}
                breadcrumbs={[
                  <Link key="home" to={getDefaultRoute(auth?.role)}>
                    Home
                  </Link>,
                  <Link key="posters" to={`${CONST.adminRoute}/posters`}>
                    Posters
                  </Link>,
                  <span key="edit">Edit</span>,
                ]}
                title="Posters"
              >
                <div className="row">
                  <div className="col col-12">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${tab === 0 ? 'active' : ''}`}
                          href="#details"
                          onClick={(e) => {
                            e.preventDefault();
                            if (tab !== 0) setTab(0);
                          }}
                        >
                          Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            pathname.endsWith('new') ? 'disabled' : tab === 1 ? 'active' : ''
                          }`}
                          href="#categories"
                          tabIndex={-1}
                          aria-disabled="true"
                          onClick={(e) => {
                            e.preventDefault();
                            if (tab !== 1) setTab(1);
                          }}
                        >
                          Categories
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <section className="row" style={{ display: tab === 0 ? 'block' : 'none' }}>
                  <div className="col col-12 col-md-6">
                    <form>
                      <div className="form-group">
                        <label htmlFor="title">Talk number</label>
                        <input
                          disabled={isLoading || input.imported}
                          onChange={onChangeInput}
                          id="talk"
                          value={input?.talk ?? ''}
                          type="text"
                          name="talk"
                          className="form-control"
                          placeholder="ex: 101.1 Scrience"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="abstract">Abstract</label>
                        <textarea
                          onChange={onChangeTextarea}
                          rows={6}
                          name="abstract"
                          value={input?.abstract ?? ''}
                          disabled={isLoading || input.imported}
                          id="abstract"
                          className="form-control"
                          placeholder="ex: Abstract describing my poster..."
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <p>{input?.title}</p>
                      </div>

                      <div className="form-group">
                        <label htmlFor="subtitle">Subtitle</label>
                        <p>{input?.subtitle ?? '(None)'}</p>
                      </div>

                      <div className="form-group">
                        <label htmlFor="overview">Overview</label>
                        <p>{input?.overview ?? '(None)'}</p>
                      </div>

                      <div className="form-group">
                        <label htmlFor="User">User</label>
                        <p>
                          {input?.User?.first_name} {input?.User?.last_name}
                        </p>
                      </div>
                    </form>
                  </div>
                </section>

                <section className="row" style={{ display: tab === 1 ? 'block' : 'none' }}>
                  <div className="col">
                    <DataList
                      isLoading={isLoading}
                      title="Categories"
                      actions={
                        input.imported
                          ? []
                          : [
                              <button
                                key="add"
                                className="btn btn-success"
                                onClick={onClickAddCategory}
                              >
                                Add
                              </button>,
                            ]
                      }
                      columns={{
                        name: {
                          display: 'Category Name',
                        },
                        ...(input.imported
                          ? {}
                          : {
                              actions: {
                                display: 'Actions',
                                class: 'text-right',
                                render: (data: any) => (
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    disabled={isLoading}
                                    onClick={onClickDelete(data)}
                                  >
                                    Delete
                                  </button>
                                ),
                              },
                            }),
                      }}
                      data={posterCategories}
                      hasPagination={false}
                    />
                  </div>
                </section>
              </DataEdit>
            </div>
          </div>
        </div>
      </PosterEditPageStyles>

      {/* MODAL */}
      {showModalCategories ? (
        <ModalContainer>
          <Modal
            title={`Add Category`}
            handleClose={() => {
              if (!isLoadingCategories) {
                setSelectCategory({
                  categoryId: '',
                });
                setShowModalCategories(false);
              }
            }}
            body={
              <>
                <div className="form-group">
                  <label htmlFor="categoryId">Category</label>
                  <select
                    onChange={onChangeSelect}
                    disabled={isLoadingCategories}
                    name="categoryId"
                    id="categoryId"
                    className="form-control"
                    value={selectCategory.categoryId}
                  >
                    <option disabled value="">
                      Select Category
                    </option>
                    {categories &&
                      categories.map((item: any) => (
                        <option key={`${item.id}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </>
            }
            footer={
              <>
                <button
                  disabled={isLoadingCategories}
                  onClick={() => setShowModalCategories(false)}
                  className="btn flex-fill btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  disabled={isLoadingCategories}
                  onClick={() => {
                    updatePosterCategory(id as string, selectCategory.categoryId);
                    setShowModalCategories(false);
                  }}
                  className="btn flex-fill btn-outline-success"
                >
                  Add
                </button>
              </>
            }
          />
        </ModalContainer>
      ) : null}
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default PosterEditPage;
