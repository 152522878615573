// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Styles
import {
  NotificationStyles,
  NotificationProviderStyles,
  NotificationProviderContainerStyles,
} from './styles';

// Types
interface NotificationProviderTypes {
  children: ReactNode;
}

interface NotificationTypes {
  title?: string;
  show?: boolean;
  type: string;
}

// MAIN CONTEXT
// ------------------------------------------------------------
/**
 *
 */
const NotificationContext = React.createContext<any>(null);

// COMPONENT
const Notification = ({ title, show = true, type }: NotificationTypes) => (
  <NotificationStyles type={type} show={show}>
    {title}
  </NotificationStyles>
);

// MAIN PROVIDER
// ------------------------------------------------------------
/**
 *
 * @param param0
 */
const NotificationProvider = ({ children }: NotificationProviderTypes) => {
  // State / Props
  const notifications = React.useRef<any>({});
  const [, setRender] = React.useState<any>();

  // Functions
  /**
   *
   * @param title
   * @param delay
   */
  const add = (title: string, type = 'success', delay = 1500) => {
    const id = uuidv4();
    if (!notifications.current[id]) {
      notifications.current[id] = {
        id,
        title,
        type,
        timeout: () =>
          setTimeout(() => {
            notifications.current[id].show = false;
            setRender(uuidv4());
            setTimeout(() => {
              delete notifications.current[id];
              setRender(uuidv4());
            }, 250);
          }, delay),
        show: true,
      };
    }
    notifications.current[id].timeout();
    setRender(id);
  };

  // Render
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        add,
      }}
    >
      <NotificationProviderStyles>
        <>{children}</>
      </NotificationProviderStyles>
      <NotificationProviderContainerStyles>
        {notifications.current && Object.keys(notifications.current).length > 0 ? (
          <ul>
            {Object.keys(notifications.current).map((i: any, k: number) => (
              <li key={`notification-${k}`}>
                <Notification
                  type={notifications.current[i].type}
                  title={notifications.current[i].title}
                  show={notifications.current[i].show}
                />
              </li>
            ))}
          </ul>
        ) : null}
      </NotificationProviderContainerStyles>
    </NotificationContext.Provider>
  );
};

// MAIN CONSUMER
// ------------------------------------------------------------
/**
 *
 */
const useNotification = () => {
  const context = React.useContext(NotificationContext);
  if (!context) {
    throw new Error('NotificationContext not set!');
  }
  return context;
};

// EXPORTS
// ------------------------------------------------------------
export default NotificationProvider;
export { NotificationContext, useNotification };
