// IMPORTS
// ------------------------------------------------------------
import styled, { keyframes } from 'styled-components';

// Types
interface ModalAsideStylesTypes {
  side?: 'left' | 'right';
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
const rightToLeft = keyframes`
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
`;

export const ModalAsideStyles = styled.div<ModalAsideStylesTypes>`
  position: fixed;
  height: 100%;
  background: white;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  left: ${({ side = 'right' }) => (side === 'right' ? 'auto' : '0px')};
  right: ${({ side = 'right' }) => (side === 'right' ? '0px' : 'auto')};
  animation: ${rightToLeft} 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards;

  > button {
    background: none;
    padding: 0;
    display: block;
    border: none;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 30px;
    right: 30px;
    z-index: 8000;
  }

  @media (min-width: 768px) {
    width: 540px;
  }
`;
