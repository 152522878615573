import { Step } from 'react-joyride';

type EditorStepsProps = {
  resetTutorial: () => void;
  cancelTutorial: () => void;
};

export const postersListSteps: Step[] = [
  {
    title: 'Edit',
    target: '.first-step',
    content: (
      <div>
        You can edit any of your posters accessing the editor.
        <br />
        Click the button above to check how.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideFooter: true,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];

export const editorSteps = ({ cancelTutorial, resetTutorial }: EditorStepsProps): Step[] => {
  return [
    {
      target: '.first-step',
      content: (
        <p style={{ margin: 0 }}>
          Edit your poster titles, upload poster background image, enter keywords, select your
          preferred colors/poster template/style or upload a poster you previously created.
        </p>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.second-step',
      content: <p style={{ margin: 0 }}>Click on a section of your poster to edit it.</p>,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'right',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.third-step',
      content: (
        <p style={{ margin: 0 }}>
          Use these tools to add elements to the selected section. You can add text, quotes, images,
          video URLs, narration, audio files
        </p>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.fourth-step',
      content: (
        <p style={{ margin: 0 }}>You can click here to preview how your poster will look like.</p>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.fifth-step',
      content: (
        <p style={{ margin: 0 }}>Be sure to click “save” after making changes to your poster.</p>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.sixth-step',
      content: (
        <p style={{ margin: 0 }}>
          When you are ready to publish your poster, click “publish” and it will automatically
          upload to the gallery.
        </p>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.seventh-step',
      content: (
        <p style={{ margin: 0 }}>
          If your poster has successfully published to the event gallery, you will see this badge.
        </p>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: -4,
      placement: 'left',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.eighth-step',
      content: (
        <p style={{ margin: 0 }}>Click here to exit the editor and go back to your poster list.</p>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.nineth-step',
      content: (
        <p style={{ margin: 0 }}>Click here to go through the BetterPoster tutorial again. </p>
      ),
      disableBeacon: true,
      hideCloseButton: true,
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: 'main',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <p style={{ margin: 0 }}> Are you ready to start creating your BetterPoster?</p>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '6px' }}>
            <button
              className="btn btn-secondary"
              onClick={() => {
                resetTutorial();
              }}
            >
              I need more help
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                cancelTutorial();
              }}
            >
              Let’s get started!
            </button>
          </div>
        </div>
      ),
      placement: 'center',
      disableScrolling: true,
      hideFooter: true,
      hideCloseButton: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
  ];
};
