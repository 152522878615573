// IMPORTS
// ------------------------------------------------------------
import React from 'react';

// MAIN HOOKS
// ------------------------------------------------------------
const useDebounce = (value: any, delay: number) => {
  // State / Props
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  // Hooks
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  // Render
  return debouncedValue;
};

// EXPORTS
// ------------------------------------------------------------
export default useDebounce;
