// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Styles
import { NavbarStyles } from './styles';

// Types
interface NavbarTypes {
  children?: ReactNode;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const Navbar = ({ children }: NavbarTypes) => {
  return <NavbarStyles className="navbar">{children}</NavbarStyles>;
};

// EXPORTS
// ------------------------------------------------------------
export default Navbar;
