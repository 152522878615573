// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Filter,
  X,
  Tag,
  Info,
  Search,
  Globe,
  Facebook,
  Twitter,
  Instagram,
  ExternalLink,
  LogIn,
} from 'react-feather';
import { Helmet } from 'react-helmet';

// Hoks
import useDebounce from '../../hooks/useDebounce';

// Styles
import { HomePageStyles, HomeEmptyStyles, ComingSoon, ConferenceFinished } from './styles';

// Providers
import { useConference } from '../../providers/Conference';

// Components
import LoadingBox from '../../components/LoadingBox';
import ModalAside from '../../components/ModalAside';
import ModalContainer from '../../components/ModalContainer';
import { useAuth } from '../../providers/Auth';
import { getConferenceStatus, getStartDiff } from '../../utils/time';
import Countdown from '../../components/Countdown';
import dayjs from 'dayjs';
import { isBreakout } from '../../utils/url';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const HomePage = () => {
  // State / Props
  const {
    isLoaded,
    isLoadingCategories,
    categories,
    getCategories,
    isLoadingKeywords,
    keywords,
    getKeywords,
    posters,
    getPosters,
  } = useConference();
  const [showModal, setShowModal] = React.useState<null | any>(null);
  const [search, setSearch] = React.useState<any>('');
  const [category, setCategory] = React.useState<any>(null);
  const [keyword, setKeyword] = React.useState<any>(null);
  const [searchCategories, setSearchCategories] = React.useState('');
  const [searchKeywords, setSearchKeywords] = React.useState('');
  const debouncedSearchCategories = useDebounce(searchCategories, 500);
  const debouncedSearchKeywords = useDebounce(searchKeywords, 500);
  const debouncedSearch = useDebounce(search, 500);
  const navigate = useNavigate();
  const { auth } = useAuth();

  const { search: searchParam } = useLocation();
  const isBreakoutSource = isBreakout(searchParam);

  const { isBefore, isLive, isAfter, hasDate } = getConferenceStatus(
    posters?.conference?.start,
    posters?.conference?.end,
  );

  // Hooks
  /**
   * Search
   */
  React.useEffect(() => {
    getCategories(debouncedSearchCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchCategories]);

  /**
   * Search
   */
  React.useEffect(() => {
    getKeywords(debouncedSearchKeywords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKeywords]);

  /**
   *
   */
  React.useEffect(() => {
    getPosters({ query: debouncedSearch, category: category?.id, keyword: keyword?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, category, keyword]);

  /**
   *
   */
  React.useEffect(() => {
    getPosters({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAttendee = auth && auth.role === 'attendee';

  // Render
  return (
    <HomePageStyles>
      {/* HTML Header */}
      <Helmet>
        <title>{`${
          posters?.conference?.name || 'Better Poster - Build and share your conference posters'
        }`}</title>
        <meta
          name="title"
          content={`${
            posters?.conference?.name || 'Better Poster - Build and share your conference posters'
          }`}
        />
        <meta
          name="description"
          content={`See the posters of ${
            posters?.conference.title || 'this conference'
          } on BetterPoster`}
        />

        <meta property="og:type" content="website" />

        {auth?.domain && (
          <meta
            property="og:url"
            content={`${window.location.protocol}//${auth.domain}.${window.location.host
              .split('.')
              .slice(1)
              .join('.')}`}
          />
        )}
        <meta
          property="og:title"
          content={`${
            posters?.conference?.name || 'Better Poster - Build and share your conference posters'
          }`}
        />
        <meta
          property="og:description"
          content={`See the posters of ${
            posters?.conference.title || 'this conference'
          } on BetterPoster`}
        />
        {posters?.conference?.photo && (
          <meta property="og:image" content={posters?.conference?.photo} />
        )}

        <meta property="twitter:card" content="summary_large_image" />
        {auth?.domain && (
          <meta
            property="twitter:url"
            content={`${window.location.protocol}//${auth.domain}.${window.location.host
              .split('.')
              .slice(1)
              .join('.')}`}
          />
        )}
        <meta
          property="twitter:title"
          content={`${
            posters?.conference?.name || 'Better Poster - Build and share your conference posters'
          }`}
        />
        <meta
          property="twitter:description"
          content={`See the posters of ${
            posters?.conference.title || 'this conference'
          } on BetterPoster`}
        />
        {posters?.conference?.photo && (
          <meta property="twitter:image" content={posters.conference.photo} />
        )}
      </Helmet>
      {/* end - HTML Header */}
      {/* HEADER */}
      <header
        style={{
          backgroundImage: `${
            posters?.conference?.background
              ? `url(${encodeURI(posters?.conference?.background)})`
              : 'none'
          }`,
        }}
      >
        <div className="inner">
          {!isLoaded ? <LoadingBox height="100%" /> : null}
          <img src={`/assets/images/banner_placeholder.png`} alt="placeholder" />
        </div>
      </header>
      {/* end HEADER */}

      {hasDate && isBefore && (
        <ComingSoon>
          <h1>See you soon!</h1>
          <Countdown currentTimeDifference={getStartDiff(posters?.conference.start)} />
          {!isAttendee && !isBreakoutSource && (
            <button
              title="Admin panel"
              className="btn btn-primary btn-lg"
              onClick={() => navigate('/admin')}
            >
              <LogIn size={20} style={{ marginRight: 6 }} />
              <span>Admin panel</span>
            </button>
          )}
        </ComingSoon>
      )}

      {hasDate && isAfter && (
        <ConferenceFinished>
          <h1>Thank you for your participation</h1>{' '}
        </ConferenceFinished>
      )}

      {/* FILTERs */}
      {(!hasDate || isLive) && (
        <>
          <section>
            <div className="container-fluid">
              {!isLoaded ? (
                <div className="row">
                  <div className="col col-12">
                    <LoadingBox height="40px" />
                  </div>
                </div>
              ) : null}
              {isLoaded ? (
                <div className="row">
                  <div className="col col-12 col-md-6">
                    <ul>
                      <li>
                        {!category ? (
                          <div className="dropdown">
                            <input
                              disabled={
                                isLoaded &&
                                posters?.posters?.result &&
                                posters.posters.result.length === 0
                              }
                              type="search"
                              className="form-control dropdown-toggle"
                              placeholder="Filter Category"
                              value={searchCategories}
                              onChange={(event) => setSearchCategories(event.target.value)}
                            />
                            <Filter />

                            {!isLoadingCategories && categories && categories.length === 0 ? (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span className="dropdown-item">None Found</span>
                              </div>
                            ) : null}

                            {!isLoadingCategories && categories && categories.length > 0 ? (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {categories.map((item: any) => (
                                  <span
                                    key={`${item.id}`}
                                    className="dropdown-item"
                                    // href={`#${item.id}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCategory(item);
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                ))}
                              </div>
                            ) : null}

                            {isLoadingCategories ? (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div className="dropdown-item">
                                  <div className="spinner-border text-secondary" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="btn-group" role="group">
                            <button type="button" className="btn btn-primary">
                              {category?.name}
                            </button>
                            <button
                              onClick={() => setCategory(null)}
                              type="button"
                              className="btn btn-primary"
                            >
                              <X />
                              &nbsp; &nbsp;
                            </button>
                          </div>
                        )}
                      </li>
                      <li>
                        {!keyword ? (
                          <div className="dropdown">
                            <input
                              disabled={
                                (isLoaded &&
                                  posters?.posters?.result &&
                                  posters.posters.result.length === 0) ||
                                (keywords && keywords.length === 0)
                              }
                              type="search"
                              className="form-control dropdown-toggle"
                              placeholder="Filter Keyword"
                              value={searchKeywords}
                              onChange={(event) => setSearchKeywords(event.target.value)}
                            />

                            {!isLoadingKeywords && keywords && keywords.length === 0 ? (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span className="dropdown-item">None Found</span>
                              </div>
                            ) : null}

                            <Tag />

                            {!isLoadingKeywords && keywords && keywords.length > 0 ? (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {keywords.map((item: any) => (
                                  <span
                                    key={`${item.id}`}
                                    className="dropdown-item"
                                    // href={`#${item.id}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setKeyword(item);
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                ))}
                              </div>
                            ) : null}

                            {isLoadingKeywords ? (
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div className="dropdown-item">
                                  <div className="spinner-border text-secondary" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="btn-group" role="group">
                            <button type="button" className="btn btn-primary">
                              {keyword?.name}
                            </button>
                            <button
                              onClick={() => setKeyword(null)}
                              type="button"
                              className="btn btn-primary"
                            >
                              <X />
                              &nbsp; &nbsp;
                            </button>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="col col-6 col-md-3">
                    <div className="dropdown">
                      <input
                        disabled={
                          isLoaded &&
                          posters?.posters?.result &&
                          posters.posters.result.length === 0 &&
                          !search
                        }
                        type="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="form-control"
                        placeholder="Search"
                      />
                      <Search />
                    </div>
                  </div>
                  <div className="col col-6 col-md-3">
                    <button
                      title="Event Info"
                      className="btn btn-dark"
                      onClick={() => setShowModal('info')}
                      style={{ width: isAttendee ? '100%' : '' }}
                    >
                      <Info />
                      <span>Event Info</span>
                    </button>
                    {!isAttendee && !isBreakoutSource && (
                      <button
                        title="Admin panel"
                        className="btn btn-secondary"
                        onClick={() => navigate('/admin')}
                      >
                        <LogIn />
                        <span>Admin panel</span>
                      </button>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </section>
          {/* end FILTERs */}
          {/* MAIN */}
          <main>
            <div className="container-fluid">
              <div className="row">
                {!isLoaded ? (
                  <>
                    <div className="col col-12 col-md-4 m7">
                      <LoadingBox height="200px" margin="0 0 30px 0" />
                    </div>
                    <div className="col col-12 col-md-4">
                      <LoadingBox height="200px" margin="0 0 30px 0" />
                    </div>
                    <div className="col col-12 col-md-4">
                      <LoadingBox height="200px" margin="0 0 30px 0" />
                    </div>
                    <div className="col col-12 col-md-4">
                      <LoadingBox height="200px" margin="0 0 30px 0" />
                    </div>
                    <div className="col col-12 col-md-4">
                      <LoadingBox height="200px" margin="0 0 30px 0" />
                    </div>
                  </>
                ) : null}
                {isLoaded && posters?.posters?.result && posters.posters.result.length === 0 ? (
                  <div className="col col-12">
                    <HomeEmptyStyles>
                      <h2>No Posters {search ? 'Found' : 'Yet'}.</h2>
                      {!search && <p>Please come back later or when the event has started.</p>}
                    </HomeEmptyStyles>
                  </div>
                ) : null}
                {isLoaded && posters?.posters?.result && posters?.posters?.result.length > 0 ? (
                  <>
                    {posters.posters.result.map((item: any, key: number) => (
                      <div key={`posters-${key}`} className="col col-12 col-sm-6 col-xl-3 my-4">
                        <article onClick={() => setShowModal(item)}>
                          <div
                            className="preview"
                            style={{
                              backgroundImage: `${
                                item?.thumbnail ? `url(${item.thumbnail})` : 'none'
                              }`,
                            }}
                          >
                            <img src="/assets/images/poster_placeholder.png" alt="placeholder" />
                          </div>
                          <div className="content">
                            <h4>{item?.title}</h4>
                          </div>
                        </article>
                        <footer>
                          <picture
                            style={{
                              backgroundImage: `${
                                item?.User?.photo ? `url(${encodeURI(item?.User?.photo)})` : 'none'
                              }`,
                            }}
                          ></picture>
                          <p>
                            {item?.User?.first_name}
                            {item?.User?.last_name ? ` ${item?.User?.last_name}` : ''}
                          </p>
                          {item?.User?.location && (
                            <p>
                              <small>{item?.User?.location}</small>
                            </p>
                          )}
                          {item?.talk && <span>{item?.talk}</span>}
                        </footer>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          </main>

          {showModal && (
            <ModalContainer handleClose={() => setShowModal(null)}>
              <ModalAside handleClose={() => setShowModal(null)}>
                {showModal === 'info' && (
                  <aside>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col">
                          <header>
                            {posters?.conference?.photo ? (
                              <img
                                src={posters?.conference?.photo}
                                alt={posters?.conference?.name}
                              />
                            ) : (
                              <span></span>
                            )}
                            {posters?.conference?.name && <h3>{posters.conference.name}</h3>}
                          </header>
                          {posters?.conference?.description && (
                            <section>
                              <p>
                                <strong>Overview</strong>
                              </p>
                              <p>{posters.conference.description}</p>
                            </section>
                          )}
                          {hasDate && (
                            <section>
                              <p>
                                <strong>Schedule</strong>
                              </p>
                              <p>
                                {dayjs.utc(posters?.conference.start).format('MMMM DD, YYYY')} -{' '}
                                {dayjs.utc(posters?.conference.end).format('MMMM DD, YYYY')}
                              </p>
                            </section>
                          )}
                          {(posters?.conference?.url ||
                            posters?.conference?.facebook ||
                            posters?.conference?.twitter ||
                            posters?.conference?.instagram) && (
                            <section>
                              <p>
                                <strong>Links</strong>
                              </p>
                              <ul>
                                {posters?.conference?.url && (
                                  <li>
                                    <a
                                      href={posters.conference.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <button className="btn btn-default website">
                                        <Globe />
                                        {posters.conference.url}
                                        <span>
                                          <ExternalLink />
                                        </span>
                                      </button>
                                    </a>
                                  </li>
                                )}
                                {posters?.conference?.facebook && (
                                  <li>
                                    <a
                                      href={posters.conference.facebook}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <button className="btn btn-default facebook">
                                        <Facebook />
                                        {posters.conference.facebook}
                                        <span>
                                          <ExternalLink />
                                        </span>
                                      </button>
                                    </a>
                                  </li>
                                )}
                                {posters?.conference?.twitter && (
                                  <li>
                                    <a
                                      href={posters.conference.twitter}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <button className="btn btn-default twitter">
                                        <Twitter />
                                        {posters.conference.twitter}
                                        <span>
                                          <ExternalLink />
                                        </span>
                                      </button>
                                    </a>
                                  </li>
                                )}
                                {posters?.conference?.instagram && (
                                  <li>
                                    <a
                                      href={posters.conference.instagram}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <button className="btn btn-default instagram">
                                        <Instagram />
                                        {posters.conference.instagram}
                                        <span>
                                          <ExternalLink />
                                        </span>
                                      </button>
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </section>
                          )}
                        </div>
                      </div>
                    </div>
                  </aside>
                )}
                {showModal && showModal !== 'info' && (
                  <aside>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col">
                          <header className="no-image">
                            <h3>{showModal?.title}</h3>
                          </header>
                          <section className="poster">
                            <div className="author">
                              {showModal?.User?.photo ? (
                                <img
                                  className="thumb"
                                  src={showModal?.User?.photo}
                                  alt={showModal?.User?.name}
                                />
                              ) : (
                                <span></span>
                              )}
                              <p>
                                {showModal?.User?.first_name} {showModal?.User?.last_name}{' '}
                              </p>
                              {showModal?.User?.school && (
                                <p>
                                  <small>{showModal?.User?.school}</small>
                                </p>
                              )}
                            </div>
                            <div
                              className="preview"
                              style={{
                                marginBottom: '20px',
                                backgroundColor: '#efefef',
                                backgroundImage: `${
                                  showModal?.thumbnail ? `url(${showModal.thumbnail})` : 'none'
                                }`,
                              }}
                            >
                              <img src="/assets/images/poster_placeholder.png" alt="placeholder" />
                            </div>
                            <a
                              style={{ color: 'white' }}
                              href={`${window.location.origin}/posters/${showModal.id}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-primary btn-block"
                            >
                              Open Poster
                            </a>
                          </section>
                          {showModal?.overview && (
                            <section>
                              <p>
                                <strong>Overview</strong>
                              </p>
                              <p>{showModal.overview}</p>
                            </section>
                          )}
                          {showModal?.abstract && (
                            <section>
                              <p>
                                <strong>Abstract</strong>
                              </p>
                              <p>{showModal.abstract}</p>
                            </section>
                          )}
                        </div>
                      </div>
                    </div>
                  </aside>
                )}
              </ModalAside>
            </ModalContainer>
          )}
        </>
      )}

      {/* end MAIN */}
    </HomePageStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default HomePage;
