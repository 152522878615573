// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Components
import Breadcrumbs from '../Breadcrumbs';
import Table from '../Table';
import LoadingBox from '../../components/LoadingBox';
import { IconCarrotDown, IconCarrotUp } from '../../components/Icons';

// Styles
import { DataListStyles, DataListActionsStyles, DataListPaginationStyles } from './styles';

// Types
interface DataListTypes {
  columns?: { [key: string]: any };
  title?: string;
  subtitle?: string;
  actions?: any;
  data?: Array<any>;
  hasPagination?: boolean;
  empty?: any;
  rowClick?: (data: any) => void;
  breadcrumbs?: Array<any>;
  prevEnabled?: boolean;
  nextEnabled?: boolean;
  paginationTotal?: string | number;
  paginationShowing?: string | number;
  handlePrev?: () => void;
  handleNext?: () => void;
  skip?: number;
  take?: number;
  isLoading?: boolean;
  order?: string;
  sortBy?: string;
  customContent?: ReactNode;
  handleSort?: (sort: { sortBy: string; orderBy: string }) => void;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const DataList = ({
  prevEnabled = false,
  nextEnabled = false,
  breadcrumbs = [],
  title,
  subtitle,
  actions = [],
  columns = {},
  data = [],
  hasPagination = true,
  empty = <>No Results</>,
  rowClick,
  paginationTotal = 0,
  paginationShowing = 0,
  handlePrev,
  handleNext,
  isLoading = false,
  order = 'asc',
  sortBy,
  handleSort,
  customContent,
}: DataListTypes) => {
  // Functions
  /**
   *
   * @param rowData
   */
  const onClickRow = (rowData: any) => () => {
    if (rowClick) {
      rowClick(rowData);
    }
  };

  /**
   *
   */
  const onClickPrev = () => {
    if (handlePrev) {
      handlePrev();
    }
  };

  /**
   *
   */
  const onClickNext = () => {
    if (handleNext) {
      handleNext();
    }
  };

  /**
   *
   * @param field
   */
  const onClickSort =
    (sortBy: string, orderBy: string) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      if (handleSort) {
        handleSort({
          sortBy,
          orderBy,
        });
      }
    };

  return (
    <DataListStyles>
      {isLoading ? <LoadingBox height="30px" width="100%" margin="0px 0px 20px 0px" /> : null}
      {!isLoading && breadcrumbs && breadcrumbs.length > 0 ? (
        <Breadcrumbs data={breadcrumbs} />
      ) : null}

      <header>
        {actions?.length > 0 ? (
          <DataListActionsStyles>
            {isLoading ? <LoadingBox height="38px" width="92px" margin="0px 0px 30px 0px" /> : null}
            {!isLoading &&
              actions.map((action: ReactNode, key: number) => (
                <span key={`actions-${key}`}>{action}</span>
              ))}
          </DataListActionsStyles>
        ) : null}
        {isLoading ? <LoadingBox height="38px" width="300px" margin="0px 0px 30px 0px" /> : null}
        {title && !isLoading ? (
          <h3>
            {title} {subtitle ? <span className="text-muted">{subtitle}</span> : null}
          </h3>
        ) : null}
      </header>
      <main>
        {isLoading ? <LoadingBox height="49px" width="100%" margin="0px 0px 12px 0px" /> : null}
        {isLoading ? <LoadingBox height="133px" width="100%" margin="0px 0px 16px 0px" /> : null}
        {!isLoading
          ? customContent || (
              <Table>
                <div className="table-responsive">
                  <table
                    className={`table align-middle ${
                      data && data?.length > 0 ? 'table-hover' : ''
                    }`}
                  >
                    {Object.keys(columns).length > 0 ? (
                      <thead>
                        <tr>
                          {Object.keys(columns).map((column: string, key: number) => (
                            <th
                              key={`thead-th-${key}`}
                              className={`${columns?.[column]?.class ?? ''}`}
                              style={{ width: columns?.[column]?.width || 'auto' }}
                            >
                              {columns?.[column]?.filter ? (
                                <a
                                  href={`#${column}`}
                                  onClick={onClickSort(column, order === 'asc' ? 'desc' : 'asc')}
                                >
                                  {columns?.[column]?.display ?? column}{' '}
                                  {sortBy === column ? (
                                    <span>
                                      {order === 'desc' ? <IconCarrotUp /> : <IconCarrotDown />}
                                    </span>
                                  ) : null}
                                </a>
                              ) : (
                                columns?.[column]?.display ?? column
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                    ) : null}
                    {data && data.length > 0 ? (
                      <tbody>
                        {data.map((row: any, key: number) => (
                          <tr key={`data-list-row-${key}`} onClick={onClickRow(row)}>
                            {Object.keys(columns).map((column: string, colKey: number) => (
                              <td
                                key={`tbody-td-${key}-${colKey}-${column}`}
                                className={`${columns?.[column]?.class ?? ''}`}
                              >
                                {(columns?.[column]?.render && columns?.[column]?.render(row)) ||
                                  row?.[column] ||
                                  ''}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={Object.keys(columns)?.length}>{empty}</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Table>
            )
          : null}
        {hasPagination ? (
          <DataListPaginationStyles aria-label="nDataListPaginationStylesigation">
            <ul className="pagination justify-content-between">
              <li className={`page-item ${!prevEnabled ? 'disabled' : ''}`}>
                {isLoading ? <LoadingBox height="38px" width="90px" /> : null}
                {!isLoading ? (
                  <button onClick={onClickPrev} className="page-link">
                    Previous
                  </button>
                ) : null}
              </li>
              <li className="page-item">
                {!isLoading ? (
                  <span>
                    {paginationShowing} of {paginationTotal}
                  </span>
                ) : null}
              </li>
              <li className={`page-item ${!nextEnabled ? 'disabled' : ''}`}>
                {isLoading ? <LoadingBox height="38px" width="90px" /> : null}
                {!isLoading ? (
                  <button onClick={onClickNext} className="page-link">
                    Next
                  </button>
                ) : null}
              </li>
            </ul>
          </DataListPaginationStyles>
        ) : null}
      </main>
    </DataListStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default DataList;
