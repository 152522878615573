// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Providers
import { useConference } from '../../providers/Conference';

// Components
import FullPageLoader from '../../components/FullPageLoader';

// Style Components
import { PosterPageStyles } from './styles';

// Templates
import Minimal from '../../templates/minimal';
import Dark from '../../templates/dark';
import Single from '../../templates/single';
import { Helmet } from 'react-helmet';
import { sanitizeUser } from '../../utils/helpers';
import { useAuth } from '../../providers/Auth';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const PosterPage = () => {
  // State / Props
  const { isLoaded, poster, getPoster } = useConference();
  const { id } = useParams<{ id: string }>();
  const { auth } = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const qrCodeUrl = React.useMemo(() => `${window.location.origin}/posters/${id}`, []);

  // Hooks
  React.useEffect(() => {
    if (!id) return;
    const override = params.get('override');
    const mode = params.get('mode');
    getPoster(id, override, mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Render
  return (
    <PosterPageStyles>
      <Helmet>
        <title>
          {isLoaded
            ? `Poster | ${poster?.poster?.talk ? poster.poster.talk + ' | ' : ''}${
                poster?.poster?.title ? `${poster?.poster?.title} | ` : ''
              } ${poster?.conference?.name ? poster.conference.name : ''}`
            : ''}
        </title>
        {poster?.poster?.title && <meta name="title" content={`${poster?.poster?.title}`} />}
        <meta
          name="description"
          content={`See ${poster?.poster?.title || 'this poster'} on BetterPoster`}
        />

        <meta property="og:type" content="website" />
        {auth?.domain && (
          <meta
            property="og:url"
            content={`${window.location.protocol}//${auth.domain}.${window.location.host
              .split('.')
              .slice(1)
              .join('.')}/posters/${poster?.poster?.id}`}
          />
        )}
        <meta property="og:title" content={`${poster?.poster?.title}`} />
        <meta
          property="og:description"
          content={`See ${poster?.poster?.title || 'this poster'} on BetterPoster`}
        />
        <meta property="og:image" content={poster?.poster?.thumbnail || ''} />

        <meta property="twitter:card" content="summary_large_image" />
        {auth?.domain && (
          <meta
            property="twitter:url"
            content={`${window.location.protocol}//${auth.domain}.${window.location.host
              .split('.')
              .slice(1)
              .join('.')}/posters/${poster?.poster?.id}`}
          />
        )}
        <meta property="twitter:title" content={`${poster?.poster?.title}`} />
        <meta
          property="twitter:description"
          content={`See ${poster?.poster?.title || 'this poster'} on BetterPoster`}
        />
        <meta property="twitter:image" content={poster?.poster?.thumbnail || ''}></meta>
      </Helmet>
      {!isLoaded ? <FullPageLoader /> : null}
      {isLoaded && poster?.poster?.Template?.name === 'Minimal' ? (
        <Minimal
          url={qrCodeUrl}
          backgroundColor={
            poster?.poster?.backgroundColor &&
            [4, 7].includes(poster?.poster?.backgroundColor.length)
              ? poster?.poster?.backgroundColor
              : undefined
          }
          bodyFontColor={
            poster?.poster?.bodyFontColor && [4, 7].includes(poster?.poster?.bodyFontColor.length)
              ? poster?.poster?.bodyFontColor
              : undefined
          }
          titleFontColor={
            poster?.poster?.titleFontColor && [4, 7].includes(poster?.poster?.titleFontColor.length)
              ? poster?.poster?.titleFontColor
              : undefined
          }
          cardColor={
            poster?.poster?.cardColor && [4, 7].includes(poster?.poster?.cardColor.length)
              ? poster?.poster?.cardColor
              : undefined
          }
          titlebarColor={
            poster?.poster?.titlebarColor && [4, 7].includes(poster?.poster?.titlebarColor.length)
              ? poster?.poster?.titlebarColor
              : undefined
          }
          homeActive={true}
          fontHeader={poster?.poster?.fontHeader}
          fontBody={poster?.poster?.fontBody}
          backgroundOpacity={
            isNaN(poster?.poster?.backgroundOpacity) ? 0 : Number(poster?.poster?.backgroundOpacity)
          }
          layout={poster?.poster?.Layout}
          title={poster?.poster?.title}
          altTitle={poster?.poster?.altTitle ?? ''}
          subtitle={poster?.poster?.subtitle}
          background={poster?.poster?.background}
          preview={true}
          cards={poster?.cards}
          user={sanitizeUser(poster?.poster?.User)}
        />
      ) : null}
      {isLoaded && poster?.poster?.Template?.name === 'Bold' ? (
        <Dark
          url={qrCodeUrl}
          backgroundColor={
            poster?.poster?.backgroundColor &&
            [4, 7].includes(poster?.poster?.backgroundColor.length)
              ? poster?.poster?.backgroundColor
              : undefined
          }
          bodyFontColor={
            poster?.poster?.bodyFontColor && [4, 7].includes(poster?.poster?.bodyFontColor.length)
              ? poster?.poster?.bodyFontColor
              : undefined
          }
          titleFontColor={
            poster?.poster?.titleFontColor && [4, 7].includes(poster?.poster?.titleFontColor.length)
              ? poster?.poster?.titleFontColor
              : undefined
          }
          cardColor={
            poster?.poster?.cardColor && [4, 7].includes(poster?.poster?.cardColor.length)
              ? poster?.poster?.cardColor
              : undefined
          }
          titlebarColor={
            poster?.poster?.titlebarColor && [4, 7].includes(poster?.poster?.titlebarColor.length)
              ? poster?.poster?.titlebarColor
              : undefined
          }
          homeActive={true}
          fontHeader={poster?.poster?.fontHeader}
          fontBody={poster?.poster?.fontBody}
          backgroundOpacity={
            isNaN(poster?.poster?.backgroundOpacity) ? 0 : Number(poster?.poster?.backgroundOpacity)
          }
          layout={poster?.poster?.Layout}
          title={poster?.poster?.title}
          altTitle={poster?.poster?.altTitle ?? ''}
          subtitle={poster?.poster?.subtitle}
          background={poster?.poster?.background}
          preview={true}
          cards={poster?.cards}
          user={sanitizeUser(poster?.poster?.User)}
        />
      ) : null}

      {isLoaded && poster?.poster?.Template?.name === 'Previously created poster' ? (
        <Single
          url={qrCodeUrl}
          background={poster?.poster?.background}
          user={sanitizeUser(poster?.poster?.User)}
          backgroundColor={
            poster?.poster?.backgroundColor &&
            [4, 7].includes(poster?.poster?.backgroundColor.length)
              ? poster?.poster?.backgroundColor
              : ''
          }
          bodyFontColor={
            poster?.poster?.bodyFontColor && [4, 7].includes(poster?.poster?.bodyFontColor.length)
              ? poster?.poster?.bodyFontColor
              : ''
          }
          titleFontColor={
            poster?.poster?.titleFontColor && [4, 7].includes(poster?.poster?.titleFontColor.length)
              ? poster?.poster?.titleFontColor
              : ''
          }
          homeActive
        />
      ) : (
        ''
      )}
    </PosterPageStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default PosterPage;
