// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LogOut, Settings, Sliders, ArrowLeft, ArrowRight, X, Grid } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import FontPicker from 'font-picker-react';

// Helpers
import { CONST } from '../../utils/helpers';

// Providers
import { useFetch } from '../../providers/Fetch';
import { usePosterEditor } from '../../providers/PosterEditor';

// Components
import Navbar from '../Navbar';

// Styles
import {
  PosterSidebarStyles,
  PosterKeywordContainer,
  PosterSidebarContentStyles,
  PosterSidebarCollapseButtonStyles,
} from './styles';
import ColorPicker from '../ColorPicker';
import { ImageInput } from '../ImageInput';
import { AxiosProgressEvent } from 'axios';

// Types
type Layout = {
  id: string;
  name: string;
};

interface PosterSidebarTypes {
  data?: any;
  keywords?: any;
  setKeywords?: any;
  templates?: any;
  setData?: (data: any) => void;
  setEdit?: (data: any) => void;
  setHasMadeChanges?: (changes: boolean) => void;
  layouts?: Layout[];
  hasMadeChanges?: boolean;
}

type PosterColors = {
  titleFontColor?: string;
  bodyFontColor?: string;
  backgroundColor?: string;
  cardColor?: string;
  titlebarColor?: string;
};

// MAIN COMPONENT
// ------------------------------------------------------------
const PosterSidebar = ({
  data,
  templates,
  layouts = [],
  setData,
  setEdit,
  keywords,
  setKeywords,
  setHasMadeChanges,
  hasMadeChanges = false,
}: PosterSidebarTypes) => {
  // State / Props
  const { options, poster, setPoster } = usePosterEditor();
  const inputRefInstitution = React.useRef<any>(null);
  const inputRefThumbnail = React.useRef<any>(null);
  const inputRefBackground = React.useRef<any>(null);
  const [keyword, setKeyword] = React.useState<any>();
  const { fetch } = useFetch();
  const navigate = useNavigate();

  // Functions
  /**
   *
   */
  const onClickCollapse = () => {
    setPoster({
      ...poster,
      show: !poster.show,
    });
  };

  /**
   *
   * @param option
   */
  const onClickSection = (option: string) => () => {
    setPoster({
      ...poster,
      current: option,
      show: poster.current === option ? !poster.show : true,
    });
  };

  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setData && setHasMadeChanges) {
      setHasMadeChanges(true);
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    }
  };

  /**
   *
   * @param value
   */
  const onChangeColorPicker = (colors: PosterColors) => {
    if (setData && setHasMadeChanges) {
      setHasMadeChanges(true);
      setData({
        ...data,
        ...colors,
      });
    }
  };

  /**
   *
   * @param event
   */
  const onChangeInputKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setKeywords && setHasMadeChanges) {
      setHasMadeChanges(true);
      setKeyword(event.target.value);
    }
  };

  /**
   *
   * @param event
   */
  const onKeyPressKeyword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!keywords.find((k: any) => k.name === keyword)) {
        setKeywords([
          ...keywords,
          {
            id: uuidv4(),
            name: keyword,
          },
        ]);
      }
      setKeyword('');
    }
  };

  /**
   *
   * @param keywords
   * @returns
   */
  const onClickRemoveKeyword = (keyword: any) => () => {
    const findIndex = keywords.findIndex((key: any) => key.id === keyword.id);
    if (findIndex > -1) {
      setKeywords([...keywords.slice(0, findIndex), ...keywords.slice(findIndex + 1)]);
    }
  };

  /**
   *
   * @param event
   */
  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (setData && setHasMadeChanges) {
      setHasMadeChanges(true);
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    }
  };

  /**
   *
   * @param event
   */
  const onClickLeave = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (
      !hasMadeChanges ||
      (hasMadeChanges && window.confirm('Are you sure leave and abandon your changes?'))
    ) {
      navigate(`${CONST.adminRoute}/posters`);
    }
  };

  /**
   *
   * @param event
   */
  const onChangeFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
  ) => {
    // setIsLoading(true);
    const formData = new FormData();
    formData.append('files', event.target.files?.[0] as any);
    fetch(
      {
        url: `/uploads`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress,
      },
      (response: any) => {
        if (inputRefThumbnail.current) inputRefThumbnail.current.value = null;
        if (inputRefBackground.current) inputRefBackground.current.value = null;
        if (inputRefInstitution.current) inputRefInstitution.current.value = null;

        if (setData && setHasMadeChanges) {
          setHasMadeChanges(true);
          setData({
            ...data,
            [event.target.name]: response?.data?.data?.[0]?.key ?? '',
          });
        }
        // updateEntry({ ...input, photo: response?.data?.data?.[0]?.key });
      },
      () => {
        inputRefThumbnail.current.value = null;
        inputRefBackground.current.value = null;
        inputRefInstitution.current.value = null;
        // setIsLoading(false);
      },
    );
  };

  // Render
  return (
    <PosterSidebarStyles>
      <Navbar>
        {poster.show ? <p className="text-muted ml-3">Poster Settings</p> : null}
        <PosterSidebarCollapseButtonStyles onClick={onClickCollapse}>
          {poster.show ? <ArrowLeft /> : <ArrowRight />}
        </PosterSidebarCollapseButtonStyles>
      </Navbar>
      <PosterSidebarContentStyles show={poster.show}>
        <ul className="first-step">
          <li className={`${poster.current === options[0] ? 'selected' : null}`}>
            <button title="Poster Settings" onClick={onClickSection(options[0])}>
              <span>
                <Settings />
              </span>
            </button>
          </li>
          <li className={`${poster.current === options[2] ? 'selected' : null}`}>
            <button title="Layout Settings" onClick={onClickSection(options[2])}>
              <span>
                <Grid />
              </span>
            </button>
          </li>
          <li className={`${poster.current === options[1] ? 'selected' : null}`}>
            <button title="Layout Settings" onClick={onClickSection(options[1])}>
              <span>
                <Sliders />
              </span>
            </button>
          </li>

          {/* <li className={`${poster.current === options[1] ? 'selected' : null}`}>
            <button title="Layout Settings" onClick={onClickSection(options[1])}>
              <span>
                <Sliders />
              </span>
            </button>
          </li>
          <li className={`${poster.current === options[2] ? 'selected' : null}`}>
            <button title="Biography" onClick={onClickSection(options[2])}>
              <span>
                <User />
              </span>
            </button>
          </li> */}
        </ul>

        <div className="space"></div>

        <div className="logout-btn">
          <Link to={`#${CONST.adminRoute}/posters`} title="Exit Editor" onClick={onClickLeave}>
            <span>
              <LogOut style={{ transform: 'scale(-1,1)' }} />
            </span>
          </Link>
        </div>
        <aside>
          {poster.current === options[0] ? (
            <section>
              <form>
                <div className="form-group">
                  <label htmlFor="title">Poster Title</label>
                  <input
                    disabled={false}
                    onChange={onChangeInput}
                    id="title"
                    value={data?.title ?? ''}
                    type="text"
                    name="title"
                    className="form-control"
                    placeholder="ex: Exploring The Universe"
                  />
                </div>
                {['Minimal', 'Bold'].includes(
                  templates?.find((template: any) => template.id === data?.templateId)?.name ?? '',
                ) && (
                  <>
                    <div className="form-group">
                      <label htmlFor="subtitle">Poster Subtitle</label>
                      <input
                        disabled={false}
                        onChange={onChangeInput}
                        id="subtitle"
                        value={data?.subtitle ?? ''}
                        type="text"
                        name="subtitle"
                        className="form-control"
                        placeholder="ex: What this means for you and me"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="altTitle">Poster Additional Title</label>
                      <input
                        disabled={false}
                        onChange={onChangeInput}
                        id="altTitle"
                        value={data?.altTitle ?? ''}
                        type="text"
                        name="altTitle"
                        className="form-control"
                        placeholder="ex: By John Smith"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="overview">Overview</label>
                      <textarea
                        onChange={onChangeTextarea}
                        rows={6}
                        name="overview"
                        value={data?.overview ?? ''}
                        disabled={false}
                        id="overview"
                        className="form-control"
                        placeholder="ex: Overview describing my poster..."
                      />
                    </div>
                  </>
                )}

                <div className="form-group">
                  <label htmlFor="abstract">Abstract</label>
                  <textarea
                    onChange={onChangeTextarea}
                    rows={6}
                    name="abstract"
                    value={data?.abstract ?? ''}
                    disabled={true}
                    id="abstract"
                    className="form-control"
                    placeholder="ex: Abstract describing my poster..."
                  />
                </div>

                {['Minimal', 'Bold'].includes(
                  templates?.find((template: any) => template.id === data?.templateId)?.name ?? '',
                ) && (
                  <>
                    <div className="form-group">
                      <ColorPicker
                        name="titlebarColor"
                        label="Title bar color"
                        color={data && data.titlebarColor ? data.titlebarColor : ''}
                        setColor={(color: string) => onChangeColorPicker({ titlebarColor: color })}
                      />
                    </div>
                    <div className="form-group">
                      <ColorPicker
                        name="cardColor"
                        label="Card color"
                        color={data && data.cardColor ? data.cardColor : ''}
                        setColor={(color: string) => onChangeColorPicker({ cardColor: color })}
                      />
                    </div>
                    <div className="form-group">
                      <ColorPicker
                        name="titleFontColor"
                        label="Title font color"
                        color={data && data.titleFontColor ? data.titleFontColor : ''}
                        setColor={(color: string) => onChangeColorPicker({ titleFontColor: color })}
                      />
                    </div>

                    <div className="form-group">
                      <ColorPicker
                        name="bodyFontColor"
                        label="Body font color"
                        color={data && data.bodyFontColor ? data.bodyFontColor : ''}
                        setColor={(color: string) => onChangeColorPicker({ bodyFontColor: color })}
                      />
                    </div>

                    <div className="form-group">
                      <ColorPicker
                        name="backgroundColor"
                        label="Background color"
                        color={data && data.backgroundColor ? data.backgroundColor : ''}
                        setColor={(color: string) =>
                          onChangeColorPicker({ backgroundColor: color })
                        }
                      />
                    </div>
                  </>
                )}

                {/* <div className="btn-group" role="group">
                        <button type="button" className="btn btn-primary">
                          {category?.name}
                        </button>
                        <button onClick={() => setCategory(null)} type="button" className="btn btn-primary">
                          <X />
                          &nbsp; &nbsp;
                        </button>
                      </div> */}

                <div className="form-group">
                  <label htmlFor="keywords">Keywords</label>
                  <PosterKeywordContainer>
                    <input
                      type="text"
                      placeholder={keywords?.length >= 6 ? 'Maximum 6 keywords' : 'ex: Magnetism'}
                      className="form-control"
                      value={keyword}
                      onChange={onChangeInputKeyword}
                      onKeyPress={onKeyPressKeyword}
                      disabled={keywords?.length >= 6}
                    />
                    <small>Press enter to add a keyword</small>
                    {keywords && keywords.length > 0 ? (
                      <dl>
                        {keywords.map((keyword: any) => (
                          <dt key={`${keyword.id}`}>
                            <div className="btn-group" role="group">
                              <button type="button" className="btn btn-sm btn-primary">
                                {keyword?.name}
                              </button>
                              <button
                                onClick={onClickRemoveKeyword(keyword)}
                                type="button"
                                className="btn btn-sm btn-primary"
                              >
                                <X />
                              </button>
                            </div>
                          </dt>
                        ))}
                      </dl>
                    ) : null}
                  </PosterKeywordContainer>
                </div>
              </form>
            </section>
          ) : null}
          {poster.current === options[1] ? (
            <section>
              <form>
                {['Minimal', 'Bold'].includes(
                  templates?.find((template: any) => template.id === data?.templateId)?.name ?? '',
                ) && (
                  <>
                    <div className="form-group">
                      <label htmlFor="font-picker">Heading Typography</label>
                      <FontPicker
                        pickerId="headingTypography"
                        apiKey={window.REACT_APP_GOOGLE_FONTS_KEY}
                        activeFontFamily={data.fontHeader || undefined}
                        onChange={(nextFont: any) =>
                          setData?.({ ...data, fontHeader: nextFont.family })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="font-picker">Body Typography</label>
                      <FontPicker
                        pickerId="bodyTypography"
                        apiKey={window.REACT_APP_GOOGLE_FONTS_KEY}
                        activeFontFamily={data.fontBody || undefined}
                        onChange={(nextFont: any) =>
                          setData?.({ ...data, fontBody: nextFont.family })
                        }
                      />
                    </div>
                  </>
                )}

                {['Minimal', 'Bold'].includes(
                  templates?.find((template: any) => template.id === data?.templateId)?.name ?? '',
                ) &&
                  layouts &&
                  layouts.length > 0 && (
                    <div className="form-group">
                      <label htmlFor="layoutId">Layouts</label>

                      <div className="layouts">
                        {layouts.map((item) => (
                          <dl
                            onClick={() => {
                              if (setData && setHasMadeChanges && setEdit) {
                                setHasMadeChanges(true);
                                setData({ ...data, layoutId: item.id });
                                setEdit(null);
                                const template = document.getElementById('template');
                                template?.removeAttribute('style');
                              }
                            }}
                            key={`${item.id}`}
                            className={`layout ${item.name.toLowerCase().replace(' ', '-')} ${
                              data?.layoutId === item.id ? 'selected' : ''
                            }`}
                          >
                            {item.name
                              .replace('layout ', '')
                              .split('')
                              .map((n, k) => {
                                if (n === '1') return <dd key={`${item.id}-${k}`}></dd>;
                                return (
                                  <>
                                    {Array.from({ length: Number(n) }, (_, index) => index + 1).map(
                                      (t) => (
                                        <dd key={`${item.id}-${k}-${t}`}></dd>
                                      ),
                                    )}
                                  </>
                                );
                              })}
                          </dl>
                        ))}
                      </div>
                    </div>
                  )}

                {templates && templates.length > 0 && (
                  <div className="form-group">
                    <label htmlFor="tempalteId">Templates</label>
                    <dl className="templates">
                      {templates.map((item: any) => (
                        <dd
                          onClick={() => {
                            if (setData && setHasMadeChanges && setEdit) {
                              setHasMadeChanges(true);
                              setData({
                                ...data,
                                templateId: item.id,
                              });
                              setEdit(null);
                              const template = document.getElementById('template');
                              template?.removeAttribute('style');
                            }
                          }}
                          key={item.id}
                          className={`templates templates-${item.name
                            .toLowerCase()
                            .replace(/\s+/g, '-')} ${
                            data?.templateId === item.id ? 'selected' : ''
                          }`}
                        >
                          {item.name === 'Previously created poster' && (
                            <div className="bg-image">
                              <span className="header" />
                              <span className="first" />
                              <span className="second" />
                              <span className="third" />
                              <span className="fourth" />
                              <span className="fifth" />
                              <span className="sixth" />
                            </div>
                          )}
                          <span>{item.name}</span>
                        </dd>
                      ))}
                    </dl>
                  </div>
                )}
                {['Previously created poster'].includes(
                  templates?.find((template: any) => template.id === data?.templateId)?.name ?? '',
                ) && (
                  <form>
                    <ImageInput
                      formLabel="Poster Image"
                      id="poster-background"
                      name="background"
                      onChange={onChangeFile}
                      ref={inputRefBackground}
                      disabled={false}
                      src={data?.background}
                      removeImage={() => {
                        if (setData && setHasMadeChanges) {
                          setHasMadeChanges(true);
                          setData({ ...data, background: null });
                        }
                      }}
                      sizeLabel="20MB"
                      recommendedDimension="1920x1080"
                      customPreview={(children) => (
                        <div
                          className="preview"
                          style={{
                            borderRadius: '0.25rem',
                            display: 'block',
                            width: '100%',
                            height: '120px',
                            backgroundImage: `url('${encodeURI(data.background)}')`,
                          }}
                        >
                          {children}
                        </div>
                      )}
                    />
                    {['Minimal', 'Bold'].includes(
                      templates?.find((template: any) => template.id === data?.templateId)?.name ??
                        '',
                    ) && (
                      <div className="image-form-wrapper">
                        <div className="form-group">
                          <label htmlFor="backgroundOpacity">Background Opacity</label>
                          <input
                            type="hidden"
                            name="backgroundOpacity"
                            value={data?.backgroundOpacity}
                          />
                          <div className="btn-group">
                            <button
                              type="button"
                              onClick={() => {
                                if (setData && setHasMadeChanges) {
                                  setHasMadeChanges(true);
                                  setData({ ...data, backgroundOpacity: 0 });
                                }
                              }}
                              className={`btn btn-${
                                data?.backgroundOpacity !== 0 ? 'outline-' : ''
                              }secondary`}
                            >
                              0%
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                if (setData && setHasMadeChanges) {
                                  setHasMadeChanges(true);
                                  setData({ ...data, backgroundOpacity: 15 });
                                }
                              }}
                              className={`btn btn-${
                                data?.backgroundOpacity !== 15 ? 'outline-' : ''
                              }secondary`}
                            >
                              15%
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                if (setData && setHasMadeChanges) {
                                  setHasMadeChanges(true);
                                  setData({ ...data, backgroundOpacity: 30 });
                                }
                              }}
                              className={`btn btn-${
                                data?.backgroundOpacity !== 30 ? 'outline-' : ''
                              }secondary`}
                            >
                              30%
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                if (setData && setHasMadeChanges) {
                                  setHasMadeChanges(true);
                                  setData({ ...data, backgroundOpacity: 50 });
                                }
                              }}
                              className={`btn btn-${
                                data?.backgroundOpacity !== 50 ? 'outline-' : ''
                              }secondary`}
                            >
                              50%
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                )}
              </form>
            </section>
          ) : null}
          {poster.current === options[2] ? (
            <section>
              <form>
                <ImageInput
                  formLabel="Poster Thumbnail"
                  id="poster-thumbnail"
                  name="thumbnail"
                  onChange={onChangeFile}
                  ref={inputRefThumbnail}
                  disabled={false}
                  src={data?.thumbnail}
                  removeImage={() => {
                    if (setData && setHasMadeChanges) {
                      setHasMadeChanges(true);
                      setData({ ...data, thumbnail: null });
                    }
                  }}
                  sizeLabel="20MB"
                  recommendedDimension="1920x1080"
                />

                {!['Previously created poster'].includes(
                  templates?.find((template: any) => template.id === data?.templateId)?.name ?? '',
                ) && (
                  <>
                    <ImageInput
                      formLabel="Background Image"
                      id="poster-background"
                      name="background"
                      onChange={onChangeFile}
                      ref={inputRefBackground}
                      disabled={false}
                      src={data?.background}
                      removeImage={() => {
                        if (setData && setHasMadeChanges) {
                          setHasMadeChanges(true);
                          setData({ ...data, background: null });
                        }
                      }}
                      sizeLabel="20MB"
                      recommendedDimension="1920x1080"
                      customPreview={(children) => (
                        <div
                          className="preview"
                          style={{
                            borderRadius: '0.25rem',
                            display: 'block',
                            width: '100%',
                            height: '120px',
                            backgroundImage: `url('${encodeURI(data.background)}')`,
                          }}
                        >
                          {children}
                        </div>
                      )}
                    />
                    <div className="image-form-wrapper">
                      <div className="form-group">
                        <label htmlFor="backgroundOpacity">Background Opacity</label>
                        <input
                          type="hidden"
                          name="backgroundOpacity"
                          value={data?.backgroundOpacity}
                        />
                        <div className="btn-group">
                          <button
                            type="button"
                            onClick={() => {
                              if (setData && setHasMadeChanges) {
                                setHasMadeChanges(true);
                                setData({ ...data, backgroundOpacity: 0 });
                              }
                            }}
                            className={`btn btn-${
                              data?.backgroundOpacity !== 0 ? 'outline-' : ''
                            }secondary`}
                          >
                            0%
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if (setData && setHasMadeChanges) {
                                setHasMadeChanges(true);
                                setData({ ...data, backgroundOpacity: 15 });
                              }
                            }}
                            className={`btn btn-${
                              data?.backgroundOpacity !== 15 ? 'outline-' : ''
                            }secondary`}
                          >
                            15%
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if (setData && setHasMadeChanges) {
                                setHasMadeChanges(true);
                                setData({ ...data, backgroundOpacity: 30 });
                              }
                            }}
                            className={`btn btn-${
                              data?.backgroundOpacity !== 30 ? 'outline-' : ''
                            }secondary`}
                          >
                            30%
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if (setData && setHasMadeChanges) {
                                setHasMadeChanges(true);
                                setData({ ...data, backgroundOpacity: 50 });
                              }
                            }}
                            className={`btn btn-${
                              data?.backgroundOpacity !== 50 ? 'outline-' : ''
                            }secondary`}
                          >
                            50%
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </section>
          ) : null}
        </aside>
      </PosterSidebarContentStyles>
    </PosterSidebarStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default PosterSidebar;
