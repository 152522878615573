// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const NavbarStyles = styled.nav`
  background: ${Colors.darkGrey};
  padding: 0;
  margin: 0;
  height: 54px;
  line-height: 54px;

  .container,
  .container-fluid {
    .row {
      width: calc(100% + 30px);
    }
  }
`;
