import styled from 'styled-components';
import Colors from '../../styles/Colors';

const modifiers = {
  getValidColor: (color: string | undefined) => {
    return color && [4, 7].includes(color.length) ? color : '';
  },
};

export const ColorPickerContainer = styled.div`
  .content {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    gap: 10px;

    input {
      width: calc(100% - 80px);
    }
  }
`;

export const PreviewCircle = styled.span`
  background: ${({ color }) => modifiers.getValidColor(color)};
  border-radius: 50%;
  outline: 1px solid #000a;
  width: 32px;
  height: 32px;
`;

export const ColorPickerToggle = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #000a;
  background-color: transparent;
  transition: 0.3s linear;
  padding: 0;

  svg {
    fill: #000a;
    height: 20px;
    width: 20px;
  }

  &:hover {
    border-color: ${Colors.blue};
  }

  &:focus {
    border-color: ${Colors.blue};
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    outline: none;
  }
`;

export const ErrorMessage = styled.small`
  display: block;
  color: ${Colors.red};
`;
