// IMPORTS
// ------------------------------------------------------------
import React from 'react';

// Styles
import { LoadingBoxStyles } from './styles';

// Types
interface LoadingBoxTypes {
  width?: string;
  height?: string;
  margin?: string;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const LoadingBox = ({ width, height, margin }: LoadingBoxTypes) => (
  <LoadingBoxStyles width={width} height={height} margin={margin} />
);

// EXPORTS
// ------------------------------------------------------------
export default LoadingBox;
