// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';
import { X } from 'react-feather';

// Styled
import { ModalAsideStyles } from './styles';

// Types
interface ModalAsideTypes {
  children?: ReactNode;
  handleClose?: () => void;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const ModalAside = ({ children, handleClose }: ModalAsideTypes) => {
  // State / Props
  const buttonRef = React.useRef<HTMLButtonElement | undefined | null>();
  // Functions
  /**
   *
   */
  const onClickClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  // Hooks
  React.useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  // Render
  return (
    <ModalAsideStyles>
      <button ref={buttonRef as any} onClick={onClickClose}>
        <X />
      </button>
      <main>{children}</main>
    </ModalAsideStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default ModalAside;
