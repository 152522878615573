// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// Styles Components
import { NavbarStyles } from '../Navbar/styles';

// Types
interface SidebarContentStylesTypes {
  show?: boolean;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const SidebarContentStyles = styled.div<SidebarContentStylesTypes>`
  width: 100%;
  height: calc(100% - 54px);
  border-right: 1px solid ${Colors.borderGrey};
  position: relative;

  ul {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: ${({ show }) => (show ? `calc(100% - 165px)` : `calc(100% - 54px)`)};
    overflow-y: scroll;
    li {
      height: 54px;
      line-height: 54px;
      border-bottom: 1px solid ${Colors.borderGrey};
      a,
      button {
        color: ${Colors.grey};
        display: block;
        width: 100%;
        height: 54px;
        line-height: 54px;
        padding: 0 20px;
        text-align: left;
        border: none;
        margin: 0;
        font-size: 14px;
        text-decoration: none;
        transition: all 250ms ease-in-out 0s;

        svg {
          position: absolute;
          right: 20px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          transition: all 250ms ease-in-out 0s;
        }

        span {
          svg {
            color: ${Colors.iconGrey};
            position: static;
            width: 16px;
            margin-right: 6px;
          }
        }

        &:hover {
          color: ${Colors.blue};
          svg {
            color: ${Colors.blue};
          }
        }

        &:focus {
          outline: 0;
        }
      }
    }

    &:nth-child(2) {
      border-top: 1px solid ${Colors.borderGrey};
      height: ${({ show }) => (show ? `165px` : `54px`)};
      position: absolute;
      display: block;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 250ms ease-in-out 0s;
      overflow: hidden;
      li {
        button {
          position: relative;
          padding-left: 50px;
          padding-right: 68px;
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
          span {
            img,
            svg {
              width: 24px;
              height: 24px;
              border-radius: 2px;
              position: absolute;
              left: 20px;
              top: 0;
              bottom: 0;
              margin: auto 0;
            }
          }

          .badge {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            position: absolute;
            bottom: 0;
            margin: auto 0;
            width: 100%;
            left: 0;
            right: 0;
            height: 12px;
            line-height: 12px;
            padding: 0 4px;
            font-size: 9px;
            font-weight: normal;
            text-transform: uppercase;
            border-radius: 0;
          }
        }

        ul {
          height: auto;
          overflow: hidden;
          position: static;

          li {
            a {
              color: ${Colors.iconGrey};
              transition: all 250ms ease-in-out 0s;
              &:hover {
                color: ${Colors.blue};
              }
            }
          }
        }
      }
    }
  }
`;

export const SidebarStyles = styled.div`
  background: ${Colors.lightGrey};
  display: block;
  width: 100%;
  height: 100%;

  ${NavbarStyles} {
    background: ${Colors.grey};

    strong {
      color: ${Colors.white};
      margin-left: 20px;
    }

    svg {
      display: block;
      margin-left: 20px;
      height: 20px;
      width: auto;
      fill: white;
    }
  }
`;
