// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Types
interface ModalProviderTypes {
  children: ReactNode;
}

// MAIN CONTEXT
// ------------------------------------------------------------
/**
 *
 */
const ModalContext = React.createContext<any>(null);

// MAIN PROVIDER
// ------------------------------------------------------------
/**
 *
 * @param param0
 */
const ModalProvider = ({ children }: ModalProviderTypes) => {
  // State / Props
  const [modal, setModal] = React.useState<any>(null);

  // Render
  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
      }}
    >
      <>
        {children}
        {modal ? modal : null}
      </>
    </ModalContext.Provider>
  );
};

// MAIN CONSUMER
// ------------------------------------------------------------
/**
 *
 */
const useModal = () => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('ModalContext not set!');
  }
  return context;
};

// EXPORTS
// ------------------------------------------------------------
export default ModalProvider;
export { ModalContext, useModal };
