// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// Styles Components
import { NavbarStyles } from '../Navbar/styles';

// Types
interface PosterSidebarContentStylesTypes {
  show?: boolean;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const PosterKeywordContainer = styled.div`
  dl {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
  }

  small {
    opacity: 0.7;
  }

  button {
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const PosterSidebarCollapseButtonStyles = styled.button`
  background: none;
  border: none;
  height: 54px;
  width: 54px;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 250ms ease-in-out 0s;

  svg {
    width: 16px;
    color: ${Colors.white};
  }

  &:hover {
    background: ${Colors.darkGrey};
  }

  &:focus {
    outline: 0;
  }
`;

export const PosterSidebarContentStyles = styled.div<PosterSidebarContentStylesTypes>`
  width: 100%;
  height: calc(100% - 54px);
  border-right: 1px solid ${Colors.borderGrey};
  position: relative;

  > ul {
    height: 170px !important;
  }

  > div.space {
    height: calc(100% - 170px - 54px);
  }

  > div.logout-btn {
    margin-top: auto;
  }

  #font-picker-headingTypography,
  #font-picker-bodyTypography {
    width: 100%;
    button {
      background-color: white;
    }
    .font-list {
      position: relative;

      li {
        background-color: white;
      }
    }
  }

  aside {
    background: ${Colors.white};
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 54px;
    bottom: 0;
    width: ${({ show }) => (show ? '255px' : '0px')};
    overflow: hidden;
    transition: all 250ms ease-in-out 0s;

    section {
      display: block;
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 20px;
      height: 100%;
      transition: all ${({ show }) => (show ? '250ms' : '0')} ease-in-out
        ${({ show }) => (show ? '250ms' : '0')};
      opacity: ${({ show }) => (show ? '1' : '0')};

      .form-group {
        margin-bottom: 12px;
      }

      .image-form-wrapper:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid rgb(0, 0, 0, 0.25);

        & + .image-form-wrapper {
          margin-top: 16px;
        }
      }

      img {
        max-height: 120px;
        max-width: 215px;
      }

      .preview {
        background: #efefef;
        border-radius: 0.25rem;
        background-size: cover;
        background-position: center center;
        position: relative;

        img {
          display: block;
          margin: auto;
        }

        .btn {
          width: 32px;
          height: 32px;
          position: absolute;
          top: 0;
          right: 0;
          border-top-left-radius: 0px;
          border-bottom-right-radius: 0px;
          svg {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }

    .layouts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      margin: 0 -5px;
      padding: 5px;
      overflow: hidden;

      .layout {
        cursor: pointer;
        margin: 0;
        width: 95px;
        height: 68px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        box-shadow: 0px 0px 0px 1px #cccccc;
        transition: all 250ms ease-in-out 0s;
        position: relative;

        &:hover {
          border: 1px solid #999;
          box-shadow: 0px 0px 0px 1px #999;
        }

        &.selected {
          border: 1px solid #007bff !important;
          box-shadow: 0px 0px 0px 1px #007bff !important;
        }

        dd {
          display: block;
          background: #f2f2f2;
          border-radius: 2px;
          border: 1px solid #888888;
          position: absolute;
        }

        &.layout-11 {
          dd {
            width: 40px;
            height: 58px;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto 0;

            &:last-child {
              left: auto;
              right: 4px;
            }
          }
        }

        &.layout-111 {
          dd {
            width: 25px;
            height: 58px;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto 0;

            &:nth-child(2) {
              left: 34px;
            }

            &:nth-child(3) {
              left: 64px;
            }
          }
        }

        &.layout-1111 {
          dd {
            width: 18px;
            height: 58px;
            margin: auto 0;
            top: 0;
            bottom: 0;
            left: 4px;

            &:nth-child(2) {
              left: 26px;
            }

            &:nth-child(3) {
              left: 48px;
            }

            &:nth-child(4) {
              left: 70px;
            }
          }
        }

        &.layout-1221 {
          dd {
            width: 18px;
            height: 58px;
            margin: auto 0;
            top: 0;
            bottom: 0;
            left: 4px;

            &:nth-child(2) {
              left: 26px;
              height: 27px;
              bottom: 31px;
            }

            &:nth-child(3) {
              left: 26px;
              height: 27px;
              top: 31px;
            }

            &:nth-child(4) {
              left: 48px;
              height: 27px;
              bottom: 31px;
            }

            &:nth-child(5) {
              left: 48px;
              height: 27px;
              top: 31px;
            }

            &:nth-child(6) {
              left: 70px;
            }
          }
        }

        &.layout-2112 {
          dd {
            width: 18px;
            height: 27px;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto 0;

            &:nth-child(1) {
              bottom: 31px;
            }

            &:nth-child(2) {
              top: 31px;
            }

            &:nth-child(3) {
              height: 58px;
              left: 26px;
            }

            &:nth-child(4) {
              height: 58px;
              left: 48px;
            }

            &:nth-child(5) {
              bottom: 31px;
              left: 70px;
            }

            &:nth-child(6) {
              top: 31px;
              left: 70px;
            }
          }
        }

        &.layout-212 {
          dd {
            width: 18px;
            height: 27px;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto 0;

            &:nth-child(1) {
              bottom: 31px;
            }

            &:nth-child(2) {
              top: 31px;
            }

            &:nth-child(3) {
              left: 26px;
              height: 58px;
              width: 40px;
            }

            &:nth-child(4) {
              left: 70px;
              bottom: 31px;
            }

            &:nth-child(5) {
              left: 70px;
              top: 31px;
            }
          }
        }

        &.layout-22 {
          dd {
            width: 40px;
            height: 27px;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto 0;

            &:nth-child(1) {
              bottom: 31px;
            }

            &:nth-child(2) {
              top: 31px;
            }

            &:nth-child(3) {
              bottom: 31px;
              left: 48px;
            }

            &:nth-child(4) {
              top: 31px;
              left: 48px;
            }
          }
        }

        &.layout-221 {
          dd {
            width: 25px;
            height: 27px;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto 0;

            &:nth-child(1) {
              bottom: 31px;
            }

            &:nth-child(2) {
              top: 31px;
            }

            &:nth-child(3) {
              left: 34px;
              bottom: 31px;
            }

            &:nth-child(4) {
              left: 34px;
              top: 31px;
            }

            &:nth-child(5) {
              left: 64px;
              height: 58px;
            }
          }
        }

        &.layout-222 {
          dd {
            width: 18px;
            height: 27px;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto 0;

            &:nth-child(1) {
              bottom: 31px;
            }

            &:nth-child(2) {
              top: 31px;
            }

            &:nth-child(3) {
              left: 26px;
              width: 40px;
              bottom: 31px;
            }

            &:nth-child(4) {
              left: 26px;
              width: 40px;
              top: 31px;
            }

            &:nth-child(5) {
              left: 70px;
              bottom: 31px;
            }

            &:nth-child(6) {
              left: 70px;
              top: 31px;
            }
          }
        }

        &.layout-3 {
          dd {
            width: 85px;
            height: 16px;
            top: 5px;
            left: 0;
            right: 0;
            margin: 0 auto;

            &:nth-child(2) {
              top: 25px;
            }

            &:nth-child(3) {
              top: 45px;
            }
          }
        }
      }
    }

    .templates {
      dd {
        cursor: pointer;
        width: 215px;
        height: 130px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        position: relative;
        box-shadow: 0px 0px 0px 1px #cccccc;
        transition: all 250ms ease-in-out 0s;

        &.selected {
          border: 1px solid #007bff !important;
          box-shadow: 0px 0px 0px 1px #007bff !important;
        }

        span {
          position: absolute;
          top: 5px;
          bottom: 5px;
          left: 5px;
          right: 5px;
          border-radius: 2px;
          display: block;
          text-align: center;
        }

        &.templates-minimal {
          span {
            background: #efefef;
            color: #121212;
            font-family: 'Noto Serif JP', serif;
            line-height: 120px;
            font-weight: 800;
            font-size: 24px;
          }
        }

        &.templates-bold {
          span {
            background: #1a1736;
            color: ${Colors.white};
            line-height: 100px;
            font-weight: 400;
            font-size: 24px;
            font-family: 'Noto Sans HK', serif;
            &:after {
              content: '';
              display: block;
              width: 100px;
              height: 4px;
              background: #15de97;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 34px;
              margin: auto;
            }
          }
        }

        &.templates-previously-created-poster {
          position: relative;
          color: ${Colors.dark};
          font-weight: 500;
          font-size: 24px;
          font-family: 'Noto Sans HK', serif;
          background-size: cover;
          display: flex;
          align-items: center;

          span {
            inset: unset;
          }

          > span {
            padding: 1rem;
          }

          .bg-image {
            position: absolute;
            background-color: #007bff22;
            width: 100%;
            inset: 0;
            opacity: 0.5;

            span {
              position: absolute;
              width: 45px;
              background-color: white;
            }

            .header {
              width: 100%;
              height: 30px;
              background-color: #007bff80;
              top: 0;
            }

            .first,
            .second {
              height: calc(100% - 30px - 15px);
              bottom: 10px;
            }

            .first {
              left: 10px;
            }

            .second {
              left: 59px;
            }

            .third,
            .fourth {
              height: calc((100% - 30px - 18px) / 2);
              left: 108px;
            }

            .third,
            .fifth {
              top: 35px;
            }

            .fourth,
            .sixth {
              bottom: 10px;
            }

            .fifth,
            .sixth {
              right: 10px;
            }

            .fifth {
              height: calc((100% - 30px - 18px) / 3 * 2);
            }

            .sixth {
              height: calc((100% - 30px - 20px) / 3);
            }
          }

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 5px solid ${Colors.white};
            border-radius: 2px;
          }
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    display: block;
    width: 54px;
    height: calc(100% - 54px);
    overflow: auto;
    li {
      height: 54px;
      line-height: 54px;
      border-bottom: 1px solid ${Colors.borderGrey};
      transition: all 250ms ease-in-out 0s;
      > a,
      > button {
        background: none;
        position: relative;
        color: ${Colors.grey};
        display: block;
        width: 100%;
        height: 54px;
        line-height: 54px;
        padding: 0 20px;
        text-align: left;
        border: none;
        margin: 0;
        font-size: 14px;
        text-decoration: none;
        transition: all 250ms ease-in-out 0s;

        svg {
          position: absolute;
          right: 20px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          transition: all 250ms ease-in-out 0s;
        }

        span {
          svg {
            color: ${Colors.iconGrey};
            position: static;
            width: 16px;
            margin-right: 6px;
          }
        }

        &:hover {
          color: ${Colors.blue};
          svg {
            color: ${Colors.blue};
          }
        }

        &:focus {
          outline: 0;
        }
      }

      &.selected {
        background: ${Colors.white};
        a,
        button {
          span {
            svg {
              color: ${Colors.blue};
            }
          }
        }
      }
    }

    > &:nth-child(2) {
      border-top: 1px solid ${Colors.borderGrey};
      height: 54px;
      position: absolute;
      display: block;
      width: 54px;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 250ms ease-in-out 0s;
      overflow: hidden;
      li {
        button {
          position: relative;
        }

        ul {
          height: auto;
          overflow: hidden;
          position: static;

          li {
            a {
              color: ${Colors.iconGrey};
              transition: all 250ms ease-in-out 0s;
              &:hover {
                color: ${Colors.blue};
              }
            }
          }
        }
      }
    }
  }

  .logout-btn {
    height: 54px;
    line-height: 54px;
    border-bottom: 1px solid ${Colors.borderGrey};
    transition: all 250ms ease-in-out 0s;
    > a,
    > button {
      background: none;
      position: relative;
      color: ${Colors.grey};
      display: block;
      width: 100%;
      height: 54px;
      line-height: 54px;
      padding: 0 20px;
      text-align: left;
      border: none;
      margin: 0;
      font-size: 14px;
      text-decoration: none;
      transition: all 250ms ease-in-out 0s;

      svg {
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transition: all 250ms ease-in-out 0s;
      }

      span {
        svg {
          color: ${Colors.iconGrey};
          position: static;
          width: 16px;
          margin-right: 6px;
        }
      }

      &:hover {
        color: ${Colors.blue};
        svg {
          color: ${Colors.blue};
        }
      }

      &:focus {
        outline: 0;
      }
    }

    &.selected {
      background: ${Colors.white};
      a,
      button {
        span {
          svg {
            color: ${Colors.blue};
          }
        }
      }
    }
  }
`;

export const PosterSidebarStyles = styled.div`
  background: ${Colors.lightGrey};
  display: block;
  width: 100%;
  height: 100%;

  ${NavbarStyles} {
    background: ${Colors.grey};

    p {
      display: block;
      text-overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
    }

    strong {
      color: ${Colors.white};
      margin-left: 20px;
    }
  }
`;
