// IMPORTS
// ------------------------------------------------------------
import styled, { keyframes } from 'styled-components';
import Colors from '../../styles/Colors';

// Types
interface LoadingBoxStylesTypes {
  width?: string;
  height?: string;
  margin?: string;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
const leftToRight = keyframes`
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
`;

export const LoadingBoxStyles = styled.div<LoadingBoxStylesTypes>`
  background: ${Colors.lightGrey};
  position: relative;
  overflow: hidden;
  height: ${({ height }) => (height ? `${height}` : '100px')};
  width: ${({ width }) => (width ? `${width}` : '100%')};
  margin: ${({ margin }) => (margin ? `${margin}` : '0px')};

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      ${Colors.altLightGrey} 50%,
      transparent 100%
    );
    animation: ${leftToRight} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`;
