// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const DataEditActionsStyles = styled.div`
  float: right;

  button,
  .btn {
    margin-left: 10px;
  }
`;

export const DataEditStyles = styled.div`
  header {
    h3 {
      line-height: 38px;
    }
    margin-bottom: 30px;
  }
  main {
    border-top: 1px solid ${Colors.borderGrey};
    padding-top: 20px;
    padding-bottom: 40px;

    .nav-tabs {
      margin-bottom: 30px;
    }

    section {
      display: none;

      header {
        margin-bottom: 0px;
      }

      main {
        border-top: none;
        padding-top: 0px;
      }
    }
  }
`;
