// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Hooks
import useDebounce from '../../hooks/useDebounce';

// Providers
import { useModal } from '../../providers/Modal';
import { useFetch } from '../../providers/Fetch';
import { useAuth } from '../../providers/Auth';

// Helpers
import { CONST, getDefaultRoute } from '../../utils/helpers';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataList from '../../components/DataList';
import NavbarSearch from '../../components/NavbarSearch';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';

// Style
import { UsersPageStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const UsersPage = () => {
  // State / Props
  // const [csvData, setCsvData] = React.useState<any>();
  const { setModal } = useModal();
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort, setSort] = React.useState('');
  const [order, setOrder] = React.useState('');
  const [data, setData] = React.useState<any>(null);
  const [pagination, setPagination] = React.useState<any>(null);
  const isMountedRef = React.useRef(false);
  const { fetch } = useFetch();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const debouncedSearchTeam = useDebounce(search, 500);

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (query?: string, skip?: number, take?: number, sort?: string, order?: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/${auth.role === 'admin' ? 'users' : `conferences/${auth.conferenceId}/users`}?${
            query ? `q=${query}&` : ''
          }${skip ? `skip=${skip}&` : ''}${take ? `take=${take}&` : ''}${
            sort ? `sort=${sort}&` : ''
          }${order ? `order=${order}&` : ''}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          const { data, pagination } = response.data;
          setData(data);
          setPagination(pagination);
          setSort(pagination.sort);
          setOrder(pagination.order);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    },
    [fetch, auth],
  );

  /**
   *
   */
  const deleteEntry = React.useCallback(
    async (entryId: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/${
            auth.role === 'admin'
              ? `users/${entryId}`
              : `conferences/${auth.conferenceId}/users/${entryId}`
          }`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        () => {
          if (isMountedRef.current) {
            getData();
          }
        },
        () => {
          setIsLoading(false);
        },
      );
    },
    [fetch, getData, auth],
  );

  // const onChangeCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // console.log('onChangeCSV');
  //   // console.log(event);
  //   const files = event.target.files;
  //   //proceed your files here
  //   const reader = new FileReader();
  //   reader.onload = function (ev) {
  //     // console.log('ev', ev);
  //     if (ev.target) {
  //       var csv = ev.target.result;
  //       // console.log(csv);

  //       // const csv2json = (str: string, delimiter = ',') => {
  //       //   const titles = str.slice(0, str.indexOf('\n')).split(delimiter);
  //       //   const rows = str.slice(str.indexOf('\n') + 1).split('\n');
  //       //   return rows.map((row) => {
  //       //     const values = row.split(delimiter);
  //       //     return titles.reduce((object: any, curr: any, i: any) => {
  //       //       if (object[curr]) {
  //       //         return (object[curr] = values[i]), object;
  //       //       }
  //       //     }, {});
  //       //   });
  //       // };

  //       // const result = csv2json(csv as string, ',');
  //       // console.log(result);)

  //       setCsvData((csv as string).split('\n').map((i: any) => i.split(',')));
  //     }
  //   };
  //   if (files && files.length > 0) {
  //     reader.readAsText(files[0]);
  //   }
  // };

  // Functions
  // /**
  //  *
  //  * @param data
  //  */
  // const onClickImport = () => {
  //   setModal(
  //     <ModalContainer>
  //       <Modal
  //         title="Import Users"
  //         body={
  //           <form>
  //             <div className="form-group">
  //               <input
  //                 className="form-control"
  //                 type="file"
  //                 name="files"
  //                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
  //                 }}
  //               />
  //             </div>
  //           </form>
  //         }
  //         handleClose={() => setModal(null)}
  //         footer={
  //           <>
  //             <button onClick={() => setModal(null)} className="btn flex-fill btn-outline-secondary">
  //               Cancel
  //             </button>
  //             <button className="btn flex-fill btn-outline-success">Save</button>
  //           </>
  //         }
  //       />
  //     </ModalContainer>
  //   );
  // };

  /**
   *
   * @param data
   */
  const onClickDelete = (data: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setModal(
      <ModalContainer>
        <Modal
          title={`Delete '${data.email}'?`}
          hasBody={false}
          handleClose={() => setModal(null)}
          footer={
            <>
              <button
                onClick={() => setModal(null)}
                className="btn flex-fill btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteEntry(data.id);
                  setModal(null);
                }}
                className="btn flex-fill btn-outline-danger"
              >
                Delete
              </button>
            </>
          }
        />
      </ModalContainer>,
    );
  };

  /**
   *
   * @param param0
   */
  const handlePrev = () => {
    getData(search, pagination?.skip - pagination?.take, pagination?.take, sort, order);
  };

  /**
   *
   * @param param0
   */
  const handleNext = () => {
    getData(search, pagination?.skip + pagination?.take, pagination?.take, sort, order);
  };

  // Hooks
  /**
   * Search
   */
  React.useEffect(() => {
    getData(debouncedSearchTeam, pagination?.skip, pagination?.take, sort, order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTeam]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Columns Config
  const columns: any = {
    ['first_name']: {
      display: 'First Name',
      filter: true,
    },
    ['last_name']: {
      display: 'Last Name',
      filter: true,
    },
    email: {
      display: 'Email',
      filter: true,
    },
  };

  if (auth.role === 'admin') {
    columns['conferences_roles'] = {
      display: 'Conferences',
      render: (data: any) =>
        data?.conferences_roles?.length > 0 &&
        data?.conferences_roles.map((i: any, key: number) => (
          <span key={`conferences-${key}`}>
            {i.Conference?.domain}
            <br />
          </span>
        )),
    };
  }

  columns.role = {
    display: 'Roles',
    render: (data: any) =>
      data?.conferences_roles?.length > 0 &&
      data?.conferences_roles.map((i: any, key: number) => (
        <span key={`role-${key}`}>
          {i.Role?.name}
          <br />
        </span>
      )),
  };

  columns.payment = {
    display: 'Current Status',
    render: (data: any) => (
      <span>
        {data?.conferences_roles?.length > 0 &&
          data?.conferences_roles.map((i: any, key: number) =>
            i.Conference.domain === 'admin' ? (
              <span key={key}>
                -<br />
              </span>
            ) : i.expires_at ? (
              new Date(i.expires_at).getTime() > new Date().getTime() ? (
                <span key={`payment-${key}`}>
                  <span className="badge bg-success">Active</span>
                  <br />
                </span>
              ) : (
                <span key={`payment-${key}`}>
                  <span className="badge bg-warning">Disabled</span>
                  <br />
                </span>
              )
            ) : (
              <span key={`payment-${key}`}>
                <span className="badge bg-secondary">None</span>
                <br />
              </span>
            ),
          )}
      </span>
    ),
  };

  columns.actions = {
    display: 'Actions',
    class: 'text-right',
    render: (data: any) =>
      !isLoading ? (
        <>
          {['admin'].includes(auth.role) && (
            <button className="btn btn-sm btn-outline-danger" onClick={onClickDelete(data)}>
              Delete
            </button>
          )}
          <Link
            className="btn btn-sm btn-outline-secondary"
            to={`${CONST.adminRoute}/users/${data.id}/edit`}
          >
            Edit
          </Link>
        </>
      ) : null,
  };

  // Render
  return (
    <DashboardLayout>
      <UsersPageStyles>
        <Navbar>
          <NavbarSearch
            handleChange={(input: string) => {
              setSearch(input || '');
            }}
          />
        </Navbar>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataList
                isLoading={isLoading}
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <span key="users">Users</span>,
                ]}
                title={['conference'].includes(auth.role) ? 'Conference' : 'Users'}
                subtitle={['conference'].includes(auth.role) ? 'Users' : undefined}
                actions={[
                  // <button disabled={isLoading} className="btn btn-outline-secondary">
                  //   Export
                  // </button>,
                  // <button disabled={isLoading} className="btn btn-outline-secondary" onClick={onClickImport}>
                  //   Import
                  // </button>,
                  <Link className="btn btn-success" to={`${CONST.adminRoute}/users/new`} key="new">
                    Add New
                  </Link>,
                ]}
                rowClick={(data: any) =>
                  !isLoading ? navigate(`${CONST.adminRoute}/users/${data.id}/edit`) : {}
                }
                columns={columns}
                sortBy={sort}
                order={order}
                handleSort={({ sortBy, orderBy }: { sortBy: string; orderBy: string }) => {
                  getData(search, undefined, undefined, sortBy, sort !== sortBy ? 'asc' : orderBy);
                }}
                data={data ? data : []}
                hasPagination={true}
                paginationShowing={`${data?.length === 0 ? 0 : pagination?.skip + 1} - ${
                  pagination?.skip + data?.length
                }`}
                paginationTotal={pagination?.count ?? 0}
                nextEnabled={
                  isLoading ? false : pagination?.skip + data?.length < pagination?.count
                }
                prevEnabled={isLoading ? false : pagination?.skip > 0}
                handlePrev={handlePrev}
                handleNext={handleNext}
              />
            </div>
          </div>
        </div>
        {/* <footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h3>Data Imported</h3>
                <div className="form-group">
                  <span>
                    <label htmlFor="csv">CSV import</label>
                    <div className="custom-file">
                      <input name="institution" onChange={onChangeCSV} disabled={false} type="file" className="custom-file-input" id="csv" />
                      <label className="custom-file-label" htmlFor="institution-thumbnail">
                        Choose file
                      </label>
                    </div>
                  </span>
                </div>
                {csvData && (
                  <table className="table">
                    <thead>
                      <tr>
                        {csvData[0].map((i: any, k: number) => (
                          <th>{i}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{JSON.stringify(csvData.slice(1))}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <pre>{JSON.stringify(csvData, null, ' ')}</pre>
              </div>
            </div>
          </div>
        </footer> */}
      </UsersPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default UsersPage;
