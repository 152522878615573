// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Link, Navigate } from 'react-router-dom';

// Helpers
import { CONST, formatDate } from '../../utils/helpers';

// Providers
import { useAuth } from '../../providers/Auth';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataEdit from '../../components/DataEdit';

// Styles
import { PaywallPageStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const PaywallPage = () => {
  // State / Props
  const { auth } = useAuth();

  // Render
  /**
   *
   */
  if (
    new Date().getTime() < new Date(auth.conferences_roles[auth.domainIndex].expires_at).getTime()
  ) {
    return <Navigate to={`${CONST.adminRoute}/posters`} />;
  }

  /**
   *
   */
  return (
    <DashboardLayout>
      <PaywallPageStyles>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataEdit
                title="Paywall"
                breadcrumbs={[
                  <Link to="/" key="home">
                    Home
                  </Link>,
                  <span key="paywall">Paywall</span>,
                ]}
              >
                <p>
                  If you are seeing this, it is most likely that you have poster slots and just need
                  to pay to gain access.
                </p>
                <p>
                  <strong>Current Poster Slots:</strong>{' '}
                  {auth?.conferences_roles?.[auth.domainIndex]?.quantity}
                </p>
                <p>
                  <strong>Expiration Date:</strong>{' '}
                  {auth?.conferences_roles?.[auth.domainIndex]?.expires_at
                    ? formatDate(auth?.conferences_roles?.[auth.domainIndex]?.expires_at)
                    : 'Disabled'}
                </p>
              </DataEdit>
            </div>
          </div>
        </div>
      </PaywallPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default PaywallPage;
