import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import React, { useMemo } from 'react';
import { ChromePicker } from 'react-color';
import { RiPaintBrushFill } from 'react-icons/ri';
import { ColorPickerContainer, ColorPickerToggle, ErrorMessage, PreviewCircle } from './styles';

interface ColorPickerProps {
  name: string;
  label: string;
  color: string;
  setColor: (value: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, setColor, name, label }) => {
  const handleChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    const regex = /^#([a-fA-F0-9]{0,6})$|^$/;
    if (value && !value.includes('#')) value = `#${value}`;
    if (value.match(regex)) setColor(value);
  };

  const isValid = useMemo(() => {
    if (color === '') return undefined;
    if (color.match(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/)) return true;
    return false;
  }, [color]);

  return (
    <ColorPickerContainer>
      <Form.Group controlId={name}>
        <Form.Label>{label}</Form.Label>
        <div className="content">
          <Form.Control type="text" value={color} onChange={handleChangeColor} isValid={isValid} />
          <OverlayTrigger
            trigger="click"
            placement="top-start"
            rootClose
            overlay={
              <Popover>
                <Popover.Body>
                  <ChromePicker
                    disableAlpha
                    color={color}
                    onChange={(color, _) => setColor(color.hex)}
                  />
                </Popover.Body>
              </Popover>
            }
          >
            <ColorPickerToggle color={color} title={'Open color picker'} type="button">
              <RiPaintBrushFill aria-hidden />
            </ColorPickerToggle>
          </OverlayTrigger>
          <PreviewCircle color={color} />
        </div>
        {isValid === undefined || isValid || (
          <ErrorMessage role="alert">
            Invalid HEX color, using default theme color instead.
          </ErrorMessage>
        )}
      </Form.Group>
    </ColorPickerContainer>
  );
};

export default ColorPicker;
