// IMPORTS
// ------------------------------------------------------------
import React from 'react';

// Providers
import { useAuth0 } from '@auth0/auth0-react';

// Styles
import { ForbiddenPageStyles } from './styles';

// Layout
import FullLayout from '../../layouts/FullLayout';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const ForbiddenPage = () => {
  // State / Props
  const { logout } = useAuth0();

  // Render
  return (
    <FullLayout>
      <ForbiddenPageStyles>
        <div className="container-fluid">
          <div className="row">
            <div className="col col-12 col-md-4 offset-md-4 col-lg-4 offset-lg-4 col-xl-4 offset-xl-4 col-xxl-2 offset-xxl-5">
              <div className="card">
                <div className="card-body text-center">
                  <h5 className="card-title text-center">Better Poster</h5>
                  <h6 className="cart-subtitle text-muted text-center">Awaiting Access</h6>
                  <p>
                    Please check back later. Currently your account has not been setup to access{' '}
                    <strong>{window.location.origin}</strong>.
                  </p>
                  <div className="d-grid mx-auto">
                    <button
                      className="btn btn-secondary"
                      onClick={() =>
                        logout({ logoutParams: { returnTo: `${window.location.origin}/admin` } })
                      }
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ForbiddenPageStyles>
    </FullLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default ForbiddenPage;
