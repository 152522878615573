import styled, { css } from 'styled-components';

export const ImageInputWrapper = styled.div<{ sidePreview?: boolean }>`
  .progress-container {
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fafafa;
    padding: 1rem;
    border-radius: 0.25rem;

    .progress {
      width: 100%;
      height: 8px;
    }
  }

  ${({ sidePreview }) =>
    sidePreview &&
    css`
      position: relative;
      display: flex;
      height: 120px;
      align-items: center;
      margin-bottom: 1rem;

      img,
      svg {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 0;
        left: 0;
      }

      .form-group {
        width: 100%;
      }

      span {
        margin-left: 130px;
        display: block;
        width: calc(100% - 130px);
      }
    `}
`;

export const ImageInputHelperWrapper = styled.p`
  font-size: 12px;
  opacity: 0.8;
  margin: 0.5rem 0;
  span {
    font-size: 12px;
  }
`;
