// IMPORTS
// ------------------------------------------------------------
import React from 'react';

// Styled
import { BreadcrumbsStyles } from './styles';

// Types
interface BreadcrumbsTypes {
  data: Array<any>;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const Breadcrumbs = ({ data }: BreadcrumbsTypes) => {
  return (
    <BreadcrumbsStyles aria-label="breadcrumb">
      <ol className="breadcrumb">
        {data.map((breadcrumb: any, key: number) => (
          <li
            key={`breadcrumb-${key}`}
            className={`breadcrumb-item ${data.length === key + 1 ? 'active' : ''}`}
          >
            {breadcrumb}
          </li>
        ))}
      </ol>
    </BreadcrumbsStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default Breadcrumbs;
