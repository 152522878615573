// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Styles
import {
  DashboardLayoutStyles,
  DashboardLayoutSidebarStyles,
  DashboardLayoutMainStyles,
} from './styles';

// Components
import Sidebar from '../../components/Sidebar';

// Types
interface DashboardLayoutTypes {
  children: ReactNode;
}

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const DashboardLayout = ({ children }: DashboardLayoutTypes) => {
  return (
    <DashboardLayoutStyles>
      <DashboardLayoutSidebarStyles>
        <Sidebar />
      </DashboardLayoutSidebarStyles>
      <DashboardLayoutMainStyles>{children}</DashboardLayoutMainStyles>
    </DashboardLayoutStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default DashboardLayout;
