import { Placeholder } from '../Icons';
import { X } from 'react-feather';
import { forwardRef, ReactNode, useState } from 'react';
import { ImageInputHelperWrapper, ImageInputWrapper } from './styles';
import { useNotification } from '../../providers/Notification';
import { AxiosProgressEvent } from 'axios';

type Props = {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    onUploadProgress?: (e: AxiosProgressEvent) => void,
  ) => void;
  disabled?: boolean;
  formLabel: string | ReactNode;
  name: string;
  id: string;
  inputLabel?: string;
  src?: string;
  removeImage?: () => void;
  sizeLabel: KBSize | MBSize;
  recommendedDimension?: string;
  customPreview?: (children: ReactNode) => ReactNode;
  customPlaceholder?: ReactNode;
  sidePreview?: boolean;
};

type KBSize = `${number}KB`;
type MBSize = `${number}MB`;

function isKB(size: KBSize | MBSize): size is KBSize {
  return size.endsWith('KB');
}

export const ImageInput = forwardRef<HTMLInputElement, Props>(function ImageInput(props, ref) {
  const { add } = useNotification();

  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  if (progress === 100 && showProgress) setShowProgress(false);

  function onUploadProgress(event: AxiosProgressEvent) {
    const progress: number = Math.round((event.loaded * 100) / (event.total as number));
    setProgress(progress);
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (progress) setProgress(0);
    const file = event.target.files?.[0];

    const sizeNumber = Number(props.sizeLabel.replace(/\D/g, ''));
    const maxSize = isKB(props.sizeLabel) ? sizeNumber * 1024 : sizeNumber * 1024 * 1024;

    if (file && file.size > maxSize) {
      add(`File size is too big. Max size is ${props.sizeLabel}`, 'error', 2500);
      return;
    }
    setShowProgress(true);
    props.onChange(event, onUploadProgress);
  }

  const placeholder =
    props.customPlaceholder ||
    (props.sidePreview ? (
      <Placeholder x="16%" placeholder="Placeholder" width="120" height="120" />
    ) : (
      <Placeholder x="28%" placeholder="Placeholder" width="100%" height="120" />
    ));

  const preview = props.customPreview ? (
    props.customPreview(
      <button title="Remove image" className="btn btn-danger btn-sm" onClick={props.removeImage}>
        <X />
      </button>,
    )
  ) : props.sidePreview ? (
    <img src={props.src} className="rounded" alt="..." />
  ) : (
    <div className="preview">
      <img
        src={props.src}
        className="rounded"
        alt={typeof props.formLabel === 'string' ? props.formLabel : 'Preview'}
      />
      <button title="Remove image" className="btn btn-danger btn-sm" onClick={props.removeImage}>
        <X />
      </button>
    </div>
  );

  return (
    <ImageInputWrapper className="image-form-wrapper" sidePreview={props.sidePreview}>
      <div className="form-group">
        <span>
          <label htmlFor={props.id}>{props.formLabel}</label>
          <div className="custom-file">
            <input
              name={props.name}
              onChange={onChange}
              ref={ref}
              disabled={props.disabled || showProgress}
              accept="image/*"
              type="file"
              className="custom-file-input"
              id={props.id}
            />
            <label className="custom-file-label" htmlFor={props.id}>
              {props.inputLabel || 'Choose file'}
            </label>
          </div>
          <ImageInputHelperWrapper>
            Up to {props.sizeLabel} and {props.recommendedDimension}
          </ImageInputHelperWrapper>
        </span>
      </div>

      {!showProgress ? (
        props.src ? (
          preview
        ) : (
          placeholder
        )
      ) : (
        <div className="progress-container">
          <p>Uploading...</p>
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      )}
    </ImageInputWrapper>
  );
});
