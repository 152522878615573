import React from 'react';
import { Facebook, Globe, Home, Instagram, Twitter, X } from 'react-feather';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { ID, Placeholder, Qr } from '../../components/Icons';
import Colors from '../../styles/Colors';
import { UserType } from '../../typings';
import { SingleTemplateStyles } from './styles';

interface SingleTemplateProps {
  background?: string;
  homeActive?: boolean;
  user: UserType;
  url?: string;
  titleFontColor?: string;
  bodyFontColor?: string;
  backgroundColor?: string;
  isEditor?: boolean;
}

const SingleTemplate: React.FC<SingleTemplateProps> = ({
  background,
  homeActive,
  user,
  url,
  isEditor,
}) => {
  const [modal, setModal] = React.useState<any>();

  return (
    <SingleTemplateStyles isEditor={isEditor}>
      <div className="buttons">
        <Link
          className="home"
          to={`${homeActive ? '/' : '#home'}`}
          onClick={(e: any) => {
            if (!homeActive) {
              e.preventDefault();
            }
          }}
        >
          <Home />
          <span>Home</span>
        </Link>
        <button className="qr" onClick={() => setModal('qr')}>
          <Qr stroke={Colors.greyQuote} fill="transparent" />
          <span>QR</span>
        </button>
        <button className="profile" onClick={() => setModal('profile')}>
          {user.photo ? (
            <img src={user.photo} alt={`${user?.firstName} ${user?.lastName}`} />
          ) : (
            <Placeholder width="60px" height="60px" placeholder="" />
          )}
        </button>
        {user?.institution && (
          <span className="institution" style={{ backgroundImage: `url(${user.institution})` }} />
        )}
      </div>

      {background ? (
        <img src={background} alt="" />
      ) : (
        <div className="bg-image">
          <span className="header" />
          <span className="first" />
          <span className="second" />
          <span className="third" />
          <span className="fourth" />
          <span className="fifth" />
          <span className="sixth" />
        </div>
      )}

      {modal ? (
        <article className={`${modal}`}>
          <aside>
            {modal === 'qr' ? (
              <section>
                <QRCode value={url || ''} />
              </section>
            ) : null}
            {modal === 'profile' ? (
              <section>
                <header>
                  {user.photo ? (
                    <img src={user.photo} alt={`${user.firstName} ${user.lastName}`} />
                  ) : (
                    <Placeholder placeholder="" />
                  )}
                  {user.petPhoto ? <img src={user.petPhoto} alt={`${user.petName}`} /> : null}
                  <h2>
                    {user.firstName} {user.lastName}
                  </h2>
                  <p>
                    {user.pronoun} {user.school ? `- ${user.school}` : ''}{' '}
                    {user.location ? `- ${user.location}` : ''}
                  </p>
                </header>
                <main>
                  {user.biography ? (
                    <>
                      <h3>Biography</h3>
                      <p>{user.biography}</p>
                    </>
                  ) : null}
                  <ul>
                    {user.twitter ? (
                      <li>
                        <span className="social social-twitter">
                          <Twitter fill="white" stroke="0px" />
                        </span>
                        <a href={user.twitter} target="_blank" rel="noopener noreferrer">
                          {user.twitter}
                        </a>
                      </li>
                    ) : null}
                    {user.facebook ? (
                      <li>
                        <span className="social social-facebook">
                          <Facebook fill="white" stroke="0px" />
                        </span>
                        <a href={user.facebook} target="_blank" rel="noopener noreferrer">
                          {user.facebook}
                        </a>
                      </li>
                    ) : null}
                    {user.instagram ? (
                      <li>
                        <span className="social social-instagram">
                          <Instagram />
                        </span>
                        <a href={user.instagram} target="_blank" rel="noopener noreferrer">
                          {user.instagram}
                        </a>
                      </li>
                    ) : null}
                    {user.website ? (
                      <li>
                        <span className="social social-website">
                          <Globe />
                        </span>
                        <a href={user.website} target="_blank" rel="noopener noreferrer">
                          {user.website}
                        </a>
                      </li>
                    ) : null}
                    {user.orcid ? (
                      <li>
                        <span className="social social-orcid">
                          <ID />
                        </span>
                        <a href={user.orcid} target="_blank" rel="noopener noreferrer">
                          {user.orcid}
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </main>
              </section>
            ) : null}
          </aside>
          <button
            type="button"
            onClick={() => {
              setModal(null);
            }}
          >
            <X />
          </button>
        </article>
      ) : null}
    </SingleTemplateStyles>
  );
};

export default SingleTemplate;
