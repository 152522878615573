// IMPORTS
// ------------------------------------------------------------
import React, { ReactNode } from 'react';

// Styled
import { InputThumbnailStyles } from './styles';

// Types
interface InputThumbnailTypes {
  children: ReactNode;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const InputThumbnail = ({ children }: InputThumbnailTypes) => {
  return <InputThumbnailStyles>{children}</InputThumbnailStyles>;
};

// EXPORTS
// ------------------------------------------------------------
export default InputThumbnail;
