// IMPORTS
// ------------------------------------------------------------
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ExternalLink } from 'react-feather';

// Hooks
import useDebounce from '../../hooks/useDebounce';

// Providers
import { useAuth } from '../../providers/Auth';
import { useModal } from '../../providers/Modal';
import { useFetch } from '../../providers/Fetch';

// Helpers
import { CONST, getDefaultRoute, formatDate } from '../../utils/helpers';

// Layouts
import DashboardLayout from '../../layouts/DashboardLayout';

// Components
import Navbar from '../../components/Navbar';
import DataList from '../../components/DataList';
import NavbarSearch from '../../components/NavbarSearch';
import ModalContainer from '../../components/ModalContainer';
import Modal from '../../components/Modal';

// Styles
import { ConferencesPageStyles } from './styles';
import { Placeholder } from '../../components/Icons';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const ConferencesPage = () => {
  // State / Props
  const { setModal } = useModal();
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort, setSort] = React.useState('');
  const [order, setOrder] = React.useState('');
  const [data, setData] = React.useState<any>(null);
  const [pagination, setPagination] = React.useState<any>(null);
  const isMountedRef = React.useRef(false);
  const debouncedSearchTeam = useDebounce(search, 500);
  const [subdomain, setSubdomain] = useState('');

  // Providers
  const { fetch, addRequest, updateRequest } = useFetch();
  const navigate = useNavigate();
  const { auth } = useAuth();

  // Requests
  /**
   *
   */
  const getData = React.useCallback(
    async (query?: string, skip?: number, take?: number, sort?: string, order?: string) => {
      setIsLoading(true);
      fetch(
        {
          url: `/conferences?${query ? `q=${query}&` : ''}${skip ? `skip=${skip}&` : ''}${
            take ? `take=${take}&` : ''
          }${sort ? `sort=${sort}&` : ''}${order ? `order=${order}&` : ''}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (response: any) => {
          const { data, pagination } = response.data;
          setData(data);
          setPagination(pagination);
          setSort(pagination.sort);
          setOrder(pagination.order);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    },
    [fetch],
  );

  /**
   *
   */
  const deleteEntry = React.useCallback(
    async (entryId: string) => {
      const id = new Date().getTime();
      addRequest(id, 'loading');
      fetch(
        {
          url: `/conferences/${entryId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        () => {
          if (isMountedRef.current) {
            getData();
          }
          updateRequest(id, 'completed', 'Successfully deleted!');
        },
        () => {
          updateRequest(id, 'error', 'Error deleting!');
        },
      );
    },
    [fetch, getData, addRequest, updateRequest],
  );

  // Functions
  // /**
  //  *
  //  * @param data
  //  */
  // const onClickImport = () => {
  //   setModal(
  //     <ModalContainer>
  //       <Modal
  //         title="Import Users"
  //         body={
  //           <form>
  //             <div className="form-group">
  //               <input className="form-control" type="file" name="files" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {}} />
  //             </div>
  //           </form>
  //         }
  //         handleClose={() => setModal(null)}
  //         footer={
  //           <>
  //             <button onClick={() => setModal(null)} className="btn flex-fill btn-outline-secondary">
  //               Cancel
  //             </button>
  //             <button className="btn flex-fill btn-outline-success">Save</button>
  //           </>
  //         }
  //       />
  //     </ModalContainer>
  //   );
  // };

  /**
   *
   * @param data
   */
  const onClickDelete = (data: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    // event.preventDefault();
    setModal(
      <ModalContainer>
        <Modal
          title={`Delete '${data.name}'?`}
          hasBody={true}
          body={<p>By deleting this you will permantly delete everything.</p>}
          handleClose={() => setModal(null)}
          footer={
            <>
              <button
                onClick={() => setModal(null)}
                className="btn flex-fill btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteEntry(data.id);
                  setModal(null);
                }}
                className="btn flex-fill btn-outline-danger"
              >
                Delete
              </button>
            </>
          }
        />
      </ModalContainer>,
    );
  };

  /**
   *
   * @param param0
   */
  const handlePrev = () => {
    getData(search, pagination?.skip - pagination?.take, pagination?.take, sort, order);
  };

  /**
   *
   * @param param0q
   */
  const handleNext = () => {
    getData(search, pagination?.skip + pagination?.take, pagination?.take, sort, order);
  };
  /**
   *
   * @param data
   */
  const buildLinkUrl = () => {
    const host = window.location.host.split('.');
    if (host.length > 0) host.shift();
    setSubdomain('.' + host.join('.'));
  };

  // Hooks
  /**
   * Search
   */
  React.useEffect(() => {
    getData(debouncedSearchTeam, pagination?.skip, pagination?.take, sort, order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTeam, getData]);

  /**
   *
   */
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  React.useEffect(() => {
    buildLinkUrl();
  }, []);

  // Render
  return (
    <DashboardLayout>
      <ConferencesPageStyles>
        <Navbar>
          <NavbarSearch
            handleChange={(input: string) => {
              setSearch(input || '');
            }}
          />
        </Navbar>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DataList
                isLoading={isLoading}
                breadcrumbs={[
                  <Link to={getDefaultRoute(auth?.role)} key="home">
                    Home
                  </Link>,
                  <span key="conferences">Conferences</span>,
                ]}
                title="Conferences"
                actions={[
                  // <button disabled={isLoading} className="btn btn-outline-secondary">
                  //   Export
                  // </button>,
                  // <button disabled={isLoading} className="btn btn-outline-secondary" onClick={onClickImport}>
                  //   Import
                  // </button>,
                  <Link
                    className="btn btn-success"
                    to={`${CONST.adminRoute}/conferences/new`}
                    key="add"
                  >
                    Add New
                  </Link>,
                ]}
                sortBy={sort}
                order={order}
                handleSort={({ sortBy, orderBy }: { sortBy: string; orderBy: string }) => {
                  getData(search, undefined, undefined, sortBy, sort !== sortBy ? 'asc' : orderBy);
                }}
                columns={{
                  photo: {
                    display: 'Photo',
                    render: (data: any) => {
                      if (!data.photo) return <Placeholder />;
                      return <img src={data.photo} alt={data.name} />;
                    },
                    width: 220,
                  },
                  name: {
                    display: 'Name',
                    filter: true,
                  },
                  conferenceDomain: {
                    display: 'Conference Domain',
                    filter: true,
                    render: (data: any) => {
                      const url = `http${window.location.host.includes('localhost') ? '' : 's'}://${
                        data.domain + subdomain
                      }/`;
                      return (
                        <div>
                          <a
                            className="btn btn-sm btn-outline-primary"
                            href={url}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginLeft: '0px' }}
                          >
                            {url}
                            <ExternalLink />
                          </a>
                        </div>
                      );
                    },
                  },
                  ...(auth?.role === 'admin' && {
                    adminDomain: {
                      display: 'Admin Domain',
                      filter: true,
                      render: (data: any) => {
                        const url = `http${
                          window.location.host.includes('localhost') ? '' : 's'
                        }://${data.domain + subdomain}/admin`;
                        return (
                          <div>
                            <a
                              className="btn btn-sm btn-outline-primary"
                              href={url}
                              target="_blank"
                              rel="noreferrer"
                              style={{ marginLeft: '0px' }}
                            >
                              {url}
                              <ExternalLink />
                            </a>
                          </div>
                        );
                      },
                    },
                  }),
                  start: {
                    display: 'Start Date',
                    filter: true,
                    render: (data: any) => (
                      <span>{data.domain === 'admin' ? '-' : formatDate(data?.start)}</span>
                    ),
                  },
                  end: {
                    display: 'End Date',
                    filter: true,
                    render: (data: any) => (
                      <span>{data.domain === 'admin' ? '-' : formatDate(data?.end)}</span>
                    ),
                  },
                  actions: {
                    display: 'Actions',
                    class: 'text-right',
                    render: (data: any) =>
                      !isLoading ? (
                        <>
                          <button
                            className="btn btn-sm btn-outline-danger"
                            onClick={onClickDelete(data)}
                          >
                            Delete
                          </button>
                          <Link
                            className="btn btn-sm btn-outline-secondary"
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                              event.stopPropagation();
                            }}
                            to={`${CONST.adminRoute}/conferences/${data.id}/edit`}
                          >
                            Edit
                          </Link>
                        </>
                      ) : null,
                  },
                }}
                rowClick={(data: any) =>
                  !isLoading ? navigate(`${CONST.adminRoute}/conferences/${data.id}/edit`) : {}
                }
                data={data ? data : []}
                hasPagination={pagination}
                paginationShowing={`${data?.length === 0 ? 0 : pagination?.skip + 1} - ${
                  pagination?.skip + data?.length
                }`}
                paginationTotal={pagination?.count ?? 0}
                nextEnabled={
                  isLoading ? false : pagination?.skip + data?.length < pagination?.count
                }
                prevEnabled={isLoading ? false : pagination?.skip > 0}
                handlePrev={handlePrev}
                handleNext={handleNext}
              />
            </div>
          </div>
        </div>
      </ConferencesPageStyles>
    </DashboardLayout>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default ConferencesPage;
