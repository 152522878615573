// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { useLocation } from 'react-router-dom';

// Style Components
import { UnsupportedPageStyles } from './styles';

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const UnsupportedPage = () => {
  // State / Props
  const { search } = useLocation();
  const type = new URLSearchParams(search).get('type');

  // Hooks

  // Render
  return (
    <UnsupportedPageStyles>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>Unsupported{type ? `: ${type}` : ''}</h1>
          </div>
        </div>
      </div>
    </UnsupportedPageStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default UnsupportedPage;
