// IMPORTS
// ------------------------------------------------------------
import React from 'react';

// Styled
import { ModalStyles } from './styles';

// Types
interface ModalTypes {
  hasHeader?: boolean;
  hasBody?: boolean;
  body?: any;
  footer?: any;
  hasFooter?: boolean;
  title?: string;
  showClose?: boolean;
  handleClose?: () => void;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const Modal = ({
  hasHeader = true,
  hasBody = true,
  hasFooter = true,
  title,
  showClose = true,
  handleClose,
  body,
  footer,
}: ModalTypes) => {
  // Functions
  /**
   *
   */
  const onClickClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  // Render
  return (
    <ModalStyles className="modal-dialog">
      <div className="modal-content">
        {hasHeader ? (
          <div className="modal-header">
            {title ? <h5 className="modal-title">{title}</h5> : null}
            {showClose ? (
              <button
                onClick={onClickClose}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            ) : null}
          </div>
        ) : null}
        {hasBody ? <div className="modal-body">{body}</div> : null}
        {hasFooter ? <div className="modal-footer">{footer}</div> : null}
      </div>
    </ModalStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default Modal;
