// IMPORTS
// ------------------------------------------------------------
import { ReactNode } from 'react';

// Providers
import { usePosterEditor } from '../../providers/PosterEditor';

// Styles
import {
  PosterEditLayoutStyles,
  PosterEditLayoutSidebarStyles,
  PosterEditLayoutMainStyles,
} from './styles';

// Components
import PosterSidebar from '../../components/PosterSidebar';

// Types

type Layout = { id: string; name: string };
interface PosterEditLayoutTypes {
  children: ReactNode;
  data?: any;
  keywords?: any;
  setKeywords?: any;
  templates?: any;
  layouts?: Layout[];
  setData?: (data: any) => void;
  setEdit?: (data: any) => void;
  hasMadeChanges?: boolean;
  setHasMadeChanges?: (changes: boolean) => void;
  preview?: boolean;
}

// MAIN PAGE COMPONENT
// ------------------------------------------------------------
const PosterEditLayout = ({
  children,
  templates,
  layouts = [],
  data,
  keywords,
  setKeywords,
  setData,
  setEdit,
  setHasMadeChanges,
  hasMadeChanges = false,
  preview = false,
}: PosterEditLayoutTypes) => {
  // State / Props
  const { poster } = usePosterEditor();

  // Render
  return (
    <PosterEditLayoutStyles>
      <PosterEditLayoutSidebarStyles show={poster.show} hide={preview}>
        <PosterSidebar
          templates={templates}
          keywords={keywords}
          setKeywords={setKeywords}
          layouts={layouts}
          data={data}
          setData={setData}
          setEdit={setEdit}
          setHasMadeChanges={setHasMadeChanges}
          hasMadeChanges={hasMadeChanges}
        />
      </PosterEditLayoutSidebarStyles>
      <PosterEditLayoutMainStyles show={poster.show} hide={preview}>
        {children}
      </PosterEditLayoutMainStyles>
    </PosterEditLayoutStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default PosterEditLayout;
