// IMPORTS
// -----------------------------------------
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

// Styles Components
import { CountdownStyles, CountdownWrapperStyles, TimerStyles } from './styles';

type CurrentTime = {
  days: string | null;
  hours: string | null;
  minutes: string | null;
  seconds: string;
};

// MAIN COMPONENT
// -----------------------------------------
const Countdown: React.FC<{ currentTimeDifference?: number }> = ({ currentTimeDifference }) => {
  const isMobile = useMedia('(max-width: 768px)');

  const formatTimeValue = (time: number) => {
    return time >= 10 ? time.toString() : `0${time}`;
  };

  const transformSecondsToDaysHoursMinutesSeconds = (currentSeconds: number) => {
    const days =
      !isMobile || currentSeconds > 24 * 3600
        ? formatTimeValue(Math.floor(currentSeconds / (24 * 3600)))
        : null;
    const hours =
      !isMobile || currentSeconds > 3600
        ? formatTimeValue(Math.floor((currentSeconds % (24 * 3600)) / 3600))
        : null;
    const minutes =
      !isMobile || currentSeconds > 60
        ? formatTimeValue(Math.floor((currentSeconds % 3600) / 60))
        : null;
    const seconds = formatTimeValue((currentSeconds % 3600) % 60);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };
  const [timeRemaining, setTimeRemaining] = useState(currentTimeDifference);
  const [currentTime, setCurrentTime] = useState<CurrentTime>(
    transformSecondsToDaysHoursMinutesSeconds(currentTimeDifference as number),
  );

  useEffect(() => {
    if (timeRemaining && timeRemaining > 0) {
      const timeout = setTimeout(() => {
        const currentSeconds = timeRemaining - 1;
        const currentTime = transformSecondsToDaysHoursMinutesSeconds(currentSeconds);

        setTimeRemaining((oldTimeRemaining) => (oldTimeRemaining as number) - 1);
        setCurrentTime(currentTime);
      }, 1000);

      return () => clearTimeout(timeout);
    } else {
      location.reload();
    }
  }, [timeRemaining]);

  // Render
  return (
    <CountdownStyles>
      <CountdownWrapperStyles>
        {currentTime.days && (
          <TimerStyles>
            <span className="timer-item">{currentTime.days}</span>
            <span className="timer-item-title">Days</span>
          </TimerStyles>
        )}
        {currentTime.hours && (
          <TimerStyles>
            <span className="timer-item">{currentTime.hours}</span>
            <span className="timer-item-title">Hours</span>
          </TimerStyles>
        )}
        {currentTime.minutes && (
          <TimerStyles>
            <span className="timer-item">{currentTime.minutes}</span>
            <span className="timer-item-title">Minutes</span>
          </TimerStyles>
        )}
        <TimerStyles>
          <span className="timer-item">{currentTime.seconds}</span>
          <span className="timer-item-title">Seconds</span>
        </TimerStyles>
      </CountdownWrapperStyles>
    </CountdownStyles>
  );
};

// EXPORTS
// -----------------------------------------
export default Countdown;
