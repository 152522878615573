// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const InputThumbnailStyles = styled.div`
  position: relative;
  display: flex;
  height: 120px;
  align-items: center;
  margin-bottom: 1rem;

  img,
  svg {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .form-group {
    width: 100%;
  }

  span {
    margin-left: 130px;
    display: block;
    width: calc(100% - 130px);
  }
`;
