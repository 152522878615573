// IMPORTS
// ------------------------------------------------------------
import React from 'react';
import { Search } from 'react-feather';

// Styles
import { NavbarSearchStyles } from './styles';

// Types
interface NavbarSearchTypes {
  hasIcon?: boolean;
  value?: string;
  handleChange?: (input: string) => void;
  placeholder?: string;
}

// MAIN COMPONENT
// ------------------------------------------------------------
const NavbarSearch = ({
  hasIcon = true,
  value = '',
  handleChange,
  placeholder = 'Search',
}: NavbarSearchTypes) => {
  // State / Props
  const [data, setData] = React.useState(() => {
    return value;
  });

  // Functions
  /**
   *
   * @param event
   */
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(event.target.value);
    if (handleChange) {
      handleChange(event.target.value);
    }
  };

  // Render
  return (
    <NavbarSearchStyles hasIcon={hasIcon}>
      <input type="search" value={data} onChange={onChangeInput} placeholder={placeholder} />
      {hasIcon ? <Search /> : null}
    </NavbarSearchStyles>
  );
};

// EXPORTS
// ------------------------------------------------------------
export default NavbarSearch;
