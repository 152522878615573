// IMPORTS
// ------------------------------------------------------------
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// Types
interface FullPageLoaderStylesTypes {
  position: string;
  zIndex: number;
}

// MAIN STYLED COMPONENTS
// ------------------------------------------------------------
export const FullPageLoaderStyles = styled.div<FullPageLoaderStylesTypes>`
  background: ${Colors.white}a1;
  opacity: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ position }) => position};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  ${({ zIndex }) => (isNaN(zIndex) ? '' : `z-index: ${zIndex};`)}

  > div {
    background-color: ${Colors.white};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 200px;
    border-radius: 0.5rem;

    > .progress {
      width: 80%;
    }
  }
`;
